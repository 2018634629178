import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  content: {
    boxSizing: 'border-box',

    '&:last-child': {
      paddingBottom: 16,
    }
  },
  heading: {
    marginBottom: '0.5rem',
  },
}));

export const SimpleCard = ({
  title = null,
  text = null,
  onClick = () => {},
  elevation = 2,
  className,
  children,
}) => {
  const classes = useStyles();

  return (
    <Card onClick={onClick}
      elevation={elevation}
      className={className}
    >
      <CardContent className={classes.content}>
        {
          title && <Typography className={classes.heading} variant='h4' component='h4'>
            {
                title
            }
          </Typography>
        }
        {
          text
            ? <Typography>{text}</Typography>
            : children
        }
        
      </CardContent>
    </Card>
  );
};

SimpleCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  elevation: PropTypes.number,
  children: PropTypes.node,
};
