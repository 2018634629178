import 'mobx-react-lite/batchingForReactDom';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { AppWrapper } from './app-wrapper';
import log from 'loglevel';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import packageJson from '../package.json';

import { services, StoreContext } from 'modules/contexts';

import './index.css';

log.setLevel(process.env.REACT_APP_LOGLEVEL || log.levels.SILENT);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
    release: 'canopy@' + packageJson.version,
  });
}

try {
  window.Chargebee.init({
    site: process.env.REACT_APP_CHARGEBEE_SITE,
  });
} catch (err) {
  Sentry.captureException(err);
}

services.authService.isAuthenticated.then(() => {
  const root = createRoot(document.getElementById('root'));
  root.render(
    <StoreContext.Provider value={services}>
      <AppWrapper {...services} />
    </StoreContext.Provider>
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
