import React from 'react';
import PropTypes from 'prop-types';
import { CoachingMap } from 'components/coaching-plan';

export const SessionsTab = ({
  className,
  sessions = [],
  onSelect = () => {},
}) => {
  return (
    <div className={className}>
      <CoachingMap sessions={sessions} onStart={onSelect} />
    </div>
  );
};

SessionsTab.propTypes = {
  className: PropTypes.string,
  sessions: PropTypes.array,
  onSelect: PropTypes.func,
};
