import React from 'react';
import PropTypes from 'prop-types';
import log from 'loglevel';
import Typography from '@material-ui/core/Typography';
import * as Sentry from '@sentry/react';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { pathOr } from 'ramda';

import { PageContent } from 'components/layout';

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    fontFamily: 'Montserrat',
  },
}));

export const GlobalErrorPage = (props) => {
  let error;
  const classes = useStyles();
  const location = useLocation();

  try {
     const errorState = pathOr(null, ['state', 'error'], location);
     if (errorState !== null && !error) {
       error = errorState;
     }
  } finally {
     error = props.error || error || 'No Error Logged';
  }

  React.useEffect(() => {
    if (error instanceof Error) {
      Sentry.captureException(error);
    }
    log.error('error', error);
  }, []);

  return (
    <PageContent variant='backgroundPage'>
      <Typography variant='h1' component='h1'>Error</Typography>
      <Typography paragraph>
        An error has occured. The incident has been logged and will be addressed
        as soon as possible.
      </Typography>
      <Typography>
        <a className={classes.link} href='/'>Back home</a>
      </Typography>
    </PageContent>
  );
};

GlobalErrorPage.propTypes = {
  error: PropTypes.instanceOf(Error),
};
