import React from 'react';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import clsx from 'clsx';
import { withStyles } from "@material-ui/core/styles";
import { assoc } from 'ramda';

const confirmDeletion = assoc('confirmDeletion');

export const PendingInvitationCardMenu = withStyles(theme => ({
  dialog: {
    width: 340,
  },
  dialogContent: {
    height: 196,
    padding: 0,

    '&:first-child': {
      paddingTop: 0,
    }
  },
  dialogTitle: {
    // width: 328,
    padding: 16,
    textAlign: 'center',
  },
  title: {
    marginBottom: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  actionList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItem: {
    textAlign: 'center',
    cursor: 'pointer',
  },
  warn: {
    color: theme.palette.warn.main,
  },
  dialogContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '0 16px',
  },
  textContainer: {
    flexGrow: 1,
  },
  actionContainer: {
    height: 50,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  confirmSave: {
    color: theme.palette.success.main,
  },
  confirmDeletion: {
    color: theme.palette.warn.main,
  },
}))(({
  classes,
  invitation,
  open = false,
  disabled = false,
  onClose = () => {},
  onResendInvitation = () => {},
  onCopyInvitationLink = () => {},
  onDeleteInvitation = () => {},
}) => {
  const [state, setState] = React.useState({
    confirmDeletion: false,
  });

  const handleConfirmDeletion = () => setState(confirmDeletion(true));
  const handleCancelDeletion = () => setState(confirmDeletion(false));

  return (
    <Dialog classes={{ paper: classes.dialog }} onClose={onClose} open={open}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography variant='h3' component='h3' className={classes.title}>
          {invitation.email}
        </Typography>
      </DialogTitle>
      
      <SwipeableViews disabled={true} index={state.confirmDeletion ? 1 : 0}>
        <DialogContent className={classes.dialogContent}>
          <List className={classes.actionList}>
            <Divider />
            <ListItem>
              <ListItemText
                className={clsx(classes.listItem, classes.warn)}
                primary='Delete Pending Invitation'
                onClick={(handleConfirmDeletion)}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                className={classes.listItem}
                primary='Copy Invitation Link'
                onClick={() => onCopyInvitationLink(invitation)}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                className={classes.listItem}
                primary='Resend Invitation'
                onClick={() => onResendInvitation(invitation)}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                className={classes.listItem}
                primary='Cancel'
                onClick={onClose}
              />
            </ListItem>
          </List>
        </DialogContent>
        <DialogContent className={classes.dialogContent}>
        {
          state.confirmDeletion
            ? <div className={classes.dialogContentContainer}>
                <Typography className={classes.textContainer}>
                  Are you sure you want to delete this invitation? This team
                  member won&apos;t be able to join the team.
                </Typography>
                <div className={classes.actionContainer}>
                  <Button onClick={handleCancelDeletion}>
                    Back
                  </Button>
                  <Button
                    onClick={() => onDeleteInvitation(invitation)}
                    className={classes.confirmDeletion}
                    disabled={disabled}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            : null
        }
        </DialogContent>
      </SwipeableViews>
    </Dialog>
  );
});