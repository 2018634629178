import { makeObservable, action } from 'mobx';

import { CollectionStore } from 'modules/base';

export class FeedbackStore extends CollectionStore {
  baseUrl = '/tracking/feedback';

  constructor(config) {
    super(config);

    makeObservable(this, {
      publish: action,
      unpublish: action,
    });
  }

  get newItem() {
    return {
      value: '',
      options: [],
      publishedAt: null,
      unpublishedAt: null,
    };
  }

  import(item) {
    item.publishedAt = item.publishedAt ? new Date(item.publishedAt) : null;
    item.unpublishedAt = item.unpublishedAt ? new Date(item.unpublishedAt) : null;
    return super.import(item);
  }

  async publish(feedback, date = null) {
    feedback.publishedAt = date || new Date();
    feedback.unpublishedAt = null;
    return this.save(feedback);
  }

  async unpublish(feedback, date = null) {
    feedback.publishedAt = null;
    feedback.unpublishedAt = date || new Date();
    return this.save(feedback);
  }
}
