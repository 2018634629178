import {
  values, sortBy, compose, prop, map, assoc, toPairs, groupBy,
  flatten as rFlatten, filter, pathOr, propEq, all, identity, find,
} from 'ramda';

const assocType = ([type, questions]) => map(assoc('type', type), questions);

export const flatten = compose(
  sortBy(prop('order')),
  rFlatten,
  values,
  map(assocType),
  toPairs,
);

export const unflatten = groupBy(q => {
  const { type } = q;
  delete q.type;
  return type;
});

export const importAssessment = assessment => compose(
  assoc('questions', flatten(assessment.questions)),
)(assessment);

export const exportAssessment = (assessment) => {
  const a = JSON.parse(JSON.stringify(assessment));
  return assoc('questions', unflatten(a.questions), a);
};

const hasClassification = compose(
  (x) => Boolean(x),
  prop('classification'),
);

export const withClassification = filter(
  q => hasClassification(q) || all(hasClassification, q.options),
);

export function answerFromAssessment(assessment, questions, userId) {
  const scale = filter(propEq('type', 'scale'), questions);
  const multipleChoice = filter(propEq('type', 'multipleChoice'), questions);

  return {
    user: userId,
    assessment: {
      id: assessment._id,
      title: assessment.title,
      description: assessment.description,
    },
    answers: {
      scale: scale.map(answerFromQuestion),
      multipleChoice: multipleChoice.map(answerFromQuestion),
    },
  };
}

export function answerFromQuestion(question) {
  return {
    question: question._id,
    option: null
  };
}

export const getFeature = loc => config => find(
  f => f.location === loc && f.active,
  config.featured
);

export function isComplete(answer) {
  const answers = pathOr([], ['answers'], answer);
  const mapOptions = map(prop('option'));
  return all(identity, mapOptions(answers));
}

export const getTopResults = (answers) => {
  const weightedResults = answers.result.map(result => {
    // Consider not only max scores, but scores that are closest to either 0 or
    // 1. The `normalizedScore` will always be between 0 and 1.
    const minDistance = Math.min(
      result.normalizedScore,
      1 - result.normalizedScore
    );
    return assoc('minDistance', minDistance, result);
  });
  return sortBy(prop('minDistance'), weightedResults).slice(0, 3);
};
