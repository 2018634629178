import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

import { Markdown } from 'components/markdown';

export const LearningCard = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.text.light,    
  },
  content: {
    '&:last-child': {
      paddingBottom: 16,
    },
  },
  title: {
    marginBottom: 16,
  },
  subTitle: {
    marginBottom: 8,
  },
}))(({
  classes,
  className,
  coaching,
}) => {
  return (
    <Card className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <Typography className={classes.title} variant='h3' component='h3'>
          {
            coaching.title
          }
        </Typography>
        <Typography className={classes.subTitle} variant='h4' component='h4'>
          You've learned
        </Typography>
        <Markdown>
          {
            coaching.focusArea.learning
          }
        </Markdown>
      </CardContent>
    </Card>
  );
});

LearningCard.propTypes = {
  coaching: PropTypes.object.isRequired,
  className: PropTypes.string,
};
