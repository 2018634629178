import { black, blue, cyan, purple, red, yellow } from './colors';

export const palette = {
  primary: {
    light: purple[400],
    main: purple[600],
    dark: purple[700],
  },
  secondary: {
    light: cyan[400],
    main: cyan[600],
    dark: cyan[700],
  },
  background: {
    light: '#FFFFFF',
    main: blue[600],
    dark: blue[700],
    darker: blue[800],
  },
  paper: {
    light: '#FFFFFF',
    main: '#FFFFFF',
    darker: black[200],
    dark: black[400],
    darkest: black[600],
  },
  disabled: {
    lightest: black[50],
    lighter: black[100],
    main: black[200],
    dark: black[400],
    darker: black[600],
    darkest: black[800],
  },
  success: {
    lightest: cyan[200],
    lighter: cyan[400],
    light: cyan[500],
    main: cyan[600],
    dark: cyan[700],
  },
  warn: {
    main: red[600],
  },
  error: {
    main: red[600],
  },
  accent: {
    lightest: yellow[100],
    lighter: yellow[300],
    light: yellow[400],
    main: yellow[600],
    darker: yellow[700],
    dark: yellow[800],
    darkest: yellow[900],
    secondary: '#05cbe7',
  },
  text: {
    light: '#FFFFFF',
    lighter: black[400],
    main: black[600],
    dark: black[700],
    darker: black[800],
    darkest: black[900],
    disabled: black[200],
  },
  border: {
    input: '#d4cfff',
  },
  action: {
    contained: {
      primary: `linear-gradient(284deg, ${blue[500]}, ${purple[500]})`,
      warn: 'linear-gradient(291deg, #f24024 214%, red 37%)',
      disabled: '#c6c6c6',
    },
  },
  emotion: {
    aweful: 'radial-gradient(circle at 52% 100%, #e57373, #b71c1c 95%)',
    bad: 'radial-gradient(circle at 52% 100%, #face74, #c79531 95%)',
    ok: 'radial-gradient(circle at 52% 100%, #376bd6, #0538a7 95%)',
    good: 'radial-gradient(circle at 52% 100%, #138985, #0d474e 95%)',
    amazing: 'radial-gradient(circle at 52% 100%, #17aba0, #106869 95%)',
  },
  checkbox: {
    checked: cyan[600],
  },
  radio: {
    checked: cyan[600],
  },
  progress: {
    primary: cyan[600],
    secondary: cyan[200],
  },
  black, blue, cyan, purple, red, yellow,
};
