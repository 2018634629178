import React from 'react';
import { Typography } from '@material-ui/core';
import { ComingSoonCard } from './coming-soon.card';

export const SkillAssessmentSection = () => (
  <div>
    <Typography variant='h2' component='h2'>
      Areas of Focus
    </Typography>
    <ComingSoonCard
      title='2 minute activity'
      text='Choose up to three areas you’d like to further develop via coaching.'
    >
    </ComingSoonCard>
  </div>
);
