import React from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { compose } from 'ramda';

import { ClassificationSection } from './components/classification.section';
import { ClassificationsStore } from 'modules/classifications';
import { loginRequired } from 'modules/auth';
import { useStoreQuery } from 'utils/state';

const decorate = compose(loginRequired, inject(
  'assessmentAnswersStore',
  'classificationsStore'
));

export const ClassificationPage = decorate(({
  classificationsStore,
}) => {
  const { classificationId } = useParams();
  const [ loading, error, classification ] = useStoreQuery(classificationsStore, 'get', {
    args: classificationId,
  });

  return (
    <div className='ClassificationPage PageContent'>
    {
      loading && <div>Loading...</div>
    }
    {
      error && <div>Loading...</div>
    }
    {
      !error && !loading && classification && <>
        <ClassificationSection classification={classification} />
      </>
    }
    </div>
  );
});

ClassificationPage.propTypes = {
  classificationsStore: PropTypes.instanceOf(ClassificationsStore),
};
