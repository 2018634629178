import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    backgroundColor: theme.palette.background.darker,
    color: 'var(--white)',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflowX: 'hidden',
  }
}));

export const AppPage = ({ className, children }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      {
        children
      }
    </div>
  );
};

AppPage.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

AppPage.defaultProps = {
  className: '',
};
