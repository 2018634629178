import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { PageContent } from 'components/layout';
import { AuthService } from 'modules/auth';

const useStyles = makeStyles(theme => ({
  text: {
    marginBottom: theme.spacing(4),
  },
  backLink: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    lineHeight: '1.43',
    letterSpacing: '0.2px',
    textAlign: 'center',
    color: '#6f7faf',
    marginTop: '16px',
  },
}));

export const Page404 = () => {
  const classes = useStyles();

  return (
    <PageContent variant='backgroundPage'>
      <Helmet>
        <title>Not Found</title>
      </Helmet>
      <Typography variant='h1' component='h1'>
        Page Not Found
      </Typography>
      <Typography className={classes.text}>
        The page you are looking could not be found.
      </Typography>
      <Typography className={classes.backLink}>
        Lost?&nbsp;
        <Link className='OutboundLink' to='/dashboard'>Back to Dashboard</Link>
      </Typography>
    </PageContent>
  );
};

Page404.propTypes = {
  authService: PropTypes.instanceOf(AuthService)
};
