import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginBottom: '32px',

    '&:last-child': {
      paddingBottom: 'var(--page-padding)',
      marginBottom: 'calc(-1 * var(--page-padding))',
    },
  },
  center: {
    textAlign: 'center',
  },
  white: {
    backgroundColor: theme.palette.paper.light,
    color: theme.palette.text.dark,
  },
  blue: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.light,
  },
  card: {
    backgroundColor: theme.palette.paper.light,
    color: 'var(--text-color)',
    margin: '0 calc(-1 * var(--page-padding))',
    padding: 'var(--page-padding)',
    borderRadius: '0 25px 0 0',
    flex: '1 1 auto',
  },
  bottomSticky: {
    position: 'relative',
    paddingBottom: '104px',
    flex: '1 1 auto',
    overflowY: 'hidden',

    '&:last-child': {
      paddingBottom: '104px',
      marginBottom: 'calc(-1 * var(--page-padding))',
    },
  },
}));

export const PageSection = React.forwardRef(({
  className = '',
  children,
  center = false,
  variant,
  bottomSticky,
}, ref) => {
  const classes = useStyles();

  return (
    <div ref={ref} className={clsx(classes.root, classes[variant], className, {
      [classes.center]: center !== false,
      [classes.bottomSticky]: bottomSticky,
    })}>
    {
      children
    }
    </div>
  );
});

PageSection.displayName = 'PageSection';

PageSection.propTypes = {
  children: PropTypes.any,
  center: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.string,
  bottomSticky: PropTypes.any,
};
