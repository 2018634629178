import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useNavigate } from 'react-router';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';

import { ColorHeaderCard } from 'components/cards/color-header-card.component';

export const ComingSoonCard = withStyles(theme => ({
  root: {},
  header: {
    backgroundColor: `${theme.palette.black['400']} !important`,
  },
  card: {
    border: `1px solid ${theme.palette.black['400']}`,
  },
  body: {
    display: 'flex',
    marginBottom: theme.spacing(3),
  },
  illustration: {
    width: 69,
    height: 69,
    marginRight: theme.spacing(3),
  },
  text: {
    flexGrow: 1,
  }
}))(({
  classes,
  className,
  title,
  text,
  illustration,
  ctaUrl = '',
}) => {
  const navigate = useNavigate();

  const onClick = () => navigate(ctaUrl);

  return (
    <ColorHeaderCard
      className={clsx(className, classes.root)}
      headerClassName={classes.header}
      cardClassName={classes.card}
      headerTitle={title}
      elevation={0}
    >
      <div className={classes.body}>
        {
          <Typography variant='body1' className={classes.text}>{text}</Typography>
        }
        {
          illustration
            ? <img src={illustration} alt={title} className={classes.illustration} />
            : null
        }
      </div>
      <Button fullWidth size='small' variant='contained' color='primary' onClick={onClick} disabled={true}>
        Coming Soon
      </Button>
    </ColorHeaderCard>
  );
});

ComingSoonCard.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string,
  illustration: PropTypes.string,
  text: PropTypes.string,
  ctaUrl: PropTypes.string,
};
