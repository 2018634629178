import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.main,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
  },
  heading: {
    marginBottom: 0,
    flexGrow: 1,
  },
  icon: {
    margin: 'auto 0',
  },
  preview: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box !important',
    whiteSpace: 'normal',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
  }
}));

export const NotebookEntry = ({
  page,
  onClick = () => {},
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} onClick={() => onClick(page)}>
      <Box className={classes.title}>
        <Typography className={classes.heading} component='h3' variant='h3'>
          {page.title}
        </Typography>
        <ArrowForwardIos className={classes.icon} />
      </Box>

      <Typography className={classes.preview} component='p' variant='body1'>
        {page.content || <i>No Notes...</i>}
      </Typography>
    </Box>
  );
};