import { SingletonStore } from "modules/base";

export class SharedCoachingStore extends SingletonStore {
  baseUrl = '/coachings/shared';

  share(coaching, reason) {
    const data = this.export(this.item$);
    data.coaching = coaching._id;
    data.reason = reason;
    const response = this.httpService.put(this.baseUrl, data);
    Object.assign(this.item$, response.body);
    return this.item$;
  }

  reset() {
    const data = this.export(this.item$);
    data.coaching = null;
    data.reason = null;
    const response = this.httpService.put(this.baseUrl, data);
    Object.assign(this.item$, response.body);
    return this.item$;
  }

  import(data) {
    delete data.user;
    return super.import(data);
  }
}
