import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, useFormState } from 'react-hook-form';

import { getError, wrapRegister } from 'utils/forms';

const NEW_EMAIL_SCHEMA = yup.object().shape({
  newEmail: yup.string().email().required(),
  password: yup.string().required(),
});

const useStyles = makeStyles(theme => ({
  submitButton: {
    width: '100%',
  },
  textField: {
    width: '100%',
    marginBottom: theme.spacing(3),
    color: theme.palette.text.main,

    '&:last-child': {
      marginBottom: theme.spacing(0),
    },
  },
}));

export const UpdateEmailForm = ({
  onSubmit = () => {},
  saving = false,
  className = '',
  formErrors = [],
}) => {
  const classes = useStyles();
  const { register, handleSubmit, control, setError, reset } = useForm({
    resolver: yupResolver(NEW_EMAIL_SCHEMA),
  });
  const { errors } = useFormState({ control });
  const errorFor = getError(errors);
  const reg = wrapRegister(register);

  React.useEffect(() => {
    formErrors.forEach(fe => setError(fe.name, {
      type: fe.reason,
      message: fe.message
    }));
  }, [formErrors]);

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit(reset))}>
      <TextField className={classes.textField} label='Password'
        type='password' name='password' variant='outlined'
        error={Boolean(errorFor('password'))}
        helperText={
          errorFor('password') ||
          'Please enter your password to change your email address'
        }
        disabled={saving} { ...reg('password') }
      />
      <TextField className={classes.textField} label='New Email Address'
        type='email' name='newEmail' variant='outlined'
        error={Boolean(errorFor('newEmail'))}
        helperText={errorFor('newEmail')}
        disabled={saving} { ...reg('newEmail') }
      />
      <Button variant='contained' fullWidth size='large' type='submit'
        disabled={saving}
      >
        {
          saving ? 'Saving...' : 'Update Email'
        }
      </Button>
    </form>
  );
};

UpdateEmailForm.propTypes = {
  onSubmit: PropTypes.func,
  saving: PropTypes.bool,
  className: PropTypes.string,
  formErrors: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    reason: PropTypes.string,
    message: PropTypes.string,
  })),
};
