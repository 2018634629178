import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { withStyles } from '@material-ui/core/styles';
import { sortBy, prop } from 'ramda';

import { GoalCard } from './goal-card.component';
import { isTouch } from 'utils/client';

const CARD_PADDING = 12;

const byOrder = sortBy(prop('order'));

function useRefWidth() {
  const [width, setWidth] = React.useState(0);
  const ref = React.useCallback(node => {
    if (node !== null) {
      setWidth(node.offsetWidth);
    }
  }, []);
  return [width, ref];
}

export const GoalGallery = withStyles(theme => ({
  scrollPane: {
    overflow: 'hidden',
    padding: 0,
    position: 'relative',
    borderRadius: 0,

    '&:hover $navButton': {
      visibility: 'unset',
    },
  },
  coverContainer: {
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
  },
  goalCard: {
    marginRight: CARD_PADDING,
    '&:last-child': {
      marginRight: 0,
    },
  },
  navButton: {
    position: 'absolute',
    top: '50%',
    width: 48,
    height: 48,
    transform: 'translateY(-50%)',
    padding: 'unset',
    minWidth: 'unset',
    color: theme.palette.text.light,
    borderRadius: 32,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    visibility: 'hidden',
  },
  buttonLeft: {
    left: 4,
  },
  buttonRight: {
    right: 4,
  },
}))(({
  classes,
  className = '',
  placeholder = false,
  cardSize = 168,
  bundles = [],
  goalSelected = null,
  onClick = () => {},
  onClickPlaceholder = () => {},
}) => {
  const [outerWidth, outerRef] = useRefWidth();
  const [innerWidth, innerRef] = useRefWidth();
  const [state, setState] = React.useState({
    offset: 0,
    transform: 'translateX(0)',
    navEnabled: false,
  });
  
  const length = bundles.length + (placeholder ? 1 : 0);
  const whiteSpace = (length - 1) * CARD_PADDING;

  const style = {
    transform: state.transform,
    transitionDuration: '200ms',
    width: length * cardSize + whiteSpace,
    height: cardSize,
  };
  
  const right = () => {
    const maxStep = 2 * cardSize + CARD_PADDING;
    const minStep = outerWidth - 90;
    const step = Math.min(minStep, maxStep);

    const offset = Math.max(
      state.offset - (step + CARD_PADDING),
      -1 * (innerWidth - outerWidth)
    );
    setState({ ...state, offset, transform: `translateX(${offset}px)` });
  };

  const left = () => {
    const maxStep = 2 * cardSize + CARD_PADDING;
    const minStep = outerWidth - 90;
    const step = Math.min(minStep, maxStep);

    const offset = Math.min(state.offset + (step + CARD_PADDING), 0);
    setState({ ...state, offset, transform: `translateX(${offset}px)` });
  };

  React.useEffect(() => {
    setState(prev => ({ ...prev, navEnabled: innerWidth > outerWidth }));
  }, [innerWidth, outerWidth]);

  return (
    <div
      className={clsx(classes.scrollPane, className)}
      ref={outerRef}
      style={{
        height: cardSize,
        overflow: isTouch() ? 'auto' : 'hidden',
      }}
    >
      <div className={classes.coverContainer} style={style} ref={innerRef}>
        {
          byOrder(bundles).map(b => (
            <GoalCard
              key={b.goal._id}
              goal={b.goal}
              size={cardSize}
              className={classes.goalCard}
              backgroundColor={
                b.isJourney ? '#17ABA0' : '#507EDB'
              }
              isFinished={b.isFinished}
              selected={goalSelected}
              onClick={() => onClick(b.goal)}
            />
          ))
        }
        {
          placeholder
            ? <GoalCard
                placeholder={true}
                className={classes.goalCard}
                size={cardSize}
                backgroundColor='rgba(0, 0, 0, 0.5)'
                onClick={onClickPlaceholder}
              />
            : null
        }
      </div>
      {
        isTouch() || !state.navEnabled
          ? null
          : <>
            <Button className={clsx(classes.navButton, classes.buttonLeft)} onClick={left}>
              <KeyboardArrowLeftIcon />
            </Button>
            <Button className={clsx(classes.navButton, classes.buttonRight)} onClick={right}>
              <KeyboardArrowRightIcon />
            </Button>
          </>
      }
    </div>
  );
});