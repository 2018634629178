import log from 'loglevel';
import { includes, compose, toLower, either } from 'ramda';

export const isPlatform = str => compose(includes(str), toLower);
export const isWindows = isPlatform('win');
export const isMac = isPlatform('mac');
export const isIOS = either(isPlatform('iphone'), isPlatform('ipad'));
export const isAndroid = isPlatform('linux');
export const isMobile = either(isIOS, isAndroid);

export const OS = {
  WINDOWS: 'windows',
  MAC: 'mac',
  IOS: 'ios',
  ANDROID: 'android',
};

export const varyByOs = (values, defaultValue) => {
  let hostOs;
  let value;

  try {
    const { platform } = navigator;

    if (isWindows(platform)) {
      hostOs = OS.WINDOWS;
    } else if (isMac(platform)) {
      hostOs = OS.MAC;
    } else if (isIOS(platform)) {
      hostOs = OS.IOS;
    } else if (isAndroid(platform)) {
      hostOs = OS.ANDROID;
    }

    value = values[hostOs];

  } catch (err) {
    log.error(err);
  }

  return value || defaultValue;
};

export const storage = {
  setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },

  getItem(key) {
    return JSON.parse(localStorage.getItem(key));
  }
};

export const isTouch = () => {
  return ('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0);
};
