import React from 'react';
import MuiTabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';

export const Tabs = withStyles(theme => ({
  indicator: {
    bottom: 'unset',
    top: 0,
    height: 4,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    width: '100%',
    '& > span': {
      maxWidth: 80,
      width: '100%',
      backgroundColor: theme.palette.accent.main,
    },
  },
}))((props) => <MuiTabs {...props} TabIndicatorProps={{ children: <span /> }} />);
