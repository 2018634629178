import moment from 'moment';

import { CollectionStore } from 'modules/base';

export class TrackerStore extends CollectionStore {
  baseUrl = '/tracking/days';

  constructor(config) {
    super(config);

    this.authService = config.authService;
  }

  get newItem() {
    return {
      feedback: null,
      activities: [],
      journalPage: null,
      date: moment.utc().format('YYYY-MM-DD'),
    };
  }

  export(item) {
    const day = super.export(item);
    day.user = this.authService.currentUser$._id;
    return day;
  }

  import(item) {
    delete item.user;
    return super.import(item);
  }
}
