import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import MuiFab from '@material-ui/core/Fab';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';

import { Emoji } from 'components/emoji';
import { DAILY_FEEDBACK } from 'utils/constants';
import { varyByOs, OS } from 'utils/client';

const Fab = (props) => <MuiFab disableRipple {...props} />;

const Dialog = withStyles({
  root: {
    position: 'relative',
    maxWidth: 'var(--max-content-width)',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  paper: {
    backgroundColor: 'unset',
  }
})(MuiDialog);

const DialogContent = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingBottom: 95,
    isolation: 'isolate',
  }
})(MuiDialogContent);

const bg = 'linear-gradient(90deg, rgba(112, 89, 205, 0.75), rgba(45, 89, 209, 0.75))';
export const MoodTrackerDialog = withStyles(theme => ({
  dialogBackground: {
    backgroundColor: theme.palette.paper.light,
    opacity: 0.9,
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  title: {
    width: '100%',
    textAlign: 'center',
    marginTop: 115,
    color: theme.palette.text.dark,
  },
  menu: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 240,
    height: 60,
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 10px',
    boxSizing: 'border-box',
  },
  fab: {
    position: 'absolute',
    right: 18,
    bottom: 18,
    backgroundImage: 'unset',
    color: theme.palette.text.light,
    '&:hover': {
      backgroundImage: 'unset',
      color: theme.palette.text.light,
    },
  },
  emoji: {
    fontSize: varyByOs({
      [OS.WINDOWS]: 30,
      [OS.MAC]: 40,
      [OS.IOS]: 40,
      [OS.ANDROID]: 35,
    }, 30),
    marginTop: varyByOs({
      [OS.WINDOWS]: 3,
      [OS.MAC]: -5,
      [OS.IOS]: -5,
      [OS.ANDROID]: -2,
    }, -5),
    cursor: 'pointer',
    color: '#000000',
  },
  fabBackground: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundImage: bg,
    borderRadius: 100,
  },
  closeIcon: {
    isolation: 'isolate',
  },
}))(({
  classes,
  open = false,
  onSelectMood = () => { },
  onClose = () => { },
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
    >
      <div className={classes.dialogBackground} />
      <DialogContent>
        <Typography className={classes.title} variant='h2' component='h2'>
          How are you feeling today?
        </Typography>
        <Paper elevation={4} className={classes.menu}>
          {
            DAILY_FEEDBACK.map(df => (
              <Emoji
                key={df.label}
                className={classes.emoji}
                symbol={df.symbol}
                label={df.label}
                onClick={() => onSelectMood(df)}
              />
            ))
          }
        </Paper>
        <Fab className={classes.fab} onClick={onClose}>
          <div className={classes.fabBackground} />
          <CloseIcon className={classes.closeIcon} />
        </Fab>
      </DialogContent>
    </Dialog>
  );
});