import React from 'react';
import { Typography } from '@material-ui/core';
import { ImageSlide } from './image.slide';

export const Manager2Slide = () => (
  <ImageSlide
    src='https://storage.googleapis.com/bestselfy-static/onboarding/manager-onboarding-2.svg'
    alt='coaching skills'
    title='coaching skills'
  >
    <Typography variant='h1' component='h2'>
      Develop your manager coaching skills
    </Typography>

    <Typography>
      Proven bite-sized coaching towards goals that matter to you.
    </Typography>
  </ImageSlide>
);
