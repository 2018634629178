import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import log from 'loglevel';
import { toJS } from 'mobx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFormState, Controller } from 'react-hook-form';
import { pathOr, curry, mergeRight } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';

import { connectObserver, useStoreQuery } from 'utils/state';
import { useSegment } from 'utils/analytics';
import { AccountStore } from 'modules/account';
import { wrapRegister } from 'utils/forms';
import { Typography } from '@material-ui/core';

const getError = curry((errors, field) => pathOr(null, [field, 'message'], errors));

const useStyles = makeStyles(theme => ({
  textField: {
    width: '100%',
    marginBottom: theme.spacing(3),
    color: theme.palette.text.dark,

    '&:last-child': {
      marginBottom: theme.spacing(0),
    },
  },
  radioGroup: {
    marginBottom: theme.spacing(3),
  },
}));

export const BasicInformation = connectObserver(
  'accountStore'
)(({
  accountStore,
  onSave = () => {},
}) => {
  const classes = useStyles();
  const { track } = useSegment();
  const [ state, setState ] = React.useState({
    saving: false,
  });
  const [ error, loading ] = useStoreQuery(accountStore, 'get');
  const account = accountStore.item$;
  const { register, handleSubmit, reset, control } = useForm({
    resolver: yupResolver(accountStore.schema),
    defaultValues: {
      isManager: 'false',
    },
  });
  const { errors } = useFormState({ control });
  const reg = wrapRegister(register);

  const errorFor = getError(errors);
  const onSubmit = async (formData) => {
    const data = mergeRight(accountStore.newItem, formData);
    setState(prev => ({ ...prev, saving: true }));

    const method = account._id ? 'save': 'create';
    try {
      await accountStore[method](data);
      track('Account Updated', { category: 'Users' });
    } catch (err) {
      log.error(err);
      setState(prev => ({ ...prev, saving: false }));
    }

    if (onSave instanceof Function) {
      onSave();
    } else {
      setState(prev => ({ ...prev, saving: false }));
    }
  };


  React.useEffect(() => {
    if (account) {
      const fieldData = toJS(account);
      fieldData.isManager = fieldData.isManager ? 'true' : 'false';
      reset(fieldData);
    }
  }, [account]);

  return (
    <>
      {
        error && <div className='inline-error-message'>Error...</div>
      }
      {
        loading && <div className='loading-indicator'>Loading...</div>
      }
      {
        !error && !loading && account && <>
          <form className='AccountForm' onSubmit={handleSubmit(onSubmit)}>
            <TextField className={classes.textField} label='First Name'
              type='text' name='firstName' variant='outlined'
              error={Boolean(errorFor('firstName'))}
              helperText={errorFor('firstName')}
              { ...reg('firstName') }
            />
            <TextField className={classes.textField} label='Last Name'
              type='text' name='lastName' variant='outlined'
              error={Boolean(errorFor('lastName'))}
              helperText={errorFor('lastName')}
              { ...reg('lastName') }
            />
            <TextField className={classes.textField} label='Country'
              type='text' name='country' variant='outlined'
              error={Boolean(errorFor('country'))}
              helperText={errorFor('country')}
              { ...reg('country') }
            />
            <TextField className={classes.textField} label='Company'
              type='text' name='company' variant='outlined'
              error={Boolean(errorFor('company'))}
              helperText={errorFor('company')}
              { ...reg('company') }
            />
            <TextField className={classes.textField} label='Role'
              type='text' name='role' variant='outlined'
              error={Boolean(errorFor('role'))}
              helperText={errorFor('role')}
              { ...reg('role') }
            />
            <TextField className={classes.textField} label='Year of Birth'
              type='number' name='yearOfBirth' variant='outlined'
              error={Boolean(errorFor('yearOfBirth'))}
              helperText={errorFor('yearOfBirth')}
              {...reg('yearOfBirth') }
            />
            <TextField className={classes.textField} label='Gender'
              type='text' name='gender' variant='outlined'
              error={Boolean(errorFor('gender'))}
              helperText={errorFor('gender')}
              { ...reg('gender') }
            />
            <TextField className={classes.textField} label='Nationality'
              type='text' name='nationality' variant='outlined'
              error={Boolean(errorFor('nationality'))}
              helperText={errorFor('nationality')}
              { ...reg('nationality') }
            />
            <Controller
              render={({
                field: { onChange, value, name, ref },
              }) => (
                <>
                  <Typography variant='h4' component='h4'>
                    Are you currently managing a team?
                  </Typography>
                  <RadioGroup
                    className={classes.radioGroup}
                    aria-label='role'
                    disabled={state.saving}
                    ref={ref}
                    value={value}
                    onChange={onChange}
                    name={name}
                  >
                    <FormControlLabel
                      value='false'
                      control={<Radio />}
                      label='No'
                    />
                    <FormControlLabel
                      value='true'
                      control={<Radio />}
                      label='Yes'
                    />
                    {errorFor(name)}
                  </RadioGroup>
                </>
              )}
              name='isManager'
              control={control}
            />
            <Button variant='contained' type='submit' disabled={state.saving}
              fullWidth color='primary' size='large'
            >
              {
                state.saving ? 'Saving...' : 'Save'
              }
            </Button>
          </form>
        </>
      }
    </>
  );
});

BasicInformation.propTypes = {
  accountStore: PropTypes.instanceOf(AccountStore),
  onSave: PropTypes.func,
};
