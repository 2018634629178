import { makeObservable, action } from 'mobx';
import { getFeature } from 'utils/assessments';
import { both, find, propEq } from 'ramda';

import { CollectionStore } from 'modules/base';

export class AssessmentConfigurationStore extends CollectionStore {
  baseUrl = '/cms/assessment-configuration';

  constructor(config) {
    super(config);

    makeObservable(this, {
      unfeature: action,
    });
  }

  get newItem() {
    return {
      assessment: null,
      assessmentSlug: null,
      featured: {
        dashboard: false,
        menu: false,
      },
    };
  }

  unfeature(assessmentId, type, location) {
    const config = find(
      both(
        propEq('assessment', assessmentId),
        propEq('type', type)
      ),
      this.items$
    );

    if (config) {
      const feature = getFeature(location)(config);
      if (feature) {
        feature.active = false;
      }
    }
  }

  create() {}
  save() {}
  delete() {}
}
