import log from 'loglevel';
import * as yup from 'yup';
import { makeObservable, observable, computed, toJS } from 'mobx';
import { mergeDeepRight } from 'ramda';

const { Promise } = window;

export class SingletonStore {
  _item$ = null;

  baseUrl = null;
  httpService = null;
  getRequestPending = null;
  config = {};

  constructor(config) {
    makeObservable(this, {
      _item$: observable,
      item$: computed,
    });

    this.config = config;
    this.httpService = config.httpService;
  }

  get url() {
    if (this.baseUrl === null) {
      throw new Error(`${this.name} is missing a baseUrl`);
    }

    return this.baseUrl;
  }

  get item$() {
    return this._item$;
  }

  set item$(newItem) {
    this._item$ = newItem;
  }

  get newItem() {
    return {};
  }

  get schema() {
    return yup.object().shape({});
  }

  get(query) {
    if (this.getRequestPending) {
      return this.getRequestPending;
    } else if (!this.item$) {
      this.getRequestPending = this.httpService.get(this.url, { query })
        .then(response => response.body)
        .catch((err) => {
          this.getRequestPending = null;
          log.error(`${this.name}#get`, err);
        });

      this.getRequestPending.then((item) => {
        this.item$ = item && this.import(item);
        this.getRequestPending = null;
      });
      return this.getRequestPending;
    } else {
      return Promise.resolve(this.item$);
    }
  }

  async create(newItem, attrs = {}) {
    try {
      const response = await this.httpService.post(
        this.url,
        this.export(mergeDeepRight(newItem, attrs)),
      );
      const item = this.import(response.body);
      log.debug(`${this.name}#create`, item);
      this.item$ = item;
      return item;
    } catch(err) {
      log.error(`${this.name}#create`, err);
      return err;
    }
  }

  async reload(query) {
    if (this.getRequestPending) {
      return this.getRequestPending;
    } else {
      this.getRequestPending = this.httpService.get(this.url, { query })
        .then(response => response.body)
        .catch((err) => {
          this.getRequestPending = null;
          log.error(`${this.name}#get`, err);
        });

      this.getRequestPending.then((item) => {
        this.item$ = item && this.import(item);
        this.getRequestPending = null;
      });
      return this.getRequestPending;
    }
  }

  async save(item) {
    try {
      const response = await this.httpService.put(this.url, this.export(item));

      Object.assign(this._item$, item, response.body);

      return this._item$;
    } catch(err) {
      log.error(`${this.name}#save`, err);
      return err;
    }
  }

  async delete(item) {
    log.debug(`${this.name}#delete`, toJS(item));
    try {
      await this.httpService.delete(this.url);
      this.item$ = null;
    } catch(err) {
      log.error(`${this.name}#delete`, err);
      return err;
    }
  }

  import(item) {
    return observable(item);
  }

  export(item) {
    return toJS(item);
  }
}
