export const createOverrides = palette => ({
  MuiCardHeader: {
    title: {
      fontFamily: 'Montserrat',
      fontWeight: 800,
      color: palette.text.primary,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    content: {
      overflow: 'hidden',
    }
  },
  MuiButton: {
    root: {
      color: palette.text.main,
      fontFamily: 'Montserrat',
      fontWeight: 600,
      textTransform: 'none',
    },
    contained: {
      backgroundImage: palette.action.contained.primary,
      color: palette.text.light,

      '&:disabled': {
        backgroundImage: 'none',
        backgroundColor: palette.action.contained.disabled,
      },
    },
    outlined: {
      '&:disabled': {
        color: palette.action.contained.disabled,
      },
    },
    sizeLarge: { 
      padding: '10px 22px',
      fontSize: '1rem',
      height: 52,
    },
    sizeMedium: {
      fontSize: '0.9375rem',
    }
  },
  MuiStepIcon: {
    root: {
      color: palette.accent.main,

      '&$active': {
        color: palette.accent.main,
      },

      '&$completed': {
        color: palette.success.main,
      }
    },
  },
  MuiPaper: {
    root: {
      color: palette.text.dark,
    }
  },
});
