import { Be5Section } from './be5.section';
import { SelfDiscoverySection } from './self-discovery.section';
import { SkillAssessmentSection } from './skill-assessment.section';

export const EmployeeProfile = () => (
  <>
    <Be5Section />
    <SelfDiscoverySection />
    <SkillAssessmentSection />
  </>
);
