import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Controller } from 'react-hook-form';

import { getError } from 'utils/forms';

export const RoleInputSlide = withStyles(theme => ({
  form: {
    marginTop: 8,
  },
  slide: {
    flex: '1 0 250px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    justifyContent: 'flex-end',
    boxSizing: 'border-box',
  },
  textField: {
    width: '100%',
    marginBottom: theme.spacing(3),
    color: theme.palette.text.main,

    '&:last-child': {
      marginBottom: theme.spacing(0),
    },
  },
  textContainer: {
    padding: 'var(--page-padding)',
    paddingTop: 50,
    paddingBottom: '5vh',
    isolation: 'isolate',
    backgroundImage: 'linear-gradient(to top, rgba(255, 255, 255, 1) 85%, rgba(255, 255, 255, 0) 100%)',

    '@media(max-width: 320px)': {
      paddingBottom: 12,
    },
  },
}))(({
  classes,
  errors,
  onSubmit,
  control,
  disabled = false,
}) => {
  const errorFor = getError(errors);

  return (
    <div className={classes.slide}>
      <div className={classes.textContainer}>
        <Typography variant='h1' component='h1'>
          Welcome to bestselfy
        </Typography>

        <Typography paragraph>
          Do you manage a team?
        </Typography>

        <form
          className={classes.form}
          onSubmit={onSubmit}
        >
          <Controller
            render={({
              field: { onChange, value, name, ref },
            }) => (
              <RadioGroup
                aria-label='role'
                disabled={disabled}
                ref={ref}
                value={value}
                onChange={onChange}
                name={name}
              >
                <FormControlLabel
                  value='false'
                  control={<Radio />}
                  label='No'
                />
                <FormControlLabel
                  value='true'
                  control={<Radio />}
                  label='Yes'
                />
                {errorFor(name)}
              </RadioGroup>
            )}
            name='isManager'
            control={control}
          />
        </form>
      </div>
    </div>
  );
});

RoleInputSlide.propTypes = {
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
  control: PropTypes.object,
  disabled: PropTypes.bool,
};
