import React from 'react';
import { compose } from 'ramda';
import { action } from 'mobx';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';
import { find, propEq } from 'ramda';

import { PageContent, PageSection } from 'components/layout';
import { SubHeader } from 'components/sub-header';
import { BottomStickyControls } from 'components/bottom-sticky';
import { QuestionCard } from './components/question-card.component';
import { AnswerInputDialog } from './components/answer-input.dialog';
import { AnswerSelectionDialog } from './components/answer-selection.dialog';
import { loginRequired } from 'modules/auth';
import {
  connectObserver,
  useLoaders,
  useStoreQuery,
} from 'utils/state';

const findAnswer = question => find(propEq('question', question?._id));

const decorate = compose(loginRequired, connectObserver(
  'managerDiscoveryQuestionsStore',
  'managerDiscoveryAnswersStore',
));

const useStyles = makeStyles(theme => ({
  root: {
    gap: theme.spacing(3),
  },
}));


export const ManagerDiscoveryPage = decorate(({
  managerDiscoveryQuestionsStore,
  managerDiscoveryAnswersStore,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [state, setState] = React.useState({ questionSelected: null });
  const [qError, qLoading] = useStoreQuery(
    managerDiscoveryQuestionsStore,
    'fetch',
  );
  const [aError, aLoading] = useStoreQuery(
    managerDiscoveryAnswersStore,
    'fetch',
  );
  const questions = managerDiscoveryQuestionsStore.items$;
  const answers = managerDiscoveryAnswersStore.items$;
  
  const { fullScreenLoader } = useLoaders([questions, answers]);
  
  const loading = qLoading || aLoading;
  const error = qError || aError;

  const back = () => navigate('/profile');
  
  const handleQuestionClick = question => () => {
    setState(prev => ({ ...prev, questionSelected: question }));
  };
  
  const handleCloseDialog = async () => {
    const currentAnswer = answer;
    const currentQuestion = state.questionSelected;
    setState(prev => ({ ...prev, questionSelected: false }));

    if (
      currentAnswer && currentQuestion &&
      (currentAnswer.answer.trim() !== '' || currentAnswer.selection.length > 0) &&
      currentAnswer.question === currentQuestion._id
    ) {
      if (currentAnswer._id) {
        await managerDiscoveryAnswersStore.save(currentAnswer);
      } else {
        await managerDiscoveryAnswersStore.create(currentAnswer);
      }
    }
  };
  
  const handleAnswerChange = action(event => {
    if (answer) {
      answer.answer = event.target.value;
    }
  });

  const handleSelectionChange = action(selection => {
    if (answer) {
      answer.selection = selection;
    }
  });

  const getOrCreateAnswer = action(() => {
    let answer = findAnswer(state.questionSelected)(answers);
    if (!answer) {
      answer = managerDiscoveryAnswersStore.newItem;
      answer.question = state.questionSelected._id;
    }
    return answer;
  });

  const answer = React.useMemo(
    () => state.questionSelected ? getOrCreateAnswer() : null,
    [state.questionSelected, answers]
  );
  
  return (
    <PageContent>
      {
        fullScreenLoader
      }
      <Helmet>
        <title>Manager Discovery</title>
      </Helmet>

      <Typography variant='h1' component='h1'>
        Manager Discovery
      </Typography>
      <SubHeader>
        Build greater self-awareness by answering these guided coaching
        questions.  Feel free to repeat at any time - your reflections are
        just for you.
      </SubHeader>

      <PageSection variant='card' className={classes.root} bottomSticky>
        {
          !loading && !error && questions
            ? questions.map(q => (
                <QuestionCard
                  key={q._id}
                  question={q}
                  answer={findAnswer(q)(answers)}
                  onClick={handleQuestionClick(q)}
                />
              ))
            : null
        }
        <BottomStickyControls>
          <Button fullWidth size='large' variant='contained' color='primary' onClick={back}>
            Back
          </Button>
        </BottomStickyControls>
      </PageSection>

      <AnswerInputDialog
        open={!!state.questionSelected && state.questionSelected.options.length === 0}
        question={state.questionSelected?.text}
        answer={answer?.answer}
        onChange={handleAnswerChange}
        onClose={handleCloseDialog}
        onExit={handleCloseDialog}
      />

      <AnswerSelectionDialog
        open={!!state.questionSelected && state.questionSelected.options.length > 0}
        question={state.questionSelected?.text}
        initialSelection={answer?.selection}
        options={state.questionSelected?.options}
        min={state.questionSelected?.minSelectionCount}
        max={state.questionSelected?.maxSelectionCount}
        onChange={handleSelectionChange}
        onClose={handleCloseDialog}
        onExit={handleCloseDialog}
      />
    </PageContent>
  );
});
