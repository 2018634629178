import React from 'react';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import clsx from 'clsx';
import { withStyles } from "@material-ui/core/styles";
import { assoc } from 'ramda';

const changeName = assoc('editName');
const changeNameValue = assoc('name');
const changeDescription = assoc('editDescription');
const changeDescriptionValue = assoc('description');
const confirmDeletion = assoc('confirmDeletion');

export const TeamCardAdminMenu = withStyles(theme => ({
  dialog: {
    width: 340,
  },
  dialogContent: {
    height: 196,
    padding: 0,

    '&:first-child': {
      paddingTop: 0,
    }
  },
  dialogTitle: {
    // width: 328,
    padding: 16,
    textAlign: 'center',
  },
  h3: {
    marginBottom: 0,
  },
  actionList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItem: {
    textAlign: 'center',
    cursor: 'pointer',
  },
  warn: {
    color: theme.palette.warn.main,
  },
  dialogContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '0 16px',
  },
  textContainer: {
    flexGrow: 1,
  },
  actionContainer: {
    height: 50,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  confirmSave: {
    color: theme.palette.success.main,
  },
  confirmDeletion: {
    color: theme.palette.warn.main,
  },
}))(({
  classes,
  team = null,
  open = false,
  disabled = false,
  onClose = () => {},
  onChangeName = () => {},
  onChangeDescription = () => {},
  onDelete,
}) => {
  const [state, setState] = React.useState({
    name: '',
    description: '',
    confirmDeletion: false,
    editName: false,
    editDescription: false,
  });

  const handleEditName = () => setState(changeName(true));
  const handleCancelEditName = () => setState(changeName(false));
  const handleEditDescription = () => setState(changeDescription(true));
  const handleCancelEditDescription = () => setState(changeDescription(false));
  const handleConfirmDeletion = () => setState(confirmDeletion(true));
  const handleCancelDeletion = () => setState(confirmDeletion(false));
  const handleNameValueChange = e => setState(changeNameValue(e.target.value));
  const handleDescriptionValueChange = e => setState(changeDescriptionValue(e.target.value));
  const resetState = () => setState(() => ({
    name: team?.name,
    description: team?.description,
    confirmDeletion: false,
    editName: false,
    editDescription: false,
  }));

  const shouldToggle = state.confirmDeletion
    || state.editName
    || state.editDescription;

  React.useEffect(() => resetState(), [team]);

  if (!team) { return null; }

  return (
    <Dialog classes={{ paper: classes.dialog }} onClose={onClose} open={open}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography variant='h3' component='h3' className={classes.h3}>
          {team.name}
        </Typography>
      </DialogTitle>
      
      <SwipeableViews disabled={true} index={shouldToggle ? 1 : 0}>
        <DialogContent className={classes.dialogContent}>
          <List className={classes.actionList}>
            <Divider />
            <ListItem>
              <ListItemText
                className={classes.listItem}
                primary='Edit Team Name'
                onClick={handleEditName}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                className={classes.listItem}
                primary='Edit Team Description'
                onClick={handleEditDescription}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                className={clsx(classes.listItem, classes.warn)}
                primary='Delete Team'
                onClick={handleConfirmDeletion}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                className={classes.listItem}
                primary='Cancel'
                onClick={onClose}
              />
            </ListItem>
          </List>
        </DialogContent>
        <DialogContent className={classes.dialogContent}>
        {
          state.editName
            ? <div className={classes.dialogContentContainer}>
                <Typography className={classes.textContainer}>
                  Please enter a new name for this team.
                </Typography>
                <Input value={state.name} onChange={handleNameValueChange} />
                <div className={classes.actionContainer}>
                  <Button onClick={handleCancelEditName}>
                    Back
                  </Button>
                  <Button
                    onClick={() => onChangeName(state.name)}
                    className={classes.confirmSave}
                    disabled={disabled}
                  >
                    Save
                  </Button>
                </div>
              </div>
            : null
        }
        {
          state.editDescription
            ? <div className={classes.dialogContentContainer}>
                <Typography className={classes.textContainer}>
                  Please enter a new description for this team.
                </Typography>
                <Input value={state.description} onChange={handleDescriptionValueChange} />
                <div className={classes.actionContainer}>
                  <Button onClick={handleCancelEditDescription}>
                    Back
                  </Button>
                  <Button
                    onClick={() => onChangeDescription(state.description)}
                    className={classes.confirmSave}
                    disabled={disabled}
                  >
                    Save
                  </Button>
                </div>
              </div>
            : null
        }
        {
          state.confirmDeletion
            ? <div className={classes.dialogContentContainer}>
                <Typography className={classes.textContainer}>
                  Are you sure you want to delete this team? Deleting a
                  team will also delete all past team interactions.
                </Typography>
                <div className={classes.actionContainer}>
                  <Button onClick={handleCancelDeletion}>
                    Back
                  </Button>
                  <Button
                    onClick={() => onDelete(team)}
                    className={classes.confirmDeletion}
                    disabled={disabled}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            : null
        }
        </DialogContent>
      </SwipeableViews>
    </Dialog>
  );
});