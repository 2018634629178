import {
  filter, sortBy, prop, compose, head, not, both, length, last, converge,
  identity, indexOf, map, reverse, pick, assoc, flatten, path,
} from 'ramda';

import { sortByCreationDate } from 'utils/date';

export const filterEnabled = filter(prop('enabled'));

export const notDeleted = compose(not, prop('deleted'));

export const isFinished = prop('dateFinished');

export const notFinished = compose(not, isFinished);

export const filterActive = filter(both(notDeleted, notFinished));

export const removeFinished = filter(notFinished);

export const countChecked = compose(length, filter(prop('dateChecked')));

export const getTasksWithNotes = filter(prop('note'));

export const flattenSessions = map(session => ({
  order: session.order,
  ...pick(
    ['title', 'description', 'detailedDescription', 'dateCompleted', 'teaser', 'prepopulateNotes'],
    session.task
  ),
}));

export const createTodoList = focusArea => {
  return {
    sessions: flattenSessions(focusArea.sessions),
    title: focusArea.title,
    media: focusArea.media,
  };
};

export const sortByOrder = sortBy(prop('order'));

export const getCurrentLevel = compose(head, removeFinished, sortByOrder);

export const getCurrentLevelIdx = converge(indexOf, [
  getCurrentLevel,
  identity,
]);

export const getMaxLevel = compose(
  last,
  sortByOrder,
);

export const isMaxLevel = converge((a, b) => a === b || !a, [
  getCurrentLevel,
  getMaxLevel,
]);

export const getNotes = map(
  compose(
    converge(assoc('tasks'), [
      compose(
        reverse,
        sortByCreationDate,
        getTasksWithNotes,
        prop('tasks')
      ),
      identity,
    ]),
    pick(['tasks', 'label', 'order']),
  )
);

export const joinTasks = compose(
  flatten,
  map(prop('tasks')),
  prop('levels'),
);

export const totalCompletion = list => {
  const tasksTotal = joinTasks(list);
  return countChecked(tasksTotal) / tasksTotal.length;
};

export const levelCompletion = level => {
  const tasksDone = countChecked(level.tasks);
  return tasksDone / level.tasks.length;
};

export const getOrder = path(['focusArea', 'tutorial', 'order']);

export const isNumber = n => typeof n === 'number';

export const isTutorial = compose(isNumber, getOrder);

export const isUnfinishedTutorial = both(isTutorial, notFinished);

export const getTutorial = compose(head, sortBy(getOrder), filter(isUnfinishedTutorial));
