import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import queryString from 'query-string';
import * as yup from 'yup';
import clsx from 'clsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFormState } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { renderFieldError, getError, wrapRegister } from 'utils/forms';

const useStyles = makeStyles(theme => ({
  submitButton: {
    marginTop: theme.spacing(2),
  },
  textField: {
    width: '100%',
    marginBottom: theme.spacing(3),
    color: theme.palette.text.main,

    '&:last-child': {
      marginBottom: theme.spacing(0),
    },
  },
  loginLink: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    lineHeight: '1.43',
    letterSpacing: '0.2px',
    textAlign: 'center',
    color: '#6f7faf',
    marginTop: theme.spacing(2),
  },
  hidden: {
    display: 'none',
  },
}));

const RegistrationSchema = yup.object().shape({
  email: yup.string().email().required(),
  coupon: yup.string(),
  pilot: yup.string(),
  company: yup.string(),
  password: yup.string().required().min(8).max(128),
  passwordConfirmation: yup.string().required(),
  tosAccepted: yup.boolean().test(val => val),
});

export const RegistrationForm = ({
  onSubmit = () => {},
  disabled = false,
  formError = null,
}) => {
  const classes = useStyles();
  const { search } = useLocation();
  const query = queryString.parse(search);
  const { register, handleSubmit, control, setError } = useForm({
    resolver: yupResolver(RegistrationSchema),
    defaultValues: {
      couponCode: decodeURIComponent(query.coupon || ''),
      pilot: decodeURIComponent(query.preset || ''),
      company: decodeURIComponent(query.company || ''),
    },
  });
  const { errors } = useFormState({ control });
  const errorFor = getError(errors);
  const reg = wrapRegister(register);

  React.useEffect(() => {
    if (!formError) { return; }
    setError(formError.name, { type: 'api', message: formError.message });
  }, [formError, setError]);

  const submitForm = (data) => {
    if (data.password === data.passwordConfirmation) {
      onSubmit(data);
    } else {
      setError('passwordConfirmation', {
        type: 'notMatch',
        message: 'Password and password confirmation must match',
      });
    }
  };

  return (
    <form className='RegistrationForm' onSubmit={handleSubmit(submitForm)}>
      { renderFieldError(errors, '__all__') }

      <TextField className={clsx(classes.textField, classes.hidden)} label='coupon'
        type='text' name='couponCode' variant='outlined'
        error={Boolean(errorFor('coupon'))} helperText={errorFor('coupon')}
        inputProps={{ readOnly: true }}
        { ...reg('couponCode') }
      />

      <TextField className={clsx(classes.textField, classes.hidden)} label='pilot'
        type='text' name='pilot' variant='outlined'
        error={Boolean(errorFor('pilot'))} helperText={errorFor('pilot')}
        inputProps={{ readOnly: true }}
        {...reg('pilot')}
      />

      <TextField className={classes.textField} label='Email'
        type='text' name='email' variant='outlined'
        error={Boolean(errorFor('email'))} helperText={errorFor('email')}
        { ...reg('email') }
      />

      <TextField className={classes.textField} label='Password'
        type='password' name='password' variant='outlined'
        error={Boolean(errorFor('password'))}
        helperText={errorFor('password')}
        { ...reg('password') }
      />

      <TextField className={classes.textField} label='Confirm Password'
        type='password' name='passwordConfirmation' variant='outlined'
        error={Boolean(errorFor('passwordConfirmation'))}
        helperText={errorFor('passwordConfirmation')}
        { ...reg('passwordConfirmation') }
      />

      <TextField className={classes.hidden} label='Company'
        type='text' name='company' variant='outlined'
        error={Boolean(errorFor('company'))}
        helperText={errorFor('company')}
        { ...reg('company') }
      />

      <FormControl error={errors.tosAccepted}>
        <FormControlLabel
          aria-label='To-do title'
          control={<Checkbox name='tosAccepted' {...reg('tosAccepted') } />}
          label={
            <span>
              I&apos;ve read and accept the&nbsp;
              <a target='_blank'
                style={{ textDecoration: 'underline' }}
                href='https://www.bestselfy.com/terms-of-service'
                rel='noopener noreferrer'
              >
                {'Terms of Service'}
              </a>
            </span>
          }
        />
        {
          errors.tosAccepted &&
          <FormHelperText>
            You need to accept the Terms of Service
          </FormHelperText>
        }
      </FormControl>

      <Button fullWidth size='large' variant='contained' type='submit'
        disabled={disabled} className={classes.submitButton}
      >
        Create Account
      </Button>
      <Typography className={classes.loginLink}>
        Already have an account?&nbsp;
        <Link className='OutboundLink' to='/login'>Login</Link>
      </Typography>
    </form>
  );
};
RegistrationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  formError: PropTypes.object,
};
RegistrationForm.defaultProps = {
  disabled: false,
};
