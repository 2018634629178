import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

export const TeamMemberListEntry = ({
  member,
}) => {
  const { account } = member;
  const { firstName, lastName, user, role, avatar } = account;
  const name = (firstName && lastName)
    ? `${firstName} ${lastName}`
    : user.email;
  const initials = (firstName && lastName)
    ? `${account.firstName.charAt(0).toUpperCase()}${account.lastName.charAt(0).toUpperCase()}`
    : user.email.charAt(0).toUpperCase();

  return (
    <ListItem key={member._id}>
      <ListItemAvatar>
        <Avatar alt={name} src={avatar}>
          {initials}
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={<b>{name}</b>} secondary={role || 'Team Member'} />
    </ListItem>
  );
};
