import React from 'react';

import { StoreContext } from 'modules/contexts';

const sync = (f, setLoading, setError = () => {}) => async (...args) => {
  setLoading(true);

  try {
    const val = await f(...args);
    return val;
  } catch (err) {
    setError(err);
  } finally {
    setLoading(false);
  }
};

export const useNotebooks = () => {
  const { notebooksStore } = React.useContext(StoreContext);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  
  const fetch = sync(() => notebooksStore.fetch(), setLoading, setError);
  const create = sync((data) => notebooksStore.create(data), setLoading, setError);
  const get = sync((id) => notebooksStore.get(id), setLoading, setError);
  const save = sync((updates) => notebooksStore.save(updates), setLoading, setError);
  const savePage = sync((updates) => notebooksStore.savePage(updates), setLoading, setError);
  const forCoaching = sync((id) => notebooksStore.forCoaching(id), setLoading, setError);

  return {
    loading,
    error,
    store: notebooksStore,
    fetch,
    create,
    get,
    save,
    savePage,
    forCoaching,
  };
};
