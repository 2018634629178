import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { compose } from 'ramda';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { PageContent, PageSection } from 'components/layout';
import { NoteDialog } from 'components/notes';
import { NotebookEntry } from './components/notebook-entry.component';
import { useLoaders, useAsyncEffect } from 'utils/state';
import { loginRequired } from 'modules/auth';
import { HeaderPrefix } from 'components/header-prefix';
import { BottomStickyControls } from 'components/bottom-sticky';
import { Button } from '@material-ui/core';
import { useNotebooks } from 'modules/notebooks';

const decorate = compose( loginRequired, observer );

const useStyles = makeStyles(theme => ({
  notebooks: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  description: {
    marginBottom: theme.spacing(2),
  },
}));

export const NotebookPage = decorate(({
  notebooksStore,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { id } = useParams();
  const { get: getNotebook, savePage } = useNotebooks();
  const [pageSelected, selectPage] = React.useState(null);
  const [sheetOpen, openSheet] =  React.useState(false);
  const [notebook, setNotebook] = React.useState(null);

  const { fullScreenLoader } = useLoaders([notebook]);

  useAsyncEffect(async () => {
    const item = await getNotebook(id);
    setNotebook(item);
  });

  const handleOpen = React.useCallback((page) => {
    selectPage(page);
    openSheet(true);
  });

  const handleClose = React.useCallback(() => {
    openSheet(false);
    savePage(pageSelected);
  });

  const handleChange = React.useCallback((content) => {
    pageSelected.content = content;
  });

  return (
    <PageContent>
      <Helmet>
        <title>Notebook{ notebook ? `- ${notebook.title}`: '' }</title>
      </Helmet>

      {
        fullScreenLoader
      }

      <Typography component='h1' variant='h1'>
        <HeaderPrefix>Notes</HeaderPrefix>
        {notebook && notebook.title}
      </Typography>

      <Typography className={classes.description} component='p' variant='body1'>
        Review and edit your notes from previous coachings.
      </Typography>

      <PageSection variant='card' bottomSticky>
        <Typography component='h2' variant='h2'>
          Session Notes
        </Typography>
        <Box className={classes.notebooks}>
          {
            notebook && notebook.pages.map(page => (
              <NotebookEntry
                key={page._id}
                page={page}
                onClick={handleOpen}
              />
            ))
          }
        </Box>

        <BottomStickyControls>
          <Button
            size='large'
            variant='contained'
            onClick={() => navigate('/coaching-journey/notes')}
            fullWidth
          >
            Back
          </Button>
        </BottomStickyControls>
      </PageSection>

      <NoteDialog
        open={sheetOpen}
        title={pageSelected?.title}
        text={pageSelected?.content}
        onClose={handleClose}
        onChange={handleChange}
      />
    </PageContent>
  );
});