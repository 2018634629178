import { CollectionStore } from 'modules/base';

export class TeamsStore extends CollectionStore {
  baseUrl = '/teams';

  async createTeam(name) {
    const response = await this.httpService.post(this.baseUrl, { name });
    const newTeam = this.import(response.body);
    this.items$.push(newTeam);

    return newTeam;
  };

  async fetchInvitations(teamId) {
    const payload = { teamId };
    const response = await this.httpService.post(
      `${this.baseUrl}/invitations`,
      payload
    );
    return response.body;
  }

  async joinTeam(team, invitation) {
    const url = `${this.baseUrl}/${team._id}/join`;
    const response = await this.httpService.post(url, { invitation });
    const newTeam = response.body;
    Object.assign(newTeam, this.import(response.body));
    return newTeam;
  }

  async inviteNewMember(team, data) {
    const response = await this.httpService.post(
      `${this.baseUrl}/${team._id}/invite`,
      data
    );
    team.invitations = response.body.invitations;
    return team;
  }

  async deletePendingInvitation(team, email) {
    const response = await this.httpService.post(
      `${this.baseUrl}/${team._id}/uninvite`,
      { email }
    );
    team.invitations = response.body.invitations;
    return team;
  }

  async removeMember(team, member) {
    const response = await this.httpService.post(
      `${this.baseUrl}/${team._id}/removeMember`,
      { member: member._id }
    );
    team.members = response.body.members;
    return team;
  }

  async leaveTeam(team) {
    const response = await this.httpService.post(
      `${this.baseUrl}/${team._id}/leave`,
    );
    team.members = response.body.members;
    return team;
  }

  async count() {
    const response = await this.httpService.get(
      `${this.baseUrl}/count`,
    );
    return response.body?.count || 0;
  };
};
