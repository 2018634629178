import * as log from 'loglevel';
import { computed, observable, makeObservable, toJS, runInAction } from 'mobx';
import { propEq, findIndex, mergeDeepRight, pathOr } from 'ramda';

export class CollectionStore {
  _items$ = [];
  _cacheState = 'cold';

  baseUrl = null;
  httpService = null;
  config = {};

  constructor(config) {
    makeObservable(this, {
      items$: computed,
      _items$: observable,
    });

    this.config = config;
    this.httpService = config.httpService;
  }

  get url() {
    if (this.baseUrl === null) {
      throw new Error(`${this.constructor.name} is missing a baseUrl`);
    }

    return this.baseUrl;
  }

  get items$() {
    return this._items$;
  }
  set items$(data) {
    runInAction(() => this._items$ = data);
  }

  get newItem() {
    return {};
  }

  get name() {
    return this.constructor.name;
  }

  async fetch(opts = {}) {
    if (this.cacheState === 'warm' && !pathOr(false, ['force'], opts)) {
      return this.items$;
    }

    try {
      const response = await this.httpService.get(this.url, opts);
      const items = response.body;
      runInAction(() => this.items$.replace(items.map(this.import.bind(this))));
      this.cacheState = 'warm';
      return this.items$;
    } catch(err) {
      log.error(`${this.constructor.name}#fetch`, err);
    }
  }

  async get(id) {
    if (id === 'new') {
      return this.import(this.newItem);
    } else if (id === null) {
      return null;
    }

    try {
      let item = this.items$.find((a) => a._id === id);
      if ( item ) {
        return item;
      } else {
        const response = await this.httpService.get(`${this.url}/${id}`);
        item = this.import(response.body);
        runInAction(() => this.items$.unshift(item));
        return item;
      }
    } catch(err) {
      log.error(`${this.constructor.name}#get`, err);
      return err;
    }
  }

  async create(newItem, attrs = {}) {
    try {
      const response = await this.httpService.post(
        this.url,
        this.export(mergeDeepRight(newItem, attrs)),
      );
      if (response.body instanceof Array) {
        const items = response.body.map(item => this.import(item));
        this.items$ = [ ...this.items$, ...items];
        return items;
      } else {
        const item = this.import(response.body);
        this.items$.push(item);
        return item;
      }
    } catch(err) {
      log.error(`${this.constructor.name}#create`, err);
      return err;
    }
  }

  async save(item) {
    const idx = findIndex(propEq('_id', item._id))(this.items$);

    try {
      const response = await this.httpService.put(
        `${this.url}/${item._id}`,
        this.export(item)
      );

      Object.assign(item, this.import(response.body));

      if ( idx >= 0 ) {
        this.items$[idx] = item;
      }

      return item;
    } catch(err) {
      log.error(`${this.constructor.name}#save`, err);
      return err;
    }
  }

  async delete(item) {
    try {
      const url = `${this.url}/${item._id}`;
      await this.httpService.delete(url);
      const foundById = this.items$.find(propEq('_id', item._id));
      this.items$.remove(foundById);
    } catch(err) {
      log.error(`${this.constructor.name}#delete`, err);
      return err;
    }
  }

  import(item) {
    return observable(item);
  }

  export(item) {
    return toJS(item);
  }
}
