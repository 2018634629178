import React from 'react';
import moment from 'moment';
import { curry, mergeRight, sortBy, prop } from 'ramda';

const TRANSLATE = {
  years: 'year',
  quarters: 'quarter',
  months: 'month',
  weeks: 'isoweek',
  days: 'day',
  hours: 'hour',
  minutes: 'minute',
  seconds: 'second',
  milliseconds: 'millisecond',
};

export const compareDate = curry((date1, date2) => date1.format('YYYYMMDD') === date2.format('YYYYMMDD'));

export const isPastDay = (date) => moment.utc().startOf('day').isAfter(moment.utc(date).startOf('day'));
export const isFutureDay = (date) => moment.utc(date).startOf('day').isAfter(moment.utc().startOf('day'));

export const isToday = (date) => compareDate(moment.utc(), date);

export const isWeekend = (date) => (date.format('dd') === 'Sa' || date.format('dd') === 'Su');

export const sortByCreationDate = sortBy(prop('createdAt'));

// eslint-disable-next-line react/display-name
export const withResetTimer = resetInterval => Component => props => {
  const getTimerInfo = () => {
    const startOf = TRANSLATE[resetInterval] || 'day';
    const now = moment.utc();
    const destination = moment.utc(now).add(1, resetInterval).startOf(startOf);
    const timeUntilReset = destination.valueOf() - now.valueOf();

    return {
      destination: destination.valueOf(),
      timeUntilReset,
    };
  };

  const [ timerInfo, setTimerInfo ] = React.useState(getTimerInfo());

  React.useEffect(() => {
    const timeout = setTimeout(
      () => setTimerInfo(getTimerInfo()),
      timerInfo.timeUntilReset
    );

    return () => clearTimeout(timeout);
  }, [timerInfo]);

  const mergedProps = mergeRight(props, { timerInfo });

  return <Component { ...mergedProps } />;
};
