import React from 'react';
import { Typography } from '@material-ui/core';
import { ImageSlide } from './image.slide';

export const Manager1Slide = () => (
  <ImageSlide
    src='https://storage.googleapis.com/bestselfy-static/onboarding/manager-onboarding-1.svg'
    alt='self-awareness'
    title='self-awareness'
  >
    <Typography variant='h1' component='h2'>
      Build deeper self-awareness
    </Typography>

    <Typography>
      Take the be5 personality assessment and receive personalized coaching -
      track your mood daily too!
    </Typography>
  </ImageSlide>
);
