import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, useFormState } from 'react-hook-form';

import { getError, wrapRegister } from 'utils/forms';

const NEW_PASSWORD_SCHEMA = yup.object().shape({
  currentPassword: yup.string().min(8).required(),
  newPassword: yup.string().min(8).required(),
  newPasswordConfirmation: yup.string().min(8).required(),
});

const useStyles = makeStyles(theme => ({
  textField: {
    width: '100%',
    marginBottom: theme.spacing(3),
    color: theme.palette.text.main,

    '&:last-child': {
      marginBottom: theme.spacing(0),
    },
  },
}));

export const UpdatePasswordForm = ({
  onSubmit = () => {},
  saving = false,
  className = '',
  formErrors = [],
}) => {
  const classes = useStyles();
  const { register, handleSubmit, control, setError, reset } = useForm({
    resolver: yupResolver(NEW_PASSWORD_SCHEMA),
  });
  const errors = useFormState({ control });
  const reg = wrapRegister(register);
  const errorFor = getError(errors);

  React.useEffect(() => {
    formErrors.forEach(fe => setError(fe.name, {
      type: fe.reason,
      message: fe.message
    }));
  }, [formErrors]);

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit(reset))}>
      <TextField className={classes.textField} label='Current Password'
        type='password' name='currentPassword' variant='outlined'
        error={Boolean(errorFor('currentPassword'))}
        helperText={errorFor('currentPassword')}
        disabled={saving}
        { ...reg('currentPassword') }
      />
      <TextField className={classes.textField} label='New Password'
        type='password' name='newPassword' variant='outlined'
        error={Boolean(errorFor('newPassword'))}
        helperText={errorFor('newPassword')}
        disabled={saving}
        { ...reg('newPassword') }
      />
      <TextField className={classes.textField} label='New Password Confirmation'
        type='password' name='newPasswordConfirmation'
        variant='outlined' disabled={saving}
        error={Boolean(errorFor('newPasswordConfirmation'))}
        helperText={errorFor('newPasswordConfirmation')}
        { ...reg('newPasswordConfirmation') }
      />
      <Button variant='contained' fullWidth size='large' type='submit'
        disabled={saving}
      >
        {
          saving ? 'Saving...' : 'Update Password'
        }
      </Button>
    </form>
  );
};

UpdatePasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  saving: PropTypes.bool,
  className: PropTypes.string,
  formErrors: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    reason: PropTypes.string,
    message: PropTypes.string,
  })),
};
