import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { LinearProgress } from './linear-progress.component';

export const CoachingProgress = ({
  coaching
}) => {
  const { percent, completed, skipped, total } = coaching;
  return (
    <Box display='flex' >
      <Box display='flex' flexDirection='column' justifyContent='center' flex='1 1 auto' mr={1}>
        <LinearProgress
          variant='determinate'
          value={percent}
        />
      </Box>
      <Box minWidth={55}>
        <Typography variant='body2' color='textSecondary'>
          {completed + skipped}/{total}&nbsp;Sessions
        </Typography>
      </Box>
    </Box>
  );
};