import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { assoc } from 'ramda';

import { CoachingProgress } from 'components/progress';
import {
  TeamMemberCardMenu
} from './team-member-card-menu.component';

const openMenu = assoc('menuOpen');

const useStyles = makeStyles(theme => ({
  root: {
    border: `1px solid ${theme.palette.grey[300]}`,
    color: 'var(--text-color)',
  },
  icon: {
    verticalAlign: 'text-bottom',
    marginRight: theme.spacing(1),
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

export const TeamMemberCard = ({
  className,
  member,
  onRemoveMember = () => {},
  onShowMember = () => {},
  disabled = false,
}) => {
  const classes = useStyles();
  const { account } = member;
  const { firstName, lastName, user, avatar } = account;
  const name = (firstName && lastName)
    ? `${firstName} ${lastName}`
    : user.email;
  const initials = (firstName && lastName)
    ? `${account.firstName.charAt(0).toUpperCase()}${account.lastName.charAt(0).toUpperCase()}`
    : user.email.charAt(0).toUpperCase();

  const [state, setState] = React.useState({
    openMenu: false,
  });

  const handleOpenMenu = () => setState(openMenu(true));
  const handleCloseMenu = () => setState(openMenu(false));
  const handleRemoveMember = member => {
    handleCloseMenu();
    onRemoveMember(member);
  };

  return (
    <>
      <Card className={clsx(classes.root, className)}>
        <CardHeader
          action={
            <IconButton onClick={handleOpenMenu}>
              <MoreVertIcon />
            </IconButton>
          }
          avatar={
            <Avatar className={classes.avatar} src={avatar}>
              {initials}
            </Avatar>
          }
          title={name}
          subheader={account.role || <i>Team Member</i>}
        />
        <CardContent className={classes.content}>
          {
            member.sharedCoaching && member.sharedCoaching.coaching
              ? <Box>
                  <Box mb={1}>
                    <Typography variant='h4' component='h4'>
                      {member.sharedCoaching.coaching.title}
                    </Typography>
                  </Box>
                  <CoachingProgress coaching={member.sharedCoaching.coaching} />
                </Box>
              : <Typography>
                  <InfoOutlinedIcon className={classes.icon} />
                  <i>No coaching shared</i>
                </Typography>
          }
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            color='primary'
            size='small'
            endIcon={<ArrowForwardIcon />}
            onClick={() => onShowMember(member)}
          >
            Show
          </Button>
        </CardActions>
      </Card>

      <TeamMemberCardMenu
        open={state.menuOpen}
        member={member}
        onRemoveMember={handleRemoveMember}
        onClose={handleCloseMenu}
        disabled={disabled}
      />
    </>
  );
};
