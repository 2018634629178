import React from 'react';
import PropTypes from 'prop-types';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router';

import { ColorHeaderCard } from 'components/cards';
import { AssessmentConfigurationStore } from 'modules/cms';
import { connectObserver, useStoreQuery } from 'utils/state';
import { getTopResults } from 'utils/assessments';
import { AssessmentAnswersStore } from 'modules/assessments';
import {
  getFeature,
  getAssessmentForConfig,
} from 'utils/cms';
import { PlaceholderCard } from './placeholder.card';

const featuredInProfile = getFeature('assessment', 'personality-assessment', 'profile');

const TopStrengths = withStyles(theme => ({
  root: {},
  cardContainer: {
    display: 'flex',
    gap: theme.spacing(1.5),
  },
  topRow: {
    width: '100%',
    marginBottom: 12,
  },
  bottomRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
  },
  card: {
    flexGrow: 1,
  },
  headerLarge: {
    textAlign: 'center',
    fontSize: '1em',
  },
  headerSmall: {
    textAlign: 'center',
  },
  image: {
    width: 48,
    height: 48,
  },
  imageContainer: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.blue[100],
    borderRadius: 4,
    width: 48,
    height: 48,
  },
  text: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  textSmall: {
    fontSize: '0.75em',
  },
}))(({ results, classes }) => {
  return <div className={classes.root}>
    <div className={classes.topRow}>
      <ColorHeaderCard
        className={classes.card}
        headerClassName={classes.headerLarge}
        headerTitle={results[0].classification.facet.name}
      >
        <div className={classes.cardContainer}>
          <span className={classes.imageContainer}>
            <img
              className={classes.image}
              src='https://storage.googleapis.com/bestselfy-static/strengths-first.png'
              title='first top strength'
              alt='first top strength'
            />
          </span>
          <Typography className={classes.text}>
            {results[0].classification.facet.topStrength}
          </Typography>
        </div>
      </ColorHeaderCard>
    </div>
    <div className={classes.bottomRow}>
      <ColorHeaderCard
        className={classes.card}
        headerClassName={classes.headerSmall}
        headerTitle={results[1].classification.facet.name}
      >
        <div className={classes.cardContainer}>
          <div className={classes.imageContainer}>
            <img
              className={classes.image}
              src='https://storage.googleapis.com/bestselfy-static/strengths-second.png'
              title='second top strength'
              alt='second top strength'
            />
          </div>
          <Typography className={clsx(classes.text, classes.textSmall)}>
            {results[1].classification.facet.topStrength}
          </Typography>
        </div>
      </ColorHeaderCard>
      <ColorHeaderCard
        className={classes.card}
        headerClassName={classes.headerSmall}
        headerTitle={results[2].classification.facet.name}
      >
        <div className={classes.cardContainer}>
          <div className={classes.imageContainer}>
            <img
              className={classes.image}
              src='https://storage.googleapis.com/bestselfy-static/strengths-third.png'
              title='third top strength'
              alt='third top strength'
            />
          </div>
          <Typography className={clsx(classes.text, classes.textSmall)}>
            {results[2].classification.facet.topStrength}
          </Typography>
        </div>
      </ColorHeaderCard>
    </div>
  </div>;
});

const decorate = connectObserver(
  'assessmentConfigurationStore',
  'assessmentAnswersStore',
);

const useStyles = makeStyles(theme => ({
  root: {},
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerIcon: {
    margin: 'auto 0',
  },
  clickeable: {
    cursor: 'pointer',
  },
}));

export const Be5Section = decorate(({
  assessmentConfigurationStore,
  assessmentAnswersStore,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [error, loading, configs] = useStoreQuery(assessmentConfigurationStore, 'fetch');

  const [state, setState] = React.useState({
    answer: null,
    config: null,
  });

  const handleClick = React.useCallback(() => {
    if (state.answer) {
      navigate(`/assessments/result/${state.answer._id}?redirectTo=/profile`);
    }
  });

  React.useEffect(() => {
    if (!configs) { return; }
    const feature = featuredInProfile(configs);
    if (!feature) { return; }
    const config = getAssessmentForConfig(feature)(configs);

    (async function() {
      const answers = await assessmentAnswersStore.fetch(config.assessment);
      setState(prev => ({ ...prev, config, answer: answers[0] }));
    })();
  }, [configs]);

  return <div>
    <Typography
      variant='h2'
      component='h2'
      onClick={handleClick}
      className={clsx(classes.sectionHeader, {
        [classes.clickeable]: !!state.answer,
      })}
    >
      <span>Personality Top Strengths</span>
      {
        !!state.answer
          ? <ArrowForwardIcon className={classes.headerIcon} />
          : null
      }
    </Typography>
    {
      state.answer
        ? <TopStrengths results={getTopResults(state.answer)} />
        : <PlaceholderCard
            title='5 minute assessment'
            text='Learn about your strengths and blindspots based on your personality.'
            ctaUrl={`/assessments/${state.config?.assessment}?redirectTo=/profile`}
          />

    }
  </div>;
});

Be5Section.propTypes = {
  assessmentConfigurationStore: PropTypes.instanceOf(AssessmentConfigurationStore),
  assessmentAnswersStore: PropTypes.instanceOf(AssessmentAnswersStore),
};