export const blue = {
  50: '#9bb6eb',
  100: '#82a3e6',
  200: '#6991e1',
  300: '#507edc',
  400: '#376cd7',
  500: '#1e59d1',
  600: '#0547cc',
  700: '#0539a8',
  800: '#052c84',
  900: '#051e5f',
};
