import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { ArrowIcon } from 'components/icons';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  previous: {
    transform: 'rotate(180deg)',
    opacity: 0.3,
  },
  next: {
    opacity: 0.3,
  },
  hasAction: {
    opacity: 1,
  },
  date: {
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: '600',
  },
});

const noop = () => {};

export const MonthSelector = ({
  date,
  className = '',
  onNext = () => {},
  onPrevious = () => {},
}) => {
  const classes = useStyles();
  const dateString = date.format('MMMM YYYY');

  return (
    <div className={clsx(classes.root, className)}>
      <ArrowIcon className={clsx(classes.previous, {
        [classes.hasAction]: Boolean(onPrevious),
      })} onClick={onPrevious || noop} />
      <span className={classes.date}>{ dateString }</span>
      <ArrowIcon className={clsx(classes.next, {
        [classes.hasAction]: Boolean(onNext)
      })} onClick={onNext || noop} />
    </div>
  );
};

MonthSelector.propTypes = {
  date: PropTypes.any.isRequired,
  className: PropTypes.string,
  onNext: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onPrevious: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};
