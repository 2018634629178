import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFormState } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { curry, pathOr } from 'ramda';

import { wrapRegister } from 'utils/forms';

const getError = curry((errors, field) => pathOr(null, [field, 'message'], errors));

const FormSchema = yup.object().shape({
  email: yup.string().email().required(),
});

const useStyles = makeStyles(theme => ({
  bottomSheet: {
    borderRadius: '8px 8px 0 0',
    maxWidth: 'var(--max-content-width)',
    marginLeft: 'auto',
    marginRight: 'auto',
    inset: 'auto 0 0 0 !important',
  },
  bottomSheetTitle: {
    paddingRight: 50,
    paddingBottom: 0,
    borderRadius: '8px 8px 0 0',
    position: 'relative',
  },
  paper: {
    borderRadius: '8px 8px 0 0',
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  content: {
    paddingLeft: 'var(--page-padding)',
    paddingRight: 'var(--page-padding)',
    marginBottom: theme.spacing(4),
    display: 'flex',
    maxWidth: 'var(--max-content-width)',
    flexDirection: 'column',
  },
  close: {
    position: 'absolute',
    top: 6,
    right: 6,
  },
  pageSection: {
    display: 'block',
  },
  linkContainer: {
    display: 'flex',
    gap: 8,
  },
  link: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: 'auto',
  },
  input: {
    marginBottom: theme.spacing(2),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export const NewMemberBottomSheet = ({
  invitationLink,
  open = false,
  disabled = false,
  onClose = () => {},
  onMemberInvite = () => {},
  onCopyInvitationLink = () => {},
}) => {
  const classes = useStyles();
  const { register, handleSubmit, control, reset } = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues: { email: '' },
  });
  const { errors } = useFormState({ control });

  const reg = wrapRegister(register);
  const errorFor = getError(errors);

  React.useEffect(reset, [open]);

  return (
    <Dialog
      fullScreen
      className={classes.bottomSheet}
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
      PaperProps={{
        className: classes.paper
      }}
    >
      <DialogTitle disableTypography className={classes.bottomSheetTitle}>
        <Typography className={classes.title} variant='h2' component='h2'>
          Add Team Members
        </Typography>
        <IconButton className={classes.close} onClick={onClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <form id='inviteNewMember' onSubmit={handleSubmit(onMemberInvite)}>
          <TextField className={classes.input} type='text' variant='outlined'
            label='Email' name='email' error={Boolean(errorFor('email'))}
            helperText={errorFor('email')} fullWidth
            { ...reg('email') }
          />
        </form>

        <Typography variant='h3' component='h3'>
          Invitation Link
        </Typography>
        <Box className={classes.linkContainer}>
          <Typography className={classes.link}>
            {invitationLink}
          </Typography>
          <Button variant='outlined' size='small' onClick={onCopyInvitationLink}>
            Copy
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button fullWidth form='inviteNewMember' type='submit'
          size='large' variant='contained' color='primary' disabled={disabled}
        >
          {
            disabled
              ? 'Sending...'
              : 'Send Invitation'
          }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NewMemberBottomSheet.propTypes = {
  invitationLink: PropTypes.string.isRequired,
  open: PropTypes.bool,
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
  onMemberInvite: PropTypes.func,
  onCopyInvitationLink: PropTypes.func,
};
