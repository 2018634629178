import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
  },
  content: {
    position: 'relative',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  button: {
    flex: '1 1 0',
  },
  date: {
    fontSize: '0.875rem',
    color: theme.palette.text.disabled,
  },
}));

export const PastCoachingCard = ({
  session,
  notes,
  onClick = () => {},
  onClickNotes = () => {},
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Typography
          className={classes.title}
          variant='h4'
          component='h4'
        >
          {
            session.title
          }
        </Typography>
        <Typography className={classes.date}>
          {
            moment(session.dateCompleted).calendar()
          }
        </Typography>
        <Box className={classes.buttons}>
          <Button
            className={classes.button}
            variant='outlined'
            color='primary'
            size='small'
            onClick={onClick}
          >
            Review Session
          </Button>
          {
            notes
              ? <Button
                variant='outlined'
                color='secondary'
                size='small'
                onClick={onClickNotes}
              >
                Notes
              </Button>
              : null
          }
        </Box>
      </CardContent>
    </Card>
  );
};