import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import {
  assoc, compose, map, find, propEq, filter, includes, not, clone, sortBy, prop,
  path,
} from 'ramda';
import { makeStyles } from '@material-ui/core/styles';

import { PageContent, PageSection } from 'components/layout';
import { TopicComponent as Topic } from 'components/topic';
import { GoalSheet } from 'components/goals';
import { HeaderPrefix } from 'components/header-prefix';
import { TopicsStore } from 'modules/topics';
import { GoalsStore } from 'modules/goals';
import { CoachingsStore } from 'modules/coachings';
import { loginRequired } from 'modules/auth';
import { useSegment } from 'utils/analytics';
import { connectObserver, useStoreQuery, useLoaders } from 'utils/state';

const byOrder = sortBy(prop('order'));

const decorate = compose(loginRequired, connectObserver(
  'goalsStore',
  'topicsStore',
  'coachingsStore',
  'subscriptionStore',
));

const useStyles = makeStyles(theme => ({
  topic: {
    marginBottom: theme.spacing(2),

    '&:last-child': {
      marginBottom: theme.spacing(0),
    },
  },
}));

const getRecommendations = (recs, focusAreas) => {
  let handledFas = [];
  const hydratedRecs = compose(
    map(r => {
      if (r.focusAreas === null) { return clone(r); }

      const fas = map(
        fa => find(propEq('_id', fa), focusAreas),
        r.focusAreas
      );
      handledFas = handledFas.concat(fas);
      return assoc('focusAreas', fas, r);
    })
  )(recs);

  const reducedFocusAreas = filter(
    fa => not(includes(fa, handledFas)),
    focusAreas
  );

  return [hydratedRecs, reducedFocusAreas ];
};

export const TopicsPage = decorate(({
  goalsStore,
  topicsStore,
  subscriptionStore,
  onStart = () => {},
  submitting = false,
}) => {
  const getEmptyState = () => ({
    goalSelected: null,
    faSelected: null,
    open: false,
    focusAreas: null,
    recommendations: null,
  });
  const classes = useStyles();
  const navigate = useNavigate();
  const { track } = useSegment();
  const { goalId, faId } = useParams();
  const [tError, tLoading, topics] = useStoreQuery(topicsStore, 'fetch');
  const [sError, sLoading, sub] = useStoreQuery(subscriptionStore, 'get');
  const [state, setState] = React.useState(getEmptyState());

  const { fullScreenLoader } = useLoaders([!tLoading, !sLoading]);

  const loading = tLoading || sLoading;
  const error = tError || sError;

  const close = () => {
    setState(prev => ({ ...prev, open: false }));
    track('Goal Sheet Closed', {
      categories: 'Coachings',
      goal: path(['goalSelected', 'title'], state),
      goalId: path(['goalSelected', '_id'], state),
    });
    navigate('/topics');
  };
  const showGoal = g => {
    navigate(`/topics/goal/${g._id}`);

    track('Goal Sheet Opened', {
      categories: 'Coachings',
      goal: g.title,
      goalId: g._id,
    });
  };
  const back = () => {
    if (!state.goalSelected) { return; }
    showGoal(state.goalSelected);
    
  };
  const showFocusArea = fa => {
    navigate(`/topics/goal/${state.goalSelected._id}/focus-area/${fa._id}`);

    track('Focus Area Clicked', {
      categories: 'Coachings',
      goal: state.goalSelected.title,
      goalId: state.goalSelected._id,
      focusArea: fa.title,
      focusAreaId: fa._id,
    });
  };

  React.useEffect(() => {
    if (goalId) {
      setState({ ...getEmptyState(), open: true });

      (async () => {
        const goal = await goalsStore.get(goalId);
        const recs = await goalsStore.getRecommendations(goal);
        const [recommendations, focusAreas] = getRecommendations(recs, goal.focusAreas);
  
        setState(prev => ({
          ...prev,
          goalSelected: goal,
          faSelected: null,
          open: true,
          focusAreas,
          recommendations,
        }));
      })();
    } else {
      setState(prev => ({ ...prev, open: false }));
    }
  }, [goalId, goalsStore]);

  React.useEffect(() => {
    if (!state.goalSelected) { return; }

    if (faId) {
      const fa = find(propEq('_id', faId), state.goalSelected.focusAreas || []);
      if (fa) {
        setState(prev => ({ ...prev, faSelected: fa }));
      }
    } else {
      setState(prev => ({ ...prev, faSelected: null }));
    }
  }, [state.goalSelected, faId]);

  return <>
    <PageContent>
      {
        fullScreenLoader
      }
      {
        error && <div>ERROR</div>
      }
      {
        !loading && !error && topics && <>
          <Helmet>
            <title>Start New Coaching</title>
          </Helmet>

          <Typography variant='h1' component='h1'>
            <HeaderPrefix>Start new coaching</HeaderPrefix>
            Choose a goal
          </Typography>

          <PageSection>
            {
              byOrder(topics).map(topic => (
                <Topic
                  key={topic._id}
                  className={classes.topic}
                  topic={topic}
                  onClick={showGoal}
                />
              ))
            }
          </PageSection>
        </>
      }
    </PageContent>
    {
      <GoalSheet
        open={state.open}
        goal={state.goalSelected}
        onStart={onStart}
        handleClose={close}
        onSelect={showFocusArea}
        onBack={back}
        disabled={submitting}
        subscribed={Boolean(sub)}
        focusAreas={state.focusAreas}
        selection={state.faSelected}
        recommendations={state.recommendations}
      />
    }
  </>;
});

TopicsPage.propTypes = {
  topicsStore: PropTypes.instanceOf(TopicsStore),
  goalsStore: PropTypes.instanceOf(GoalsStore),
  coachingsStore: PropTypes.instanceOf(CoachingsStore),
};
