import * as log from 'loglevel';
import { makeObservable, observable, action } from 'mobx';

import { answerFromAssessment } from 'utils/assessments';

export class AssessmentAnswersStore {
  httpService = null;
  answers$ = [];

  constructor(config) {
    this.httpService = config.httpService;

    makeObservable(this, {
      answers$: observable,
      save: action,
    });
  }

  async fetch(assessmentId) {
    try {
      const response = await this.httpService.get(`/assessments/${assessmentId}/answers`);
      const answers = (response.body || []).map((i) => observable(i));
      this.answers$.replace(answers);
      return this.answers$;
    } catch(err) {
      log.debug('AssessmentAnswersStore#fetch', err);
    }
  }

  async fetchAll() {
    try {
      const response = await this.httpService.get('/assessments/answers');
      const answers = (response.body || []).map((i) => observable(i));
      this.answers$.replace(answers);
      return this.answers$;
    } catch(err) {
      log.debug('AssessmentAnswersStore#fetchAll', err);
    }
  }

  async get(answerId) {
    try {
      let answer = this.answers$.find((a) => a._id === answerId);
      if ( answer ) {
        return answer;
      } else {
        const response = await this.httpService.get(`/assessments/answers/${answerId}`);
        answer = observable(response.body);
        this.answers$.push(answer);
        return answer;
      }
    } catch(err) {
      log.debug('AssessmentAnswersStore#get', err);
    }
  }

  getAnswerTemplate(assessment, questions, userId) {
    if (!(assessment && userId)) { return null; }

    const newAnswer = observable(answerFromAssessment(
      assessment,
      questions,
      userId
    ));
    this.answers$.push(newAnswer);
    return newAnswer;
  }

  async save(answer) {
    try {
      const response = await this.httpService.post(
        `/assessments/${answer.assessment.id}/answers`,
        answer,
      );
      Object.assign(answer, response.body);

      return answer;
    } catch (err) {
      return err;
    }
  }

  async getRecommendations(id) {
    try {
      const response = await this.httpService.get(`/recommendations/${id}`);

      return observable(response.body);
    } catch (err) {
      return err;
    }
  }
}
