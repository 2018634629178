import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { GoalGallery } from 'components/goals';

const useStyles = makeStyles(theme => ({
  root: {
  },
  showAll: {
    color: theme.palette.text.light,
  },
}));

export const TopicComponent = ({
  topic,
  className = '',
  onClick = () => {},
}) => {
  const classes = useStyles();
  const { name } = topic;
  const bundles = (topic.goals || []).map(goal => ({ goal }));

  return <div className={clsx(className, classes.root)}>
    <Typography variant='h3' component='h3'>{ name }</Typography>
    <GoalGallery bundles={bundles} onClick={onClick} />
  </div>;
};

TopicComponent.propTypes = {
  topic: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    goals: PropTypes.arrayOf(PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      illustration: PropTypes.string.isRequired,
    }))
  }).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
