export const createTypography = () => ({
  fontFamily: [
    '-apple-system',
    'Poppins',
    '"Apple Color Emoji"',
  ].join(','),
  fontWeight: 'normal',
  fontStretch: 'stretch',
  fontStyle: 'normal',
  letterSpacing: 'normal',

  h1: {
    fontFamily: 'Montserrat',
    fontSize: '2rem',
    fontWeight: 800,
    margin: '0 0 16px 0',

    '&::after': {
      content: '""',
      display: 'block',
      width: '32px',
      height: '5px',
      marginTop: '16px',
      borderRadius: '2.5px',
      backgroundColor: '#04a5df',
    },
  },
  h2: {
    fontFamily: 'Montserrat',
    fontSize: '1.5rem',
    fontWeight: 800,
    marginBottom: 12,
  },
  h3: {
    fontFamily: 'Montserrat',
    fontSize: '1.25rem',
    fontWeight: 800,
    marginBottom: 12,
  },
  h4: {
    fontFamily: 'Montserrat',
    fontSize: '1rem',
    fontWeight: 800,
    margin: 0,
  },
  h5: {},
  h6: {},

  body1: {
    fontSize: '1rem',
  },
  body2: {
    fontSize: '0.875rem',
  },
});
