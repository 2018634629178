import React from 'react';
import Typography from '@material-ui/core/Typography';
import MuiFab from '@material-ui/core/Fab';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiButton from '@material-ui/core/Button';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import EventIcon from '@material-ui/icons/Event';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import moment from 'moment';
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { DateTimePicker } from "@material-ui/pickers";
import { withStyles } from '@material-ui/core/styles';

const Fab = (props) => <MuiFab disableRipple {...props} />;

const pickerTheme = createTheme({
  overrides: {},
  typography: {
    h2: {
      fontFamily: 'Montserrat',
      fontSize: '3.75rem',
      fontWeight: 300,
    },
  },
});

const Dialog = withStyles({
  root: {
    position: 'relative',
    maxWidth: 'var(--max-content-width)',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  paper: {
    backgroundColor: 'unset',
  }
})(MuiDialog);

const DialogContent = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingBottom: 185,
    isolation: 'isolate',
  }
})(MuiDialogContent);

const Button = withStyles({
  root: {
    minWidth: 'unset',
    width: 36,
    height: 36,
  }
})((props) => <MuiButton disableRipple {...props} />);

const ActionButton = withStyles(theme => ({
  actionButtonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: '12px 4px',

    '&:last-child': {
      marginBottom: 0,
    },
  },
  actionButton: {
    color: theme.palette.text.light,
    backgroundColor: theme.palette.accent.main,
    borderRadius: 100,
    '&:hover': {
      backgroundColor: theme.palette.accent.main,
      color: theme.palette.text.light,
    },
  },
  buttonLabel: {
    fontFamily: 'Montserrat',
    fontWeight: '800',
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingRight: 18,
    fontSize: '0.875rem',
  },
  primary: {},
  secondary: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
  },
}))(({
  classes,
  label,
  icon,
  onClick,
  secondary = false,
}) => (
  <div className={classes.actionButtonRow}>
    <Typography className={classes.buttonLabel}>
      { label }
    </Typography>
    <Button className={clsx(classes.actionButton, {
      [classes.secondary]: !(secondary === false),
    })} onClick={onClick}>
      { icon }
    </Button>
  </div>
));

export const CoachingSessionActionsDialog = withStyles(theme => ({
  dialogBackground: {
    backgroundColor: theme.palette.paper.light,
    opacity: 0.9,
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  pickerContainer: {
    display: 'none',
  },
  picker: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  reminderAction: {
    marginTop: 12,
  },
  fab: {
    position: 'absolute',
    right: 18,
    bottom: 96,
    backgroundColor: theme.palette.accent.main,
    color: theme.palette.text.light,
    '&:hover': {
      backgroundColor: theme.palette.accent.main,
      color: theme.palette.text.light,
    },
  },
}))(({
  classes,
  open = false,
  onSetReminder = () => {},
  onSkipSession = null,
  onUnskipSession = null,
  onBack,
  onCompleteSession,
  onClose = () => {},
}) => {
  const [state, setState] = React.useState({
    selectedDate: moment.now(),
    pickerOpen: false,
  });
  const showTimePicker = () => setState({ ...state, pickerOpen: true });
  const hideTimePicker = () => setState({ ...state, pickerOpen: false });
  const setReminder = dateTime => {
    hideTimePicker();
    onSetReminder(dateTime);
  };
  const changeDate = newDate => setState(prev => ({
    ...prev,
    selectedDate: newDate,
  }));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
    >
      <div className={classes.dialogBackground} />
      <DialogContent>
        <div className={classes.pickerContainer}>
          <ThemeProvider theme={pickerTheme}>
            <DateTimePicker
              open={state.pickerOpen}
              ampm={true}
              variant='dialog'
              label='Choose reminder time'
              value={state.selectedDate}
              minutesStep={5}
              disablePast={true}
              onChange={changeDate}
              onAccept={setReminder}
              onClose={hideTimePicker}
            />
          </ThemeProvider>
        </div>
        <div className={classes.actions}>
          <ActionButton label='Set Reminder' icon={<EventIcon />} onClick={showTimePicker} />
        {
          onSkipSession
            ? <ActionButton label='Skip Session' icon={<SkipNextIcon />} onClick={onSkipSession} />
            : null
        }
        {
          onUnskipSession
            ? <ActionButton label='Undo Skip Session' icon={<SkipPreviousIcon />} onClick={onUnskipSession} />
            : null
        }
        {
          onBack
            ? <ActionButton label='Back to Coaching' icon={<KeyboardBackspaceIcon />} onClick={onBack} />
            : null
        }
        {
          onCompleteSession
            ? <ActionButton label='Complete Session' icon={<CheckIcon />} onClick={onCompleteSession} secondary />
            : null
        }
        </div>
        <Fab className={classes.fab} onClick={onClose}>
          <CloseIcon />
        </Fab>
      </DialogContent>
    </Dialog>
  );
});