import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '1rem',
    fontWeight: 400,
    display: 'block',
    paddingBottom: 4,
  },
}));

export const HeaderPrefix = ({ children }) => {
  const classes = useStyles();

  return (
    <span variant='body' className={classes.root}>{ children }</span>
  );
};

HeaderPrefix.propTypes = {
  children: PropTypes.any,
};
