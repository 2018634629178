import React from 'react';
import { inject } from 'mobx-react';
import { Navigate, useLocation } from 'react-router-dom';

export const loginRequired = Component => inject(
  'authService',
)(({
  authService,
  ...props
}) => {
  const [ state, setState ] = React.useState({
    isAuthenticated: null,
  });

  React.useEffect(() => {
    authService.isAuthenticated.then(
      isAuthenticated => setState(prev => ({ ...prev, isAuthenticated }))
    );
  }, [authService.currentUser$]);

  if (state.isAuthenticated === null) {
    return null;
  } else if (state.isAuthenticated) {
    return <Component {...props} />;
  } else {
    const { pathname, search } = useLocation();
    const currentPath = encodeURIComponent(`${pathname}${search}`);
    const redirectTo = `/login?redirect=${currentPath}`;

    return <Navigate to={redirectTo} replace={true} />;
  }
});
