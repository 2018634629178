import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import { Helmet } from "react-helmet";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useNavigate, useParams } from 'react-router-dom';
import {
  compose, groupBy, keys, find, propEq, pathOr, length, prop, filter, not, path,
  any, all, head, sortBy
} from 'ramda';

import {
  CoachingCard,
  CoachingCardMenu,
  NotebookDialog,
  MediaDialog,
} from './components';
import { MoodTracker } from 'components/mood-tracker';
import { GoalGallery } from 'components/goals';
import { PageContent } from 'components/layout';
import { loginRequired } from 'modules/auth';
import { connectObserver, useStoreQuery, useLoaders } from 'utils/state';
import { AuthService } from 'modules/auth';
import { Typography } from '@material-ui/core';
import { guard } from 'utils/decorators';
import { useSegment } from 'utils/analytics';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    paddingBottom: 95,
  },
  goalGallery: {
    marginBottom: 32,
  },
  coachingCard: {
    marginBottom: 12,
  },
  addCoaching: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: 80,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: 4,
    cursor: 'pointer',
  },
  addIcon: {
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: 48,
    color: theme.palette.text.light,
  },
}));

const isFinished = compose(Boolean, prop('dateFinished'));
const removeFinished = filter(c => {
  if (c.isJourney) { return true; }
  return compose(not, isFinished)(c);
});
const byOrder = sortBy(prop('order'));
const byGoal = compose(
  groupBy(c => c.goal._id),
  removeFinished,
);
const getGoal = goals => goalId => find(propEq('_id', goalId), goals);
const getGoals = (goals, coachingsByGoal) => {
  return goals.map(goal => {
    const coachings = coachingsByGoal[goal._id];
    for (let i = 0; i < coachings.length; i++) {
      const previousCoaching = coachings[i - 1];
      const currentCoaching = coachings[i];
      if (previousCoaching) {
        currentCoaching.isLocked = !previousCoaching.dateFinished;
      } else {
        currentCoaching.isLocked = false;
      }
    }

    return {
      goal,
      coachings,
      isJourney: any(prop('isJourney'))(coachingsByGoal[goal._id]),
      order: path(['goal', 'order'], head(coachingsByGoal[goal._id])),
      isFinished: all(prop('dateFinished'))(coachingsByGoal[goal._id]),
    };
  });
};

const EmptyDashboard = withStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    borderRadius: 4,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    height: 120,
    padding: '36px 16px',
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
  row: {
    width: '100%',
    textAlign: 'center',
    color: theme.palette.text.light,
  },
  addIcon: {
    fontSize: 48,
  },
}))(({
  classes,
  onClick = () => {},
}) => (
  <div className={classes.root} onClick={onClick}>
    <div className={classes.row}>
      <Typography>
        Select a goal to get started.
      </Typography>
    </div>
    <div className={classes.row}>
      <AddIcon className={classes.addIcon} />
    </div>
  </div>
));

const decorate = compose(loginRequired, connectObserver(
  'goalsStore',
  'coachingsStore',
));

export const DashboardPage = decorate(({
  goalsStore,
  coachingsStore,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { goalId } = useParams();
  const { track } = useSegment();
  const [ cError, cLoading, coachings ] = useStoreQuery(coachingsStore, 'fetch');
  const [ gError, gLoading, goals ] = useStoreQuery(goalsStore, 'fetch');
  const [ state, setState ] = React.useState({
    menuOpen: false,
    notebookOpen: false,
    mediaOpen: false,
    menuFor: null,
    deleting: false,
  });
  const { fullScreenLoader } = useLoaders([coachings, goals]);

  const loading = cLoading || gLoading;
  const error = cError || gError;
  const coachingsByGoal = (!loading && coachings)
    ? byGoal(coachings)
    : null;

  const filteredGoals = (!loading && goals && coachingsByGoal)
    ? keys(coachingsByGoal).map(getGoal(goals))
    : null;
  
  const bundles = React.useMemo(() => coachingsByGoal && filteredGoals
    ? getGoals(filteredGoals, coachingsByGoal)
    : null, [ coachingsByGoal, filteredGoals ]);

  const linkTo = url => () => navigate(url);
  const showDialog = coaching => () => setState(prev => ({
    ...prev,
    menuOpen: true,
    menuFor: coaching
  }));
  const closeDialog = () => setState(prev => ({
    ...prev,
    menuOpen: false,
  }));
  const closeAfter = f => (...args) => {
    f(...args);
    setState(prev => ({ ...prev, menuOpen: false }));
  };
  const openMedia = closeAfter(() => {
    setState({ ...state, mediaOpen: true });
    track('Media Dialog Opened', {
      category: 'Dashboard',
      coaching: path(['title'], state.menuFor),
    });
  });
  const closeMedia = closeAfter(() => {
    setState({ ...state, mediaOpen: false });
    track('Media Dialog Closed', {
      category: 'Dashboard',
      coaching: path(['title'], state.menuFor),
    });
  });
  const openNotebook = closeAfter(() => {
    setState({ ...state, notebookOpen: true });
    track('Notebook Dialog Opened', {
      category: 'Dashboard',
      coaching: path(['title'], state.menuFor),
    });
  });
  const closeNotebook = closeAfter(() => {
    setState({ ...state, notebookOpen: false });
    track('Notebook Dialog Closed', {
      category: 'Dashboard',
      coaching: path(['title'], state.menuFor),
    });
  });
  const toggleNotifications = closeAfter(c => {
    guard(async () => await coachingsStore.toggleMute(c));
    track('Coaching Notifications Toggled', {
      category: 'Dashboard',
      coaching: path(['title'], c),
      state: c.muted,
    });
  });
  const deleteCoaching = c => {
    setState({ ...state, deleting: true });
    guard(async () => await coachingsStore.delete(c));
    setState({ ...state, menuOpen: false, deleting: false });
    track('Coaching Deleted', {
      category: 'Dashboard',
      coaching: path(['title'], c),
    });
  };
  const continueCoaching = coaching => {
    track('Coaching Continue Clicked', {
      category: 'Dashboard',
      coaching: path(['title'], coaching),
    });
    navigate(`/coachings/${coaching._id}`);
  };
  const selectFirstGoal = () => {
    if (filteredGoals[0]) {
      navigate(`/dashboard/${filteredGoals[0]._id}`, { replace: true });
    }
  };
  const isGoalSelected = () => Boolean(find(propEq('_id', goalId), filteredGoals));
  const hasGoals = () => Boolean(filteredGoals.length);

  React.useEffect(() => {
    if (loading || !filteredGoals) { return; }
    if (hasGoals() && !isGoalSelected()) {
      selectFirstGoal();
    } else if (!hasGoals() && goalId) {
      navigate('/dashboard', { replace: true });
    }
  });

  return <>
    <PageContent className={classes.root}>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Typography variant='h1' component='h1'>
        My Coachings
      </Typography>
      {
        fullScreenLoader
      }
      {
        !loading && !error && <>
        {
          length(bundles)
            ? <GoalGallery
                className={classes.goalGallery}
                bundles={bundles}
                goalSelected={goalId}
                onClick={goal => navigate(`/dashboard/${goal._id}`)}
                placeholder={true}
                onClickPlaceholder={linkTo('/topics')}
              />
            : <EmptyDashboard onClick={linkTo('/topics')} />
        }
        </>
      }
      {
        pathOr([], [goalId], coachingsByGoal).length
          ? <>
              {
                byOrder(pathOr([], [goalId], coachingsByGoal)).map(c => (
                  <CoachingCard
                    key={c._id}
                    coaching={c}
                    className={classes.coachingCard}
                    onOpenMenu={showDialog(c)}
                    onContinue={continueCoaching}
                  />
                ))
              }
            </>
          : null
      }
      {
        goalId
          ? <div
              className={classes.addCoaching}
              onClick={linkTo(`/topics/goal/${goalId}`)}
            >
              <AddIcon className={classes.addIcon} />
            </div>
          : null
      }
      <CoachingCardMenu
        coaching={state.menuFor}
        open={state.menuOpen}
        disabled={state.deleting}
        onClose={closeDialog}
        onClickMedia={openMedia}
        onClickNotebook={openNotebook}
        onToggleNotifications={toggleNotifications}
        onClickDelete={
          state.menuFor?.isJourney
            ? null
            : deleteCoaching
        }
      />
      <NotebookDialog
        coaching={state.menuFor}
        open={state.notebookOpen}
        onClose={closeNotebook}
      />
      <MediaDialog
        coaching={state.menuFor}
        open={state.mediaOpen}
        onClose={closeMedia}
      />
      <MoodTracker />
    </PageContent>
  </>;
});

DashboardPage.propTypes = {
  authService: PropTypes.instanceOf(AuthService),
};
