export const black = {
  50: 'rgba(6, 4, 23, 0.1)',
  100: 'rgba(6, 4, 23, 0.2)',
  200: 'rgba(6, 4, 23, 0.3)',
  300: 'rgba(6, 4, 23, 0.4)',
  400: 'rgba(6, 4, 23, 0.5)',
  500: 'rgba(6, 4, 23, 0.6)',
  600: 'rgba(6, 4, 23, 0.7)',
  700: 'rgba(6, 4, 23, 0.8)',
  800: 'rgba(6, 4, 23, 0.9)',
  900: 'rgba(6, 4, 23, 1.0)',
};
