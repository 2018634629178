import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import SwipeableViews from 'react-swipeable-views';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { assoc } from 'ramda';

const showConfirmation = assoc('showConfirmation');
const setReason = assoc('reason');

const useStyles = makeStyles(theme => ({
  bottomSheet: {
    borderRadius: '8px 8px 0 0',
    maxWidth: 'var(--max-content-width)',
    marginLeft: 'auto',
    marginRight: 'auto',
    inset: 'auto 0 0 0 !important',
  },
  bottomSheetTitle: {
    paddingRight: 50,
    paddingBottom: 0,
    borderRadius: '8px 8px 0 0',
    position: 'relative',
  },
  paper: {
    borderRadius: '8px 8px 0 0',
  },
  title: {
    marginBottom: theme.spacing(0),
  },
  content: {
    paddingLeft: 'var(--page-padding)',
    paddingRight: 'var(--page-padding)',
    marginBottom: theme.spacing(2),
    display: 'flex',
    maxWidth: 'var(--max-content-width)',
    flexDirection: 'column',
  },
  close: {
    position: 'absolute',
    top: 6,
    right: 6,
  },
  pageSection: {
    display: 'block',
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  hidden: {
    display: 'none',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export const ShareCoachingBottomSheet = ({
  open = false,
  disabled = false,
  onClose = () => {},
  onShareCoaching = () => {},
}) => {
  const classes = useStyles();
  const [ state, setState ] = React.useState({
    showConfirmation: false,
    reason: '',
  });

  React.useEffect(() => {
    setState(showConfirmation(false));
  }, [open]);

  return (
    <Dialog
      fullScreen
      className={classes.bottomSheet}
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
      PaperProps={{
        className: classes.paper
      }}
    >
      <DialogTitle disableTypography className={classes.bottomSheetTitle}>
        <Typography className={classes.title} variant='h2' component='h2'>
          Share coaching progress
        </Typography>
        <IconButton className={classes.close} onClick={onClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </DialogTitle>
      <SwipeableViews disabled={true} index={state.showConfirmation ? 1 : 0}>
        <>
          <DialogContent className={classes.content}>
            <Typography>
              By sharing the progress of your coaching, your team lead will be able
              to support you directly in your efforts to improve yourself. Only the
              progress of your coaching will be shared. Your notes will stay private
              and not be shared with anyone.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button fullWidth onClick={() => setState(showConfirmation(true))}
              size='large' variant='contained' color='primary' disabled={disabled}
            >
              Share this Coaching
            </Button>
          </DialogActions>
        </>

        <>
          <DialogContent className={classes.content}>
            <Typography className={classes.description}>
              Give your team leader some context around why you picked this
              particular coaching.
            </Typography>
            <TextField
              className={clsx({ [classes.hidden]: !state.showConfirmation })}
              fullWidth
              multiline
              variant='outlined'
              label='reason'
              rows={8}
              value={state.reason}
              onChange={e => setState(setReason(e.target.value))}
            />
          </DialogContent>
          <DialogActions>
            <Button fullWidth onClick={() => onShareCoaching(state.reason)}
              size='large' variant='contained' color='primary' disabled={disabled}
            >
              Submit and share
            </Button>
          </DialogActions>
        </>
      </SwipeableViews>
    </Dialog>
  );
};
