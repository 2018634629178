import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import {
  Dialog, Slide, Typography, Paper, IconButton, Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { BottomStickyControls } from 'components/bottom-sticky';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 'var(--max-content-width)',
    margin: '0 auto',
  },
  content: {
    padding: 'var(--page-padding)',
    backgroundColor: theme.palette.paper.light,
    height: '100%',
    marginBottom: 76,
    display: 'flex',
    flexDirection: 'column',
  },
  question: {
    marginBottom: '1rem',
    paddingRight: 24,
  },
  answer: {
    flexGrow: 1,
    width: '100%',
    outline: 'none',
    border: 'unset',
    backgroundColor: 'unset',
    color: theme.palette.text.dark,
    font: '400 1em Poppins',
    resize: 'none',
    padding: 0,
    margin: 0,
    boxSizing: 'border-box',
    appearance: 'none',
    '&:focus': {
      outline: 'none',
    }
  },
  closeButton: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export const AnswerInputDialog = ({
  open = false,
  question = '',
  answer = '',
  onClose = () => {},
  onChange = () => {},
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      className={classes.root}
      onClose={onClose}
      TransitionComponent={Transition}
      closeAfterTransition={true}
      fullScreen
    >
      <Paper elevation={0} className={classes.content}>
        <Typography className={classes.question} variant='h4'>{question}</Typography>
        <textarea
          className={classes.answer}
          value={answer}
          onChange={onChange}
          placeholder='Add your answer here...'
        />
      </Paper>
      <IconButton className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <BottomStickyControls>
        <Button fullWidth size='large' variant='contained' color='primary' onClick={onClose}>
          Done
        </Button>
      </BottomStickyControls>
    </Dialog>
  );
};

AnswerInputDialog.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.string,
  placeholder: PropTypes.string,
  open: PropTypes.bool,
  className: PropTypes.string,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
};
