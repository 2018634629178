import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { SimpleCard } from './simple-card.component';

const HEADER_SPACING = 0.5;

const useStyles = makeStyles(theme => ({
  root: {
  },
  header: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: theme.palette.blue['400'],
    color: theme.palette.text.light,
    fontSize: '0.825rem',
  },
  top: {
    paddingTop: theme.spacing(HEADER_SPACING),
    paddingBottom: `calc(${theme.spacing(HEADER_SPACING)}px + 25px)`,
    borderRadius: '4px 4px 0 0',
    marginBottom: -25,
  },
  bottom: {
    paddingTop: `calc(${theme.spacing(HEADER_SPACING)}px + 25px)`,
    paddingBottom: theme.spacing(HEADER_SPACING),
    borderRadius: '0 0 4px 4px',
    marginTop: -25,
  },
  headerText: {
    fontSize: 'inherit',
  },
  contentCard: {
    isolation: 'isolate',
  }
}));

export const ColorHeaderCard = ({
  headerTitle,
  className,
  headerClassName,
  cardClassName,
  elevation = 2,
  headerPosition = 'top',
  ...cardProps
}) => {
  const classes = useStyles();

  const header = (
    <Paper elevation={0} className={clsx(classes.header, classes[headerPosition], headerClassName)}>
      <Typography variant='h4' component='h4' className={classes.headerText}>
        {headerTitle || ''}
      </Typography>
    </Paper>
  );

  return (
    <div className={clsx(classes.root, className)}>
      { headerPosition === 'top' ? header : null }
      <SimpleCard className={clsx(classes.contentCard, cardClassName)} elevation={elevation} {...(cardProps || {})} />
      { headerPosition === 'bottom' ? header : null }
    </div>
  );
};

ColorHeaderCard.propTypes = {
  headerTitle: PropTypes.string,
  headerClassName: PropTypes.string,
  cardClassName: PropTypes.string,
  elevation: PropTypes.number,
  className: PropTypes.string,
  headerPosition: PropTypes.oneOf(['top', 'bottom']),
};
