import React from 'react';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import clsx from 'clsx';
import { withStyles } from "@material-ui/core/styles";

export const CoachingCardMenu = withStyles(theme => ({
  dialog: {
    width: 340,
  },
  dialogContent: {
    height: 196,
    padding: 0,

    '&:first-child': {
      paddingTop: 0,
    }
  },
  dialogTitle: {
    // width: 328,
    padding: 16,
    textAlign: 'center',
  },
  h3: {
    marginBottom: 0,
  },
  actionList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItem: {
    textAlign: 'center',
    cursor: 'pointer',
  },
  warn: {
    color: theme.palette.warn.main,
  },
  dialogContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '0 16px',
  },
  textContainer: {
    flexGrow: 1,
  },
  actionContainer: {
    height: 50,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  confirmDeletion: {
    color: theme.palette.warn.main,
  },
}))(({
  classes,
  coaching = null,
  open = false,
  disabled = false,
  onClose = () => {},
  onClickMedia = () => {},
  onClickNotebook = () => {},
  onToggleNotifications = () => {},
  onClickDelete,
}) => {
  const [state, setState] = React.useState({
    confirmDeletion: false,
  });

  const confirmDeletion = () => setState(prev => ({
    ...prev,
    confirmDeletion: true,
  }));

  const reconsiderDeletion = () => setState(prev => ({
    ...prev,
    confirmDeletion: false,
  }));

  React.useEffect(() => reconsiderDeletion(), [coaching]);

  if (!coaching) { return null; }

  return (
    <Dialog classes={{ paper: classes.dialog }} onClose={onClose} open={open}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography variant='h3' component='h3' className={classes.h3}>
          {coaching.title}
        </Typography>
      </DialogTitle>
      
      <SwipeableViews disabled={true} index={state.confirmDeletion ? 1 : 0}>
        <DialogContent className={classes.dialogContent}>
          <List className={classes.actionList}>
            <Divider />
            <ListItem>
              <ListItemText
                className={classes.listItem}
                primary='Recommended Media'
                onClick={() => onClickMedia(coaching)}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                className={classes.listItem}
                primary='Notebook'
              onClick={() => onClickNotebook(coaching)}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                className={classes.listItem}
                primary={
                  coaching.muted
                    ? 'Turn On Notifications'
                    : 'Turn Off Notifications'
                }
                onClick={() => onToggleNotifications(coaching)}
              />
            </ListItem>
            {
              onClickDelete
                ? <>
                    <Divider /><Divider />
                    <ListItem>
                      <ListItemText
                        className={clsx(classes.listItem, classes.warn)}
                        primary='Delete Coaching'
                        onClick={confirmDeletion}
                      />
                    </ListItem>
                  </>
                : null
            }
          </List>
        </DialogContent>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.dialogContentContainer}>
            <Typography className={classes.textContainer}>
              Are you sure you want to delete this coaching? Deleting a
              coaching will also delete all progress you made.
            </Typography>
            <div className={classes.actionContainer}>
              <Button onClick={reconsiderDeletion}>
                Cancel
              </Button>
              <Button
                onClick={() => onClickDelete(coaching)}
                className={classes.confirmDeletion}
                disabled={disabled}
              >
                Delete
              </Button>
            </div>
          </div>
        </DialogContent>
      </SwipeableViews>
    </Dialog>
  );
});