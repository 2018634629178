import * as yup from 'yup';

import { CollectionStore } from 'modules/base';

export class InvitationTokensStore extends CollectionStore {
  baseUrl = '/auth/tokens';

  get newItem() {
    return {
      value: null,
      expirationDate: null,
      dateRedeemed: null,
      tags: null,
      company: null,
      email: null,
    };
  }

  get schema() {
    return yup.object().shape({
      company: yup.string().nullable(),
      emails: yup.array().of(yup.string().email()).nullable(),
      tags: yup.array().of(yup.string()).nullable(),
      amount: yup.number().default(1),
    });
  }
}
