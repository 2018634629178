import React from 'react';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import clsx from 'clsx';
import SwipeableViews from 'react-swipeable-views';
import { withStyles } from "@material-ui/core/styles";
import { assoc } from 'ramda';

const selectRating = assoc('selectRating');

export const NudgeMenu = withStyles(theme => ({
  dialog: {
    width: 340,
  },
  dialogContent: {
    padding: 0,

    '&:first-child': {
      paddingTop: 0,
    }
  },
  dialogContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '0 16px',
  },
  dialogTitle: {
    padding: 16,
    textAlign: 'center',
  },
  h3: {
    marginBottom: 0,
  },
  actionList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItem: {
    textAlign: 'center',
    cursor: 'pointer',
  },
  warn: {
    color: theme.palette.error.main,
  },
  success: {
    color: theme.palette.success.main,
    fontWeight: 'bold',
  },
}))(({
  classes,
  memberName = 'your team member',
  nudge = null,
  open = false,
  onClose = () => {},
  onOpenSlack = () => {},
  onCopyText = () => {},
  onRateInteraction = () => {},
}) => {
  const [ state, setState ] = React.useState({
    selectRating: false,
  });
  const handleRateInteraction = () => setState(selectRating(true));
  const setRating = (nudge, rating) => {
    onRateInteraction(nudge, rating);
  };

  React.useEffect(() => {
    setState(selectRating(false));
  }, [open]);

  if (!nudge) { return null; }

  return (
    <Dialog classes={{ paper: classes.dialog }} onClose={onClose} open={open}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography variant='h3' component='h3' className={classes.h3}>
          {
            state.selectRating
              ? `Rate ${memberName}'s effort`
              : `Send a message to ${memberName}`
          }
        </Typography>
      </DialogTitle>

      <SwipeableViews disabled={true} index={state.selectRating ? 1 : 0}>
        <DialogContent className={classes.dialogContent}>
          <List className={classes.actionList}>
            <Divider />
            <ListItem>
              <ListItemText
                className={classes.listItem}
                primary='Take me to Slack'
                onClick={() => onOpenSlack(nudge)}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                className={classes.listItem}
                primary='Copy nudge text'
                onClick={() => onCopyText(nudge)}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                className={clsx(classes.listItem, classes.success)}
                primary='Complete coaching conversation'
                onClick={handleRateInteraction}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                className={clsx(classes.listItem, classes.warn)}
                primary='Cancel'
                onClick={onClose}
              />
            </ListItem>
          </List>
        </DialogContent>
        <DialogContent className={classes.dialogContent}>
          <List className={classes.actionList}>
            <Divider />
            <ListItem>
              <ListItemText
                className={classes.listItem}
                primary='Amazing'
                onClick={() => setRating(nudge, 2)}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                className={classes.listItem}
                primary='Good Effort'
                onClick={() => setRating(nudge, 1)}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                className={classes.listItem}
                primary='I need to see more...'
                onClick={() => setRating(nudge, 0)}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                className={clsx(classes.listItem, classes.warn)}
                primary='Cancel'
                onClick={onClose}
              />
            </ListItem>
          </List>
        </DialogContent>
      </SwipeableViews>
    </Dialog>
  );
});