import * as log from 'loglevel';
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as yup from 'yup';
import queryString from 'query-string';
import { inject } from 'mobx-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Helmet } from 'react-helmet';
import { useForm, useFormState } from 'react-hook-form';
import { pathOr, curry } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { PageContent } from 'components/layout';
import { useSegment } from 'utils/analytics';
import { AuthService } from 'modules/auth';
import { wrapRegister } from 'utils/forms';

const getError = curry((errors, field) => pathOr(null, [field, 'message'], errors));

const useStyles = makeStyles(theme => ({
  text: {
    marginBottom: theme.spacing(4),
  },
  textField: {
    width: '100%',
    marginBottom: theme.spacing(3),
    color: theme.palette.text.main,

    '&:last-child': {
      marginBottom: theme.spacing(0),
    },
  },
  loginLink: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    lineHeight: '1.43',
    letterSpacing: '0.2px',
    textAlign: 'center',
    color: '#6f7faf',
    marginTop: theme.spacing(2),
  },
  successMessage: {
    color: theme.palette.success.main,
  },
}));

const LoginSchema = yup.object().shape({
  password: yup.string().min(8).required(),
});

const ResetPasswordPage = inject(
  'authService'
)(({
  authService
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { track } = useSegment();
  const { register, handleSubmit, control, setError, clearErrors } = useForm({
    resolver: yupResolver(LoginSchema),
  });
  const { errors } = useFormState({ control });
  const [ state, setState ] = React.useState({
    successMessage: null,
  });

  const reg = wrapRegister(register);

  async function onLogin({ password }) {
    const search = queryString.parse(location.search) || {};
    clearErrors();
    setState(prev => ({ ...prev, submitting: true }));
    try {
      await authService.updatePassword(search.oobCode, password);
      onPasswordUpdate();
    } catch (err) {
      onPasswordUpdateError(err);
    }
  }

  async function onPasswordUpdate() {
    track('Password Reset Successful', {
      category: 'Users',
    });
    navigate('/login');
  }

  async function onPasswordUpdateError(err) {
    log.debug('ResetPasswordPageController#onLoginFailure', err);
    setError('password', { type: '', message: err.message });
    setState(prev => ({ ...prev, submitting: false }));
  }

  const errorFor =  getError(errors);

  return (
    <PageContent variant='backgroundPage'>
      <Helmet>
        <title>Password Reset</title>
      </Helmet>
      <Typography className={classes.header} variant='h1' component='h1'>
        Reset your password.
      </Typography>
      <Typography className={classes.text}>
        Please enter your new password.
      </Typography>
      <form className='' onSubmit={handleSubmit(onLogin)}>
        <TextField
          className={classes.textField}
          label='New Password'
          type='password'
          name='password'
          variant='outlined'
          error={Boolean(errorFor('password'))}
          helperText={errorFor('password')}
          { ...reg('password') }
        />
        <Button fullWidth size='large' variant='contained' type='submit'
          disabled={state.submitting}
        >
          {state.submitting ? 'Submitting...': 'Submit'}
        </Button>
        <Typography className={classes.loginLink}>
          Do you remember your password?&nbsp;
          <Link className='OutboundLink' to='/login'>Login</Link>
        </Typography>
      </form>
    </PageContent>
  );
});

ResetPasswordPage.propTypes = {
  authService: PropTypes.instanceOf(AuthService),
};

export {
  ResetPasswordPage,
};
