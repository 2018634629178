import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  plainSheet: {
    maxWidth: 'var(--max-content-width)',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: theme.palette.paper.light,
  },
  content: {
    backgroundColor: theme.palette.paper.light,
    padding: 'var(--page-padding)',
    display: 'flex',
    maxWidth: 'var(--max-content-width)',
    flexDirection: 'column',
  },
  close: {
    position: 'absolute',
    top: 12,
    right: 12,
    color: theme.palette.text.dark,
    zIndex: 1, // Otherwise, issues on Safari with vanishing close button
  },
  pageSection: {
    display: 'block',
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export const PlainSheet = ({
  open = false,
  content = null,
  actions = null,
  handleClose = () => { }
}) => {
  const classes = useStyles();

  return (
    <Dialog
      fullScreen
      className={classes.plainSheet}
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
    >
      <IconButton className={classes.close} onClick={handleClose}>
        <CloseIcon className={classes.closeIcon} />
      </IconButton>
      <DialogContent className={classes.content}>
          {
            content
          }
      </DialogContent>
      {
        actions && <DialogActions>
          {
            actions
          }
        </DialogActions>
      }
    </Dialog>
  );
};

PlainSheet.propTypes = {
  open: PropTypes.bool,
  content: PropTypes.node,
  actions: PropTypes.node,
  handleClose: PropTypes.func,
};
