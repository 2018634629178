import React from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { compose, groupBy, toPairs, pathOr } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { HeaderPrefix } from 'components/header-prefix';
import { PageContent, PageSection } from 'components/layout';
import { SubHeader } from 'components/sub-header';
import { BottomStickyControls } from 'components/bottom-sticky';
import { loginRequired } from 'modules/auth';
import { usePastCoachings } from 'modules/coachings';
import { useLoaders, useAsyncEffect } from 'utils/state';

const groupByProp = (path, defaultName = null) => compose(
  toPairs,
  groupBy(list => pathOr(defaultName, path, list)),
);
const byGoal = groupByProp(['goal', 'title']);

const decorate = compose(loginRequired, observer);

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: 8,
    cursor: 'pointer',
  },
  level: {
    marginBottom: 32,
  },
  title: {
    marginBottom: 8,
  },
  description: {
    marginBottom: 8,
  },
  date: {
    fontSize: '0.875rem',
    color: theme.palette.text.disabled,
  }
}));

export const CoachingsArchivePage = decorate(() => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [items, setItems] = React.useState(null);
  const { error, loading, fetch } = usePastCoachings();
  const { fullScreenLoader } = useLoaders([items]);

  useAsyncEffect(async () => {
    const coachings = await fetch();
    setItems(coachings);
  }, []);

  const goto = l => () => navigate(`/coaching-journey/completed-coachings/${l._id}`);

  return (
    <PageContent>
      <Helmet>
        <title>Completed Coachings</title>
      </Helmet>
      <Typography variant='h1' component='h1'>
        <HeaderPrefix>Past Activity</HeaderPrefix>
        Completed Coachings
      </Typography>
      <SubHeader>
        Here are all the coachings you have previously completed
      </SubHeader>
      {
        fullScreenLoader
      }
      {
        loading && <div>Loading...</div>
      }
      {
        error && <div>ERROR</div>
      }
      {
        !loading && !error && items && <PageSection bottomSticky>
        {
          byGoal(items).map(([goal, coachings], idx) => {
            return <React.Fragment key={idx}>
              {
                goal && goal !== 'null'
                  ? <Typography variant='h3' component='h3'>
                    {
                      goal
                    }
                    </Typography>
                  : null
              }
              <div className={classes.level}>
              {
                coachings.map(coaching => {
                  const title = pathOr(null, ['focusArea', 'title'], coaching);
                  const description = pathOr(null, ['focusArea', 'description'], coaching);
                  if (!title) {
                    return null;
                  }
                  return <Card key={coaching._id} className={classes.card} onClick={goto(coaching)}>
                    <CardContent>
                      <Typography
                        className={classes.title}
                        variant='h4'
                        component='h4'
                      >
                      {
                        title
                      }
                      </Typography>
                      {
                        description
                          ? <Typography className={classes.description}>
                          {
                            description
                          }
                          </Typography>
                          : null
                      }
                      <Typography className={classes.date}>
                      {
                        moment(coaching.dateFinished).calendar()
                      }
                      </Typography>
                    </CardContent>
                  </Card>;
                })
              }
              </div>
            </React.Fragment>;
          })
        }
          <BottomStickyControls>
            <Button
              size='large'
              variant='contained'
              onClick={() => navigate('/coaching-journey')}
              fullWidth
            >
              Back
            </Button>
          </BottomStickyControls>
        </PageSection>
      }
    </PageContent>
  );
});
