import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { assoc } from 'ramda';

import {
  PendingInvitationCardMenu
} from './pending-invitation-card-menu.component';

const openMenu = assoc('menuOpen');

const useStyles = makeStyles(theme => ({
  root: {
    border: `1px solid ${theme.palette.grey[300]}`,
    color: 'var(--text-color)',
  },
  icon: {
    verticalAlign: 'text-bottom',
    marginRight: theme.spacing(1),
  },
  content: {
    color: theme.palette.grey[500],
  },
}));

export const PendingInvitationCard = ({
  className,
  invitation,
  onResendInvitation = () => { },
  onCopyInvitationLink = () => { },
  onDeleteInvitation = () => { },
  disabled = false,
}) => {
  const classes = useStyles();
  const { email, dateSent } = invitation;
  
  const [ state, setState ] = React.useState({
    openMenu: false,
  });

  const handleOpenMenu = () => setState(openMenu(true));
  const handleCloseMenu = () => setState(openMenu(false));
  const copyInvitationLink = invitation => {
    onCopyInvitationLink(invitation);
    handleCloseMenu();
  };
  const resendInvitationLink = invitation => {
    onResendInvitation(invitation);
    handleCloseMenu();
  };

  return (
    <>
      <Card className={clsx(classes.root, className)}>
        <CardHeader
          action={
            <IconButton onClick={handleOpenMenu}>
              <MoreVertIcon />
            </IconButton>
          }
          avatar={
            <Avatar className={classes.avatar}>
              {email.charAt(0).toUpperCase()}
            </Avatar>
          }
          title={email}
          subheader={<i>Pending Invitation</i>}
        />
        <CardContent className={classes.content}>
          <AccessTimeIcon className={classes.icon} />
          <Typography component='span'>
            {moment(dateSent).calendar()}
          </Typography>
        </CardContent>
      </Card>

      <PendingInvitationCardMenu
        open={state.menuOpen}
        invitation={invitation}
        onClose={handleCloseMenu}
        onResendInvitation={resendInvitationLink}
        onCopyInvitationLink={copyInvitationLink}
        onDeleteInvitation={onDeleteInvitation}
        disabled={disabled}
      />
    </>
  );
};
