import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import log from 'loglevel';
import { makeStyles } from '@material-ui/core/styles';
import { compose, pick, pathOr } from 'ramda';
import { useNavigate, useParams } from 'react-router-dom';

import { PageSection, PageContent } from 'components/layout';
import { SubHeader } from 'components/sub-header';
import { SimpleCard } from 'components/cards';
import { AssessmentAnswersStore } from 'modules/assessments';
import { connectObserver, useStoreQuery } from 'utils/state';
import { loginRequired } from 'modules/auth';
import { createTodoList } from 'utils/todos';
import { useSegment } from 'utils/analytics';

const decorate = compose(loginRequired, connectObserver(
  'assessmentAnswersStore',
  'todoListsStore',
));

const useStyles = makeStyles(theme => ({
  root: {},
  cardContent: {
    '&:last-child': {
      paddingBottom: 16,
    },

    '& > *': {
      marginBottom: 24,
    },
    '& > *:last-child': {
      marginBottom: 0,
    }
  },
  topics: {
    marginTop: 24,
    color: theme.palette.text.light,
  },
}));

export const RecommendationsPage = decorate(({
  assessmentAnswersStore,
  todoListsStore,
}) => {
  let errorComponent;
  const navigate = useNavigate();
  const classes = useStyles();
  const { track } = useSegment();
  const { assessmentId } = useParams();
  const [ error, loading, recommendations ] = useStoreQuery(
    assessmentAnswersStore,
    'getRecommendations',
    { args: [assessmentId] },
  );
  const [ state, setState ] = React.useState({
    recSelected: null,
  });

  const handleClick = fa => () => {
    setState({ ...state, recSelected: fa });
  };
  const browseTopics = () => navigate('/topics');
  const startCoaching = async () => {
    setState(prev => ({ ...prev, submitting: true }));
    const { focusArea, goal, topic } = state.recSelected;
    try {
      const todoListData = {
        ...createTodoList(focusArea),
        topic: pick(['_id', 'name'], topic),
        goal: pick(['_id', 'title'], goal),
        focusArea: pick(['_id', 'title', 'description'], focusArea),
      };

      const newCoaching = await todoListsStore.create(
        todoListsStore.newItem,
        todoListData
      );

      if (newCoaching instanceof Error) {
        log.error('SelfAssessmentPage#startCoaching', newCoaching);
      } else {
        track('TodoList Created', {
          category: 'TodoLists',
          topic: pathOr(null, ['name'], topic),
          topicId: pathOr(null, ['_id'], topic),
          goal: pathOr(null, ['title'], goal),
          goalId: pathOr(null, ['_id'], goal),
          focusArea: pathOr(null, ['title'], focusArea),
          focusAreaId: pathOr(null, ['_id'], focusArea),
          coaching: pathOr(null, ['title'], newCoaching),
          coachingId: pathOr(null, ['_id'], newCoaching),
        });
        navigate(`/coaching/${newCoaching._id}`);
      }
    } catch (error) {
      log.error(error);
      setState(prev => ({ ...prev, submitting: false }));
    }
  };

  if (error && error.response.status === 409) {
    errorComponent = (
      <Card>
        <CardContent className={classes.cardContent}>
          <Typography variant='h2' component='h2'>
            Personality Assessment Required
          </Typography>
          <Typography>
            You need to finish the bestselfy personality assessment before we
            can start showing you recommendations.
          </Typography>
          <Button
            fullWidth
            variant='contained'
            onClick={() => navigate(`/assessments/${assessmentId}`)}
          >
            Take Assessment
          </Button>
        </CardContent>
      </Card>
    );
  }

  return (
    <PageContent>
      <Typography className={classes.title} variant='h1' component='h1'>
        My Recommendations
      </Typography>
      {
        loading && <div>Loading...</div>
      }
      {
        error && !errorComponent && <div>Error...</div>
      }
      {
        errorComponent
      }
      {
        !loading && !error && recommendations && <>
          <SubHeader>
            Your results indicate you should dive into these coaching sessions.
            Pick one to get started (don&apos;t worry, you can choose another
            one later)
          </SubHeader>
          <PageSection>
            {
              recommendations.map(r => (
                <SimpleCard key={r.focusArea._id}
                  onClick={handleClick(r)}
                  title={r.focusArea.title}
                  text={r.focusArea.description}
                  selected={state.recSelected === r}
                />
              ))
            }
            <Button variant='contained' disabled={!state.recSelected}
              onClick={startCoaching} fullWidth size='large'>
              Start Coaching
            </Button>
            <Button className={classes.topics}
              onClick={browseTopics} fullWidth>
              Browse Topics
            </Button>
          </PageSection>
        </>
      }
    </PageContent>
  );
});

RecommendationsPage.propTypes = {
  assessmentAnswersStore: PropTypes.instanceOf(AssessmentAnswersStore),
};
