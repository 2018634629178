import React from 'react';
import MuiTab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

export const Tab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: theme.palette.text.dark,
    flex: '1 1 50px',
    minWidth: 'unset',
    fontFamily: 'Montserrat',
    minHeight: 36,
    fontSize: '1rem',
    fontWeight: 600,
  },
  selected: {
    color: theme.palette.background.main,
  },
  disabled: {
    color: theme.palette.text.disabled,
    opacity: 0.3,
  },
}))((props) => <MuiTab disableRipple {...props} />);
