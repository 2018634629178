import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';
import { compose } from 'ramda';
import { useParams, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { NoteDialog } from 'components/notes';
import { HeaderPrefix } from 'components/header-prefix';
import { PageContent, PageSection } from 'components/layout';
import { PastCoachingCard } from './components/past-coaching.card';
import { SessionContentBottomSheet } from './components/session-content.bottomsheet';
import { BottomStickyControls } from 'components/bottom-sticky';
import { loginRequired } from 'modules/auth';
import { useNotebooks } from 'modules/notebooks';
import { usePastCoachings } from 'modules/coachings';
import { useLoaders, useAsyncEffect } from 'utils/state';

const pageGetter = (notebook) => (sessionId) => {
  return notebook.pages.find(p => p.session._id === sessionId);
};

const decorate = compose(loginRequired, observer);


export const CoachingArchivePage = decorate(() => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [notebook, setNotebook] = React.useState(null);
  const [coaching, setCoaching] = React.useState(null);
  const [sessionSelected, setSessionSelected] = React.useState(null);
  const [notesSelected, setNotesSelected] = React.useState(null);

  const { error, get: getCoaching } = usePastCoachings();
  const { fullScreenLoader, loading } = useLoaders([coaching]);

  const {
    forCoaching: getNotebook,
    save: saveNotebook,
  } = useNotebooks();

  useAsyncEffect(async () => {
    const coaching = await getCoaching(id);
    setCoaching(coaching);
  }, [id]);

  useAsyncEffect(async () => {
    const notebook = await getNotebook(id);
    setNotebook(notebook);
  }, [id]);

  const getPage = pageGetter(notebook);

  const handleNotesClose = () => {
    saveNotebook(notebook);
    setNotesSelected(null);
  };

  return (
    <PageContent>
      <Helmet>
        <title>Completed Coachings</title>
      </Helmet>
      {
        loading && <div>Loading...</div>
      }
      {
        error && <div>ERROR</div>
      }
      {
        fullScreenLoader
      }
      {
        !loading && !error && coaching && notebook && <>
          <Typography variant='h1' component='h1'>
            <HeaderPrefix>
              Completed Coaching
            </HeaderPrefix>
            { coaching.title }
          </Typography>

          <PageSection bottomSticky>
            {
              coaching.sessions.map((session) => (
                <>
                  <PastCoachingCard
                    key={`card-${session._id}`}
                    session={session}
                    notes={getPage(session._id)}
                    onClick={() => setSessionSelected(session._id)}
                    onClickNotes={() => setNotesSelected(session._id)}
                  />

                  <SessionContentBottomSheet
                    key={`sheet-${session._id}`}
                    session={session}
                    open={sessionSelected === session._id}
                    onClose={() => setSessionSelected(null)}
                  />

                  <NoteDialog
                    key={`note-${session._id}`}
                    open={notesSelected === session._id}
                    title={getPage(session._id).title}
                    text={getPage(session._id).content}
                    onClose={handleNotesClose}
                    onChange={(content) => getPage(session._id).content = content}
                  />
                </>
              ))
            }
            <BottomStickyControls>
              <Button
                size='large'
                variant='contained'
                onClick={() => navigate('/coaching-journey/completed-coachings')}
                fullWidth
              >
                Back
              </Button>
            </BottomStickyControls>
          </PageSection>
        </>
      }
    </PageContent>
  );
});
