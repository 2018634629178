import MUILinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

export const LinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[300],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.accent.main,
  },
}))(MUILinearProgress);