import React from 'react';
import Helmet from 'react-helmet';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { compose, sortBy, prop, reverse } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

import { PageContent, PageSection } from 'components/layout';
import { HeaderPrefix } from 'components/header-prefix';
import { SubHeader } from 'components/sub-header';
import { NotebookCard } from './components/notebook-card.component';
import { useAsyncEffect, useLoaders } from 'utils/state';
import { loginRequired  } from 'modules/auth';
import { BottomStickyControls } from 'components/bottom-sticky';
import { useNotebooks } from 'modules/notebooks';

const byUpdatedAt = compose(
  reverse,
  sortBy(prop('createdAt'))
);

const decorate = compose( loginRequired, observer );

const useStyles = makeStyles(theme => ({
  notebooks: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

export const NotesPage = decorate(({
  notebooksStore,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { fetch } = useNotebooks();
  const [items, setItems] = React.useState(null);
  const { fullScreenLoader } = useLoaders([items]);

  useAsyncEffect(async () => {
    const items = await fetch();
    setItems(items);
  }, []);

  const notebooks = React.useMemo(() => items && byUpdatedAt(items), [items]);
  const onClick = React.useCallback((notebook) => {
    navigate(`/coaching-journey/notes/${notebook._id}/pages`);
  });

  return (
    <PageContent>
      <Helmet>
        <title>Notes</title>
      </Helmet>

      {
        fullScreenLoader
      }

      <Typography variant='h1' component='h1'>
        <HeaderPrefix>
          Past Activity
        </HeaderPrefix>
        Notes
      </Typography>
      <SubHeader>
        Here you can find all your notes from your previous coachings.
      </SubHeader>

      <PageSection bottomSticky>
        <div className={classes.notebooks}>
        {
          notebooks && notebooks.map(notebook => (
            <NotebookCard
              key={notebook._id}
              notebook={notebook}
              onClick={onClick}
            />
          ))
        }
        </div>

        <BottomStickyControls>
          <Button
            size='large'
            variant='contained'
            onClick={() => navigate('/coaching-journey')}
            fullWidth
          >
            Back
          </Button>
        </BottomStickyControls>
      </PageSection>
    </PageContent>
  );
});
