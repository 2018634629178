import React from 'react';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { TeamMemberListEntry } from './team-member-list-entry.component';

const useStyles = makeStyles(theme => ({
  root: {
    border: `1px solid ${theme.palette.grey[300]}`,
    color: 'var(--text-color)',
  },
  list: {
    padding: 0,
    marginBottom: 16,

    '&:last-child': {
      marginBottom: 0,
    },
  },
  detailsButton: {
    boxShadow: 'none',
  },
}));

export const TeamCard = ({
  className,
  team,
  ctaLabel,
  onOpenMenu,
  onClickCta = () => {},
  disabled = false,
}) => {
  const classes = useStyles();
  const { lead, members } = team;

  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader
        action={
          onOpenMenu
            ? <IconButton onClick={onOpenMenu}>
                <MoreVertIcon />
              </IconButton>
            : null
        }
        title={team.name}
        subheader={team.description || <i>No Description</i>}
      />
      <CardContent className={classes.content}>
        <Typography variant='h3' component='h3'>
          Team Lead
        </Typography>
        <List dense className={classes.list}>
          <TeamMemberListEntry member={{ account: lead }} />
        </List>

        <Typography variant='h3' component='h3'>
          Members
        </Typography>

        {
          members.length < 1
            ? <Typography>
                <i>No members</i>
              </Typography>
            : null
        }
        {
          members.length > 0
            ? <List dense className={classes.list}>
                {
                  members.map(member => (
                    <TeamMemberListEntry member={member} key={member._id} />
                  ))
                }
              </List>
            : null
        }
      </CardContent>

      {
        ctaLabel
          ? <CardActions className={classes.actions} disableSpacing>
              <Button
                fullWidth
                className={classes.detailsButton}
                variant='contained' color='primary' size='small'
                onClick={() => onClickCta(team)}
                disabled={disabled}
              >
                {ctaLabel}
              </Button>
            </CardActions>
          : null
      }
    </Card>
  );
};
