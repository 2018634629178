import React from 'react';
import { Typography } from '@material-ui/core';
import { ImageSlide } from './image.slide';

export const Employee3Slide = () => (
  <ImageSlide
    src='https://storage.googleapis.com/bestselfy-static/onboarding/manager-onboarding-3.svg'
    alt='automated coaching'
    title='automated coaching'
  >
    <Typography variant='h1' component='h2'>
      Develop human skills that matter most
    </Typography>

    <Typography>
      Support your employees and help them becoming more happy, engaged, and
      successful in the flow of work.
    </Typography>
  </ImageSlide>
);
