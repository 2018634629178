import React from 'react';
import log from 'loglevel';
import { observer, inject } from 'mobx-react';
import { compose, curry, pathOr, map, reduce, concat, not, all, identity } from 'ramda';

import { FullscreenLoader } from 'components/loaders';

export const connectObserver = (...stores) => compose(
  inject(...stores),
  observer,
);

export const useStoreQuery = function(store, method, {
  args = null,
  refresher = [],
} = {}) {
  const [ state, setState ] = React.useState({
    error: null,
    loading: true,
  });
  const setItem = item => setState(prev => ({ ...prev, item }));
  const setError = error => setState(prev => ({ ...prev, error }));
  const setLoading = loading => setState(prev => ({ ...prev, loading }));
  const dependencies = args instanceof Array
    ? [ ...args, ...refresher ]
    : [ args, ...refresher ];

  React.useEffect(() => {
    (async () => {
      try {
        let item;
        if ( args instanceof Array ) {
          item = await store[method](...args);
        } else if (args) {
          item = await store[method](args);
        } else {
          item = await store[method]();
        }
        setLoading(false);
        item instanceof Error ? setError(item) : setItem(item);
      } catch (err) {
        // TODO: throw the error again?
        setLoading(false);
        log.error(err);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return [ state.error, state.loading, state.item, setItem, setError ];
};

export function useInputControl(model, fieldName, type = 'string') {
  const onChange = (event) => {
    const { value } = event.target;
    switch(type) {
      case 'number': model[fieldName] = parseInt(value, 10); break;
      case 'float': model[fieldName] = parseFloat(value, 10); break;
      default: model[fieldName] = value; break;
    }
  };

  return {
    value: model[fieldName],
    onChange,
  };
}

export const concatArrayValues = curry((path, list) => compose(
  reduce(concat, []),
  map(pathOr([], path)),
)(list));

const notAll = compose(not, all(identity));
export const useLoaders = (loadables) => {
  const [loading, setLoading] = React.useState(false);
  const [timedout, setTimedout] = React.useState(false);
  const [loaders, setLoaders] = React.useState({
    fullScreenLoader: null,
    cover: null,
  });

  React.useEffect(() => {
    // A short delay should avoid flashing loading indicators.
    const to = setTimeout(() => setLoading(notAll(loadables)), 100);
    return () => clearTimeout(to);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, loadables);

  React.useEffect(() => {
    if (loading) {
      setLoaders(prev => ({
        ...prev,
        fullScreenLoader: loading ? <FullscreenLoader timedout={timedout} /> : null,
      }));
    } else {
      setLoaders({
        fullScreenLoader: null,
      });
    }
  }, [loading, timedout]);

  React.useEffect(() => {
    let to;
    if (loading) { to = setTimeout(() => setTimedout(true), 30000); }
    if(!loading) { clearTimeout(to); }
    return () => clearTimeout(to);
  }, [loading]);

  return { ...loaders, loading };
};

export const makeRefWithCallback = (func, cleanup = () => {}) => () => {
  const ref = React.useRef(null);
  const setRef = React.useCallback(node => {
    cleanup(node);
    func(node);
    ref.current = node;
  }, []);

  return setRef;
};

export const useAutoFocusRef = makeRefWithCallback(node => node ? node.focus() : null);

export const sync = (f, setLoading = () => {}, setError = () => {}) => async (...args) => {
  setLoading(true);

  try {
    const val = await f(...args);
    return val;
  } catch (err) {
    setError(err);
  } finally {
    setLoading(false);
  }
};

export const useAsyncEffect = (effect, deps) => {
  React.useEffect(() => {
    (async () => {
      await effect();
    })();
  }, deps);
};
