import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { inject } from 'mobx-react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { compose, pathOr, sortBy } from 'ramda';

import { PageContent, PageSection } from 'components/layout';
import { HeaderPrefix } from 'components/header-prefix';
import { Trait } from './components/trait.component';
import { AssessmentAnswersStore } from 'modules/assessments';
import { useStoreQuery } from 'utils/state';
import { loginRequired } from 'modules/auth';
import { pickParams, addQuery } from 'utils/http';

const sortByOrder = sortBy(pathOr(0, ['classification', 'order']));
const display = compose(sortByOrder, pathOr([], ['result']));

const decorate = compose(loginRequired, inject(
  'assessmentAnswersStore',
));

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: 32,
  },
  trait: {
    marginBottom: 24,

    '&:last-child': {
      marginBottom: 0,
    }
  },
  redoButton: {
    marginTop: 12,
    color: theme.palette.text.light,
  },
}));

export const AssessmentResultPage = decorate(({
  assessmentAnswersStore,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { answerId } = useParams();
  const [ error, loading, answer ] = useStoreQuery(assessmentAnswersStore, 'get',
  { args: answerId },
  );
  
  const { redirectTo } = pickParams(['redirectTo'], location);
  const goto = url => () => navigate(url);
  const redoUrl = answer
    ? addQuery(
        { redirectTo },
        `/assessments/${answer.assessment.id}?redo=1`
      )
    : null;

  return (
    <PageContent>
      {
        loading && <div>Loading...</div>
      }
      {
        error && <div>Error...</div>
      }
      {
        !loading && !error && answer && <>
          <Typography className={classes.title} variant='h1' component='h1'>
            <HeaderPrefix>{ answer.assessment.title }</HeaderPrefix>
            Your Results
          </Typography>
          <PageSection>
            {
              display(answer).map(a => (
                <Trait className={classes.trait} key={a._id} result={a} />
              ))
            }
            <Button
              variant='contained'
              onClick={goto(redirectTo || `/topics`)}
              fullWidth
            >
              Continue
            </Button>
            <Button
              className={classes.redoButton}
              onClick={goto(redoUrl)}
              fullWidth
            >
              Take Again
            </Button>
          </PageSection>
        </>
      }
    </PageContent>
  );
});

AssessmentResultPage.propTypes = {
  assessmentAnswersStore: PropTypes.instanceOf(AssessmentAnswersStore),
};
