import React from 'react';

import { AssessmentsStore, AssessmentAnswersStore } from 'modules/assessments';
import { TopicsStore } from 'modules/topics';
import { TodoListsStore } from 'modules/todos';
import { ClassificationsStore } from 'modules/classifications';
import { AuthService } from 'modules/auth';
import { HttpService } from 'modules/http';
import { FeedbackStore } from 'modules/feedback';
import { TrackerStore } from 'modules/tracker';
import { MenuStore } from 'modules/menu';
import { AccountStore } from 'modules/account';
import { SettingsStore } from 'modules/settings';
import { InvitationTokensStore } from 'modules/invitation-tokens';
import { GoalsStore } from 'modules/goals';
import { FocusAreasStore } from 'modules/focus-areas';
import { AssessmentConfigurationStore } from 'modules/cms';
import { FeatureFocusStore } from 'modules/feature-focus';
import { NotebooksStore } from 'modules/notebooks';
import { TeamsStore } from 'modules/teams';
import { CompletedCoachingNudgesStore } from 'modules/coaching-nudges';
import {
  CoachingsStore,
  SharedCoachingStore,
  PastCoachingStore,
} from 'modules/coachings';
import {
  ManagerDiscoveryQuestionsStore,
  ManagerDiscoveryAnswersStore,
} from 'modules/manager-discovery';
import {
  SelfDiscoveryQuestionsStore,
  SelfDiscoveryAnswersStore,
} from 'modules/self-discovery';
import {
  SubscriptionStore,
  SubscriptionPlansStore
} from 'modules/subscriptions';

const httpService = new HttpService(process.env.REACT_APP_API_URL);
const authService = new AuthService({ httpService });
const assessmentsStore = new AssessmentsStore({ httpService });
const topicsStore = new TopicsStore({ httpService });
const classificationsStore = new ClassificationsStore({ httpService });
const todoListsStore = new TodoListsStore({ httpService, authService });
const coachingsStore = new CoachingsStore({ httpService, authService });
const pastCoachingsStore = new PastCoachingStore({ httpService, authService });
const notebooksStore = new NotebooksStore({ httpService, authService });
const assessmentAnswersStore = new AssessmentAnswersStore({ httpService });
const feedbackStore = new FeedbackStore({ httpService });
const trackerStore = new TrackerStore({ httpService, authService });
const menuStore = new MenuStore();
const accountStore = new AccountStore({ httpService });
const settingsStore = new SettingsStore({ httpService });
const invitationTokensStore = new InvitationTokensStore({ httpService });
const goalsStore = new GoalsStore({ httpService });
const focusAreasStore = new FocusAreasStore({ httpService });
const assessmentConfigurationStore = new AssessmentConfigurationStore({ httpService });
const featureFocusStore = new FeatureFocusStore();
const subscriptionPlansStore = new SubscriptionPlansStore({ httpService });
const subscriptionStore = new SubscriptionStore({ httpService });
const managerDiscoveryQuestionsStore = new ManagerDiscoveryQuestionsStore({ httpService });
const managerDiscoveryAnswersStore = new ManagerDiscoveryAnswersStore({ httpService, authService });
const selfDiscoveryQuestionsStore = new SelfDiscoveryQuestionsStore({ httpService });
const selfDiscoveryAnswersStore = new SelfDiscoveryAnswersStore({ httpService, authService });
const teamsStore = new TeamsStore({ httpService });
const sharedCoachingStore = new SharedCoachingStore({ httpService });
const completedCoachingNudgesStore = new CompletedCoachingNudgesStore({ httpService });

export const services = {
  authService,
  httpService,
  assessmentsStore,
  topicsStore,
  assessmentAnswersStore,
  todoListsStore,
  pastCoachingsStore,
  feedbackStore,
  trackerStore,
  menuStore,
  accountStore,
  settingsStore,
  invitationTokensStore,
  classificationsStore,
  goalsStore,
  focusAreasStore,
  assessmentConfigurationStore,
  featureFocusStore,
  subscriptionPlansStore,
  subscriptionStore,
  coachingsStore,
  notebooksStore,
  managerDiscoveryQuestionsStore,
  managerDiscoveryAnswersStore,
  selfDiscoveryQuestionsStore,
  selfDiscoveryAnswersStore,
  teamsStore,
  sharedCoachingStore,
  completedCoachingNudgesStore,
};

export const StoreContext = React.createContext(services);
