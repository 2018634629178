import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
  Radar, RadarChart, PolarGrid, PolarAngleAxis
} from 'recharts';
import { inject } from 'mobx-react';
import { compose, values } from 'ramda';

import { PageSection } from 'components/layout';
import { BottomStickyControls } from 'components/bottom-sticky';
import { Markdown } from 'components/markdown';
import { ClassificationsStore } from 'modules/classifications';
import { loginRequired } from 'modules/auth';
import { useScreenWidth } from 'utils/dom';

const getHeight = (width) => Math.min(width * 0.7, 350);

const decorate = compose(loginRequired, inject(
  'assessmentAnswersStore',
  'classificationsStore'
));

export const ClassificationSection = decorate(({
  classification,
  assessmentAnswer,
}) => {
  let maxScore = 0;
  const width = useScreenWidth();

  if (assessmentAnswer) {
    maxScore = Math.max(...values(assessmentAnswer.scores));
  }

  return (
    <>
      <Typography variant='h1' component='h1'>Assessment Result</Typography>
      <PageSection variant='card' bottomSticky>
        <h2>{ classification.name }</h2>
        {
          assessmentAnswer && <RadarChart
            outerRadius='70%'
            width={width}
            height={getHeight(width)}
            data={assessmentAnswer.result}
          >
            <PolarGrid />
            <PolarAngleAxis dataKey='classification.name' domain={[0, maxScore]} />
            <Radar dataKey='score' stroke='#825dde' fill='#00a89d' fillOpacity={0.6} />
          </RadarChart>
        }
        <Markdown source={classification.description} />
        <BottomStickyControls>
          <Button fullWidth variant='contained'>
            Next
          </Button>
        </BottomStickyControls>
      </PageSection>
    </>
  );
});

ClassificationSection.propTypes = {
  classificationsStore: PropTypes.instanceOf(ClassificationsStore),
  assessmentAnswer: PropTypes.shape({}),
};
