import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';

import { ColorHeaderCard } from 'components/cards';

const format = (str, ...props) => str.split('\n').map((line, i) => (
  <Typography key={i}>{line}</Typography>
));

const displaySelection = (selection, ...props) => selection.map((s, i) => (
  <Typography {...props} key={s}>{i + 1}. {s}</Typography>
));

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
  },
  header: {
    fontSize: '0.8rem',
    textAlign: 'right',
  },
  withAnswer: {
    textAlign: 'left',
    backgroundColor: theme.palette.success.main,
  },
  content: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  text: {
    flexGrow: 1,
    maxHeight: 124,
  },
  selection: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  icon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: -6,
  },
  p: {
    margin: 0,
  },
}));

export const QuestionCard = ({
  question,
  answer,
  onClick = () => {},
}) => {
  const classes = useStyles();
  const hasAnswer = !!answer?.answer;
  const hasSelection = answer?.selection?.length;
  const done = hasAnswer || hasSelection;

  return (
    <ColorHeaderCard
      className={classes.root}
      headerTitle={done ? question.text : 'Start reflection'}
      headerPosition={done ? 'top' : 'bottom'}
      headerClassName={clsx(classes.header, {
        [classes.withAnswer]: done,
      })}
      onClick={onClick}
    >
      <div className={classes.content}>
        {
          hasSelection
            ? null
            : <Typography
                className={classes.text}
                component={done ? 'div' : 'h4'}
                variant={done ? 'body1' : 'h4'}
              >
                {
                  hasAnswer
                    ? format(answer.answer, { className: classes.p })
                    : question.text
                }
              </Typography>
        }
        {
          hasSelection
            ? <div className={classes.selection}>
                {displaySelection(answer.selection, { className: classes.p })}
              </div>
            : null
        }
        <div className={classes.icon}>
          <ArrowForwardIcon />
        </div>
      </div>
    </ColorHeaderCard>
  );
};

QuestionCard.propTypes = {
  question: PropTypes.shape({
    text: PropTypes.string,
  }).isRequired,
  answer: PropTypes.shape({
    answer: PropTypes.string,
  }),
  onClick: PropTypes.func,
};
