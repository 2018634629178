import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import MuiFab from '@material-ui/core/Fab';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import SwipeableViews from 'react-swipeable-views';
import clsx from 'clsx';
import { includes } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { indexOf } from 'ramda';

import { Tabs } from './components/coaching-tabs.component';
import { Tab } from './components/coaching-tab.component';
import { SessionCompletionDialog } from './components/session-completion-dialog.component';
import { Markdown } from 'components/markdown';
import { BottomStickyControls } from 'components/bottom-sticky';
import { CoachingSessionActionsDialog } from './components/coaching-session-actions.dialog';
import { useSegment } from 'utils/analytics';

const TAB_IDS = ['session', 'notes', 'more'];

const Fab = (props) => <MuiFab disableRipple { ...props } />;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    maxWidth: 'var(--max-content-width)',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  swipeContainer: {
    overflow: 'auto',
    height: '100%',
    boxSizing: 'border-box',
    padding: '0 var(--page-padding) var(--page-padding) var(--page-padding)',
  },
  fabContainer: {
    paddingBottom: 165,
  },
  title: {
    marginBottom: 24,
  },
  markdown: {
  },
  notes: {
    minHeight: '100%',
    width: '100%',
    outline: 'none',
    border: 'unset',
    backgroundColor: 'unset',
    color: theme.palette.text.dark,
    font: '400 1em Poppins',
    resize: 'none',
    padding: 0,
    margin: 0,
    boxSizing: 'border-box',
    appearance: 'none',
    '&:focus': {
      outline: 'none',
    }
  },
  fab: {
    position: 'absolute',
    right: 18,
    bottom: 96,
    backgroundColor: theme.palette.accent.main,
    color: theme.palette.text.light,
    '&:hover': {
      backgroundColor: theme.palette.accent.main,
      color: theme.palette.text.light,
    },
  },
  backButton: {
    backgroundImage: 'unset',
    backgroundColor: theme.palette.success.main,
    marginRight: 12,
  },
}));

export const CoachingSessionPage = ({
  open = false,
  actionsDisabled = false,
  coaching = {},
  session = {},
  notebookPage = {},
  onSetReminder = () => {},
  onSkipSession = () => {},
  onUnskipSession = () => {},
  onBack = () => {},
  onCompleteSession = () => {},
  onNotesChange = () => {},
  onClose = () => {},
}) => {
  const classes = useStyles();
  const notesRef = React.useRef();
  const { track } = useSegment();
  const [state, setState] = React.useState({
    tabId: 'session',
    notes: '',
    actionsDialogOpen: false,
    completionDialogOpen: false,
  });

  const toTab = tabId => {
    track('Coaching Session Tab Changed', {
      category: 'Coachings',
      coaching: coaching.title,
      session: session.title,
      tab: tabId,
    });
    setState(prev => ({ ...prev, tabId }));
  };
  const saveNotebookPage = () => {
    if (notebookPage.content !== state.notes) {
      notebookPage.content = state.notes;
      onNotesChange(notebookPage);
    }
  };
  const handleTabChange = (event, tabId) => {
    if (state.tabId === 'notes' && tabId !== 'notes') {
      saveNotebookPage();
    }
    toTab(tabId);
  };
  const handleIndexChange = idx => {
    toTab(TAB_IDS[idx]);
  };
  const getIndex = () => {
    return Math.max(indexOf(state.tabId, TAB_IDS), 0);
  };
  const onUpdateNotes = event => {
    setState({ ...state, notes: event.target.value });
  };
  const openActionsDialog = () => {
    setState(prev => ({ ...prev, actionsDialogOpen: true }));
  };
  const closeActionsDialog = () => {
    setState(prev => ({ ...prev, actionsDialogOpen: false  }));
  };
  const openCompletionDialog = () => {
    setState(prev => ({ ...prev, completionDialogOpen: true }));
  };
  const closeCompletionDialog = () => {
    setState(prev => ({ ...prev, completionDialogOpen: false }));
  };
  const action = f => (...args) => {
    closeActionsDialog();
    setTimeout(() => f(...args));
  };
  const handleExit = () => {
    saveNotebookPage();
  };
  const completeSession = rating => {
    session.rating = rating;
    onCompleteSession(session);
  };
  const skipSession = () => onSkipSession(session);
  const unskipSession = () => onUnskipSession(session);
  const setReminder = dateTime => onSetReminder(dateTime, session);

  React.useEffect(() => {
    if (!includes(state.tabId, TAB_IDS) && session) {
      toTab('session');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tabId, session]);

  React.useEffect(() => {
    if (!notebookPage) { return; }

    setState({ notes: notebookPage.content || '' });
  }, [notebookPage]);

  const swipableProps = {
    index: getIndex(),
    onChangeIndex: handleIndexChange,
    enableMouseEvents: true,
    style: {
      flexGrow: '1',
      display: 'flex',
      width: '100%',
    },
    containerStyle: {
      flexGrow: 1,
      width: '100%',
      position: 'relative',
    },
  };

  if (!session) {
    return null;
  }

  return <>
    <Dialog
      open={open}
      className={classes.root}
      onClose={onClose}
      TransitionComponent={Transition}
      TransitionProps={{
        onExit: handleExit,
      }}
      fullScreen
    >
      {
        <>
          <div className={classes.tabBar}>
            <Tabs className={classes.tabs} value={state.tabId || TAB_IDS[0]} onChange={handleTabChange}>
              <Tab value='session' label='Session' />
              <Tab value='notes' label='Notes' />
              <Tab value='more' label='More' disabled={!session.detailedDescription} />
            </Tabs>
          </div>

          <SwipeableViews {...swipableProps}>
            <div className={clsx(classes.swipeContainer, classes.fabContainer)}>
              <Typography variant='h2' component='h2' className={classes.title}>
                {
                  session.title
                }
              </Typography>
              <Markdown className={classes.markdown}>
                {
                  session.description
                }
              </Markdown>
              <BottomStickyControls>
                <Button size='large' variant='contained' className={classes.backButton} onClick={onBack}>
                  <KeyboardBackspaceIcon />
                </Button>
                <Button size='large' variant='contained' onClick={openCompletionDialog} disabled={actionsDisabled} fullWidth>
                  Complete
                </Button>
              </BottomStickyControls>
              <Fab className={classes.fab} onClick={openActionsDialog}>
                <MoreIcon />
              </Fab>
            </div>
            <div className={classes.swipeContainer}>
              <textarea
                className={classes.notes}
                ref={notesRef}
                value={state.notes || session.prepopulateNotes}
                onChange={onUpdateNotes}
                placeholder='Add your session notes here...'
              />
            </div>
            <div className={classes.swipeContainer}>
              <Typography variant='h2' component='h2' className={classes.title}>
                {
                  session.title
                }
              </Typography>
              <Markdown>
                {
                  session.detailedDescription || '*No detailed description available...*'
                }
              </Markdown>
            </div>
          </SwipeableViews>
          <CoachingSessionActionsDialog
            open={state.actionsDialogOpen}
            onClose={closeActionsDialog}
            onSetReminder={action(setReminder)}
            onSkipSession={!session.dateSkipped && action(skipSession)}
            onUnskipSession={session.dateSkipped && action(unskipSession)}
            actionsDisabled={actionsDisabled}
          />
          <SessionCompletionDialog
            open={state.completionDialogOpen}
            onClose={closeCompletionDialog}
            onContinue={completeSession}
            actionsDisabled={actionsDisabled}
          />
        </>
      }
    </Dialog>
  </>;
};

CoachingSessionPage.propTypes = {
  open: PropTypes.bool,
  coaching: PropTypes.object,
  session: PropTypes.object,
  notebookPage: PropTypes.object,
  onSetReminder: PropTypes.func,
  onSkipSession: PropTypes.func,
  onUnskipSession: PropTypes.func,
  onBack: PropTypes.func,
  onCompleteSession: PropTypes.func,
  onNotesChange: PropTypes.func,
  onClose: PropTypes.func,
  actionsDisabled: PropTypes.bool,
};
