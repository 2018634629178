import { CollectionStore } from 'modules/base';

export class GoalsStore extends CollectionStore {
  baseUrl = '/goals';
  recommendations = {};

  async getRecommendations(goal) {
    if (!this.recommendations[goal._id]) {
      const response = await this.httpService.get(
        `${this.url}/${goal._id}/recommendations`,
      );
      this.recommendations[goal._id] = response.body;
    }

    return this.recommendations[goal._id];
  }

  resetRecommendationCache() {
    this.recommendations = {};
  }
}
