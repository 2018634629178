import React from 'react';
import PropTypes from 'prop-types';
import HomeIcon from '@material-ui/icons/Home';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SettingsIcon from '@material-ui/icons/Settings';
import StarIcon from '@material-ui/icons/Star';
import SaveIcon from '@material-ui/icons/Save';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import MailIcon from '@material-ui/icons/Mail';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { map, compose, filter, propEq } from 'ramda';

import { TrackerPage } from 'app/pages/tracker';
import { AssessmentResultPage } from 'app/pages/classifications';
import { AnswerAssessmentPage } from 'app/pages/assessments';
import { AppPage } from 'components/layout';
import { TopicsControllerPage } from 'app/pages/topics';
import { AccountPage } from 'app/pages/account';
import { DashboardPage } from 'app/pages/dashboard';
import { ProfilePage } from 'app/pages/profile';
import {
  TeamsPage, TeamPage, JoinTeamPage, TeamMemberPage,
 } from 'app/pages/teams';
import { ManagerDiscoveryPage } from 'app/pages/manager-discovery';
import { SelfDiscoveryPage } from 'app/pages/self-discovery';
import {
  CoachingPage,
  CoachingCompletionPage,
} from 'app/pages/coachings';
import {
  CoachingsArchivePage,
  CoachingArchivePage,
} from 'app/pages/archive';
import { RecommendationsPage } from 'app/pages/recommendations';
import {
  SubscriptionPlansPage,
  SubscriptionSuccessPage,
} from 'app/pages/subscriptions';
import { PastActivitiesPage } from 'app/pages/past-activities';
import { NotesPage, NotebookPage } from 'app/pages/notes';
import { ArchivedNudgesPage } from 'app/pages/coaching-nudges';
import { AppHeaderComponent as AppHeader } from 'components/app-header';
import { BurgerMenu } from 'components/burger-menu';
import { PageTracker } from 'components/analytics';
import { AuthService } from 'modules/auth';
import { HttpService } from 'modules/http';
import { AssessmentConfigurationStore } from 'modules/cms';
import { SubscriptionStore } from 'modules/subscriptions';
import { connectObserver, useStoreQuery } from 'utils/state';
import { loginRequired } from 'modules/auth';
import { featuredIn } from 'utils/cms';

const featuredInMenu = featuredIn('menu');

const MenuLink = withStyles(theme => ({
  disabled: {
    color: theme.palette.text.disabled,
    cursor: 'not-allowed',
  },
}))(({ to, children, disabled, classes }) => (
  disabled
    ? <Typography className={classes.disabled}>{children}</Typography>
    : <Link to={to}>{children}</Link>
));
MenuLink.propTypes = {
  to: PropTypes.string,
  disabled: PropTypes.bool,
};

const decorate = compose(loginRequired, connectObserver(
  'httpService',
  'assessmentConfigurationStore',
  'subscriptionStore',
));

export const App = decorate(({
  httpService,
  assessmentConfigurationStore,
  subscriptionStore,
}) => {
  const navigate = useNavigate();
  useStoreQuery(assessmentConfigurationStore, 'fetch');
  useStoreQuery(subscriptionStore, 'get');

  const assessmentConfigs = (assessmentConfigurationStore.items$ || []);
  
  const createMenuEntry = (url, icon) => config => {
    const f = featuredInMenu(config);
    return {
      icon,
      item: (
        <Link to={`${url}/${config.assessment}`}>
          { f.name }
        </Link>
      ),
    };
  };
  const createMenuEntries = (configs, url, type, icon) => compose(
    map(createMenuEntry(url, icon)),
    filter(featuredInMenu),
    filter(propEq('type', type)),
  )(configs);

  React.useEffect(() => {
    const handler401 = () => navigate('/logout');
    const handler402 = () => navigate('/subscription/plans');
    httpService.on(401, handler401);
    httpService.on(402, handler402);
    return () => {
      httpService.off(401, handler401);
      httpService.off(402, handler402);
    };
  }, [navigate, httpService]);

  return (
    <AppPage>
      <AppHeader />
      <PageTracker />
      <BurgerMenu  items={[
        { icon: <HomeIcon fill='#fff' />, item: <MenuLink to='/dashboard'>Dashboard</MenuLink> },
        { icon: <PersonIcon fill='#fff' />, item: <MenuLink to='/profile' >Profile</MenuLink> },
        { icon: <GroupIcon fill='#fff' />, item: <MenuLink to='/teams' >Teams</MenuLink> },
        { icon: <StarIcon fill='#fff' />, item: <MenuLink to='/topics'>New Coaching</MenuLink> },
        { icon: <SaveIcon fill='#fff' />, item: <MenuLink to='/coaching-journey'>Past Activity</MenuLink> },
        ...createMenuEntries(assessmentConfigs, '/assessments', 'assessment', <AssessmentIcon fill='#fff' />),
        { icon: <SettingsIcon fill='#fff' />, item: <MenuLink to='/account'>Account</MenuLink> },
        { icon: <PowerSettingsNewIcon fill='#fff' />, item: <MenuLink to='/logout'>Sign Out</MenuLink> },
        { icon: <MailIcon fill='#fff' />, item: <a href='mailto:support@bestselfy.com'>Get In Touch</a>},
      ]} />

      <Routes>
        <Route path='/' element={<Navigate to='/dashboard' replace={true} />} />

        <Route path='dashboard/:goalId' element={<DashboardPage />} />
        <Route path='dashboard' element={<DashboardPage />} />

        <Route path='profile' element={<ProfilePage />} />

        <Route path='topics' element={<TopicsControllerPage />} />
        <Route path='topics/goal/:goalId' element={<TopicsControllerPage />} />
        <Route path='topics/goal/:goalId/focus-area/:faId' element={<TopicsControllerPage />} />
        <Route path='topics/:topicId' element={<TopicsControllerPage />} />
        <Route path='topics/:topicId/goal/:goalId' element={<TopicsControllerPage />} />

        <Route path='coachings/:id/completed' element={<CoachingCompletionPage />} />
        <Route path='coachings/:id/:tabId?/:sessionId?' element={<CoachingPage />} />

        <Route path='/coaching-journey' element={<PastActivitiesPage />}/>
        <Route path='/coaching-journey/notes' element={<NotesPage />}/>
        <Route path='/coaching-journey/notes/:id/pages' element={<NotebookPage />}/>
        <Route path='/coaching-journey/mood-tracker' element={<TrackerPage />}/>
        <Route path='/coaching-journey/completed-coachings' element={<CoachingsArchivePage />} />
        <Route path='/coaching-journey/completed-coachings/:id' element={<CoachingArchivePage />} />

        <Route path='teams' element={<TeamsPage />} />
        <Route path='teams/:id' element={<TeamPage />} />
        <Route path='teams/:teamId/members/:memberId' element={<TeamMemberPage />} />
        <Route path='teams/:teamId/members/:memberId/archived-nudges' element={<ArchivedNudgesPage />} />
        <Route path='teams/join/:id' element={<JoinTeamPage />} />

        <Route path='manager-discovery' element={<ManagerDiscoveryPage />} />
        <Route path='self-discovery' element={<SelfDiscoveryPage />} />
        <Route path='assessments/:id' element={<AnswerAssessmentPage />} />
        <Route path='assessments/result/:answerId' element={<AssessmentResultPage />} />
        <Route path='recommendations/:assessmentId' element={<RecommendationsPage />} />

        <Route path='account/:tabId?' element={<AccountPage />} />

        <Route path='subscription/plans' element={<SubscriptionPlansPage />} />

        <Route path='subscription/success' element={<SubscriptionSuccessPage />} />

        <Route element={<Navigate to='/404' replace={true} />} />
      </Routes>
    </AppPage>
  );
});

App.propTypes = {
  authService: PropTypes.instanceOf(AuthService),
  httpService: PropTypes.instanceOf(HttpService),
  assessmentConfigurationStore: PropTypes.instanceOf(AssessmentConfigurationStore),
  subscriptionStore: PropTypes.instanceOf(SubscriptionStore),
};
