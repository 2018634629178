import { SingletonStore } from 'modules/base';
export class SettingsStore extends SingletonStore {
  baseUrl = '/settings';

  get newItem() {
    return {};
  }

  import(data) {
    if (data === null) {
      return super.import(this.newItem);
    } else {
      delete data.user;
      return super.import(data);
    }
  }
}
