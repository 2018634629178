import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MuiMobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import * as Sentry from '@sentry/react';
import queryString from 'query-string';
import SwipeableViews from 'react-swipeable-views';
import Pluralize from 'react-pluralize';
import clsx from 'clsx';
import { useLocation, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { compose, sortBy, prop, reverse, pathOr } from 'ramda';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { PageContent, PageSection } from 'components/layout';
import { SubscriptionPlanCard } from 'components/subscription-plan-card';
import {
  SubscriptionStore,
  SubscriptionPlansStore
} from 'modules/subscriptions';
import { loginRequired } from 'modules/auth';
import { connectObserver, useStoreQuery, useLoaders } from 'utils/state';
import { HostedPage } from 'components/chargebee';
import { useSegment } from 'utils/analytics';
import { SLIDES } from './slides';
import moment from 'moment';

const CONVERSION = {
  day: 1,
  week: 7,
  month: 30,
  year: 365,
};

const Alert = props => <MuiAlert elevation={6} variant='filled' {...props} />;
const MobileStepper = withStyles(theme => ({
  root: {
    background: 'unset',
  },
  dotActive: {
    backgroundColor: theme.palette.paper.light,
  },
}))(MuiMobileStepper);
const Disclaimer = withStyles(theme => ({
  root: {
    fontSize: '0.875rem',
    color: theme.palette.text.disabled,
  },
}))(Typography);
const byPrice = compose(reverse, sortBy(prop('price')));

const decorate = compose(loginRequired, connectObserver(
  'subscriptionStore',
  'subscriptionPlansStore',
));

const useStyles = makeStyles(theme => ({
  cta: {
    marginBottom: 16,
    '&:last-child': {
      marginBottom: 0,
    }
  },
  carrousel: {
    margin: 'calc(-1 * var(--page-padding))',
    position: 'relative',
  },
  carrouselItem: {
    width: '100%',
    position: 'relative',
  },
  carrouselItemBackground: {
    width: '100%',
    height: 'auto',
    minHeight: 320,
  },
  carrouselItemInner: {
    position: 'absolute',
    padding: 'var(--page-padding)',
    paddingBottom: 32,
    left: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    color: theme.palette.text.light,
  },
  carrouselItemTitle: {
    marginBottom: 12,
  },
  headline: {
    margin: '48px 0 24px 0',
  },
  disclaimer: {
    marginTop: 24,
  },
  link: {
    textDecoration: 'underline',
  },
  navButton: {
    position: 'absolute',
    top: '50%',
    width: 48,
    height: 48,
    transform: 'translateY(-50%)',
    padding: 'unset',
    minWidth: 'unset',
    color: theme.palette.text.light,
    borderRadius: 32,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  buttonLeft: {
    left: 8,
  },
  buttonRight: {
    right: 8,
  },
  stepper: {
    position: 'absolute',
    bottom: 4,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  disclaimerList: {
    marginTop: 4,
    marginBottom: 4,
    color: theme.palette.text.disabled,
  },
}));

export const SubscriptionPlansPage = decorate(({
  subscriptionStore,
  subscriptionPlansStore,
}) => {
  const location = useLocation();
  const classes = useStyles();
  const { track } = useSegment();
  const [pError, pLoading] = useStoreQuery(subscriptionPlansStore, 'fetch');
  const [sError, sLoading] = useStoreQuery(subscriptionStore, 'reload');
  const [state, setState] = React.useState({
    chargebeeError: null,
    hp: null,
    index: 0,
    interval: null,
  });

  const hideError = () => setState(prev => ({ ...prev, chargebeeError: null }));
  const cancelCheckout = () => setState(prev => ({ ...prev, hp: null }));
  const getHostedPage = async plan => {
    try {
      const { redirectTo } = queryString.parse(location.search);
      // const hp = await subscriptionPlansStore.subscribe(plan, { redirectTo });
      // setState(prev => ({ ...prev, hp }));
      window.Chargebee.registerAgain();
      const cb = window.Chargebee.getInstance();
      cb.openCheckout({
        hostedPage: () => subscriptionPlansStore.subscribe(plan, { redirectTo }),
        close: () => track('Subscription Dialog Step', {
          category: 'Subscriptions',
          plan: plan.name,
          step: 'closed',
        }),
        step: (step) => track('Subscription Dialog Step', {
          category: 'Subscriptions',
          plan: plan.name,
          step,
        }),
        loaded: () => track('Subscription Dialog Step', {
          category: 'Subscriptions',
          plan: plan.name,
          step: 'opened',
        }),
      });
    } catch (err) {
      setState(prev => ({ ...prev, chargebeeError: err }));
      Sentry.captureException(err);
    }
  };

  const plans = subscriptionPlansStore.items$;
  const sub = subscriptionStore.item$;
  const { fullScreenLoader } = useLoaders([!pLoading, !sLoading]);

  const loading = pLoading || sLoading;
  const error = pError || sError;

  const switchSlide = index => setState(prev => ({ ...prev, index }));
  const showNext = () => {
    const newIdx = state.index + 1;
    const index = newIdx < SLIDES.length ? newIdx : 0;
    switchSlide(index);
  };
  const showPrev = () => {
    const newIdx = state.index - 1;
    const index = newIdx >= 0 ? newIdx : SLIDES.length - 1;
    switchSlide(index);
  };
  const getLabel = (idx, plan, sub) => {
    if (sub) { return; }
    if (plan.applicableCoupon) {
      try {
        const coupon = plan.applicableCoupon;
        const prefix = coupon.discount_amount ? '$' : '';
        const suffix = coupon.discount_percentage ? '%' : '';
        let duration = 'forever';

        if (coupon.period && coupon.period_unit) {
          const planPeriod = CONVERSION[plan.period_unit] * plan.period;
          const couponPeriod = CONVERSION[coupon.period_unit] * coupon.period;
          const factor = Math.ceil(couponPeriod / planPeriod);
          duration = <>
            for{' '}
            <Pluralize
              singular={plan.period_unit}
              count={factor * plan.period}
            />
          </>;
        }

        const amount = (
          coupon.discount_percentage ||
          Math.round(coupon.discount_amount / 100)
        );

        return <>{prefix}{amount}{suffix} off {duration}</>;
      } catch (err) {
        Sentry.captureException(err);
      }
    }
    if (idx === 0) { return 'Most Popular'; }
  };
  const getLabelColor = (idx, plan, sub) => {
    if (plan.applicableCoupon || Boolean(sub)) { return 'success'; }
    return 'accent';
  };
  const getExpirationDate = p => {
    const _path = ['applicableCoupon', 'valid_till'];
    const valid_till = pathOr(false, _path, p);
    if (valid_till) {
      return moment(valid_till * 1000).calendar();
    }
  };

  React.useEffect(() => {
    const to = setTimeout(showNext, 6000);
    return () => clearTimeout(to);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.index]);

  return <>
    <PageContent variant='no-background'>
      {
        fullScreenLoader
      }
      {
        error && <div>ERROR</div>
      }
      {
        !loading && !error && plans && <>
          <Helmet>
            <title>Subscription Plans</title>
          </Helmet>

          <div className={classes.carrousel}>
            <SwipeableViews index={state.index} disabled>
            {
              SLIDES.map((content, idx) => (
                <div key={idx} className={classes.carrouselItem} style={{
                  backgroundColor: content.backgroundColor }}
                >
                  <img 
                    className={classes.carrouselItemBackground}
                    src={content.background}
                    alt={content.alt}
                  />
                  <div className={classes.carrouselItemInner}>
                    <Typography
                      className={classes.carrouselItemTitle}
                      variant='h3'
                    >
                      {
                        content.title
                      }
                    </Typography>
                    <Typography>
                      {
                        content.text
                      }
                    </Typography>
                  </div>
                </div>
              ))
            }
            </SwipeableViews>
            <MobileStepper
              variant='dots'
              steps={SLIDES.length}
              position='static'
              activeStep={state.index}
              className={classes.stepper}
            />
            <Button className={clsx(classes.navButton, classes.buttonLeft)} onClick={showPrev}>
              <KeyboardArrowLeftIcon />
            </Button>
            <Button className={clsx(classes.navButton, classes.buttonRight)} onClick={showNext}>
              <KeyboardArrowRightIcon />
            </Button>
          </div>

          <Typography variant='h2' component='h2' className={classes.headline}>
            Choose a plan
          </Typography>

          <PageSection>
            <Snackbar open={Boolean(state.chargebeeError)} autoHideDuration={6000} onClose={hideError}>
              <Alert onClose={hideError} severity='error'>
                Something went wrong while processing your subscription. We are
                aware of the incident and will address it as soon as possible.
              </Alert>
            </Snackbar>
            {
              byPrice(plans).map((p, idx) => (
                <SubscriptionPlanCard key={p.id}
                  className={classes.cards}
                  plan={p}
                  onClick={getHostedPage}
                  subscribed={sub && sub.planId === p.id}
                  label={getLabel(idx, p, sub)}
                  labelColor={getLabelColor(idx, p, sub)}
                  disabled={Boolean(sub)}
                  couponExpiration={getExpirationDate(p)}
                />
              ))
            }
            <Disclaimer className={classes.disclaimer}>
              You won’t be charged until after your 7-day free trial.
              Subscriptions can be cancelled at any time in your{' '}
              <Link className={classes.link} to='/account'>
                account section
              </Link>.
            </Disclaimer>
            <div className={classes.disclaimer}>
            {
              byPrice(plans).map((p, idx) => (
                <Disclaimer key={idx} component='div'>
                  ${p.price / 100} billed every {p.period} {p.period_unit}s after free trial.
                </Disclaimer>
              ))
            }
            </div>
          </PageSection>
        </>
      }
      {
        state.hp
          ? <HostedPage page={state.hp} cancelCheckout={cancelCheckout} />
          : null
      }
    </PageContent>
  </>;
});

SubscriptionPlansPage.propTypes = {
  subscriptionStore: PropTypes.instanceOf(SubscriptionStore),
  subscriptionPlansStore: PropTypes.instanceOf(SubscriptionPlansStore),
};
