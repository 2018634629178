import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SwipeableViews from 'react-swipeable-views';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import * as Sentry from '@sentry/react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { pathOr, forEach, isEmpty } from 'ramda';

import { PageSection } from 'components/layout';
import { CoachingPlan } from 'components/coaching-plan';
import { addQuery } from 'utils/http';
import { useLoaders } from 'utils/state';

const getSessions = pathOr([], ['sessions']);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const FocusAreaCard = withStyles({
  root: {
    cursor: 'pointer',
    marginBottom: 16,
    '&:last-child': {
      marginBottom: 0,
    }
  },
  title: {
    marginBottom: 12,
  },
})(({
  classes,
  focusArea,
  onClick = () => { },
}) => (
  <Card
    className={classes.root}
    elevation={6}
    onClick={() => onClick(focusArea)}
  >
    <CardContent>
      <Typography variant='h4' component='h4' className={classes.title}>
        {
          focusArea.title
        }
      </Typography>
      <Typography>{focusArea.description}</Typography>
    </CardContent>
  </Card>
));
FocusAreaCard.propTypes = {
  classes: PropTypes.any,
  focusArea: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
};

const RecommendationPlaceholder = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.disabled.darker,
  },
  text: {
    color: theme.palette.text.light,
    marginBottom: 12,
  },
  button: {
    backgroundImage: 'linear-gradient(271.66deg, #FBDC9B, #F8BA39)',
    color: theme.palette.text.light,
  },
}))(({
  classes,
  assessmentId,
  assessmentTitle,
  goal,
}) => {
  const navigate = useNavigate();
  const gotoAssessment = () => {
    const assessmentUrl = addQuery(
      { redirectTo: `/topics/goal/${goal._id}` },
      `/assessments/${assessmentId}`
    );
    navigate(assessmentUrl);
  };

  return (
    <Card className={classes.root} elevation={6}>
      <CardContent>
        <Typography className={classes.text}>
          Unlock your recommended coaching by completing the <b>
            {
              assessmentTitle
            }
          </b>
        </Typography>
        <Button
          size='large'
          fullWidth
          variant='contained'
          className={classes.button}
          endIcon={<LockOpenIcon />}
          onClick={gotoAssessment}
        >
          Start Assessment
        </Button>
      </CardContent>
    </Card>
  );
});

export const GoalSheet = withStyles(theme => ({
  bottomSheet: {
    display: 'flex',
    maxWidth: 'var(--max-content-width)',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  content: {
    padding: 0,
    '&:first-child': {
      paddingTop: 0,
    }
  },
  inner: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    padding: 'var(--page-padding)',
    boxSizing: 'border-box',
  },
  illustrationContainer: {
    height: 180,
    backgroundColor: '#507EDB',
    position: 'relative',
    padding: 12,
    flexShrink: 0,
    overflow: 'hidden',
  },
  title: {
    color: theme.palette.text.light,
    marginRight: 65,
    isolation: 'isolate',
  },
  illustration: {
    position: 'absolute',
    bottom: -30,
    left: '50%',
    width: 'auto',
    height: '133%',
    transform: 'translateX(-50%)',
  },
  close: {
    position: 'absolute',
    top: 4,
    right: 4,
    color: theme.palette.text.light,
  },
  description: {
    color: theme.palette.text.dark,
    marginBottom: 24,
  },
  coachingHeading: {
    marginTop: 32,
    marginBottom: 20,

    '&:first-child': {
      marginTop: 0,
    }
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  back: {
    flexGrow: 0,
    marginRight: 12,
    color: theme.palette.text.light,
    backgroundColor: theme.palette.success.main,
    backgroundImage: 'none',
  },
  start: {
    flexGrow: 1,
  },
  swipeContent: {
    padding: 10,
  },
  pleaseSubscribe: {
    width: '100%',
    textAlign: 'center',
    color: theme.palette.warn.main,
    marginBottom: 12,
  },
  plans: {
    textDecoration: 'underline',
  },
}))(({
  classes,
  open,
  goal,
  focusAreas = null,
  selection = null,
  disabled = false,
  handleClose = () => {},
  onStart = () => {},
  onSelect = () => {},
  onBack = () => {},
  subscribed = false,
  recommendations = [],
}) => {
  const location = useLocation();
  const { fullScreenLoader } = useLoaders([focusAreas]);

  const container = React.useRef();
  const resetScroll = () => {
    if (!container.current) { return; }
    // It does not seem possible to target the correct frame individually since
    // it is generated by <SwipeableViews />, so instead the scroll state is
    // is reset for the entire sub-tree of the <GoalSheet />.
    try {
      container.current.scrollTo(0, 0);
      const children = container.current.getElementsByTagName('*');
      forEach(c => c.scrollTo(0, 0), children);
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const style = {
    height: '100%',
    margin: -10,
    overflowY: 'visible',
  };
  const containerStyle = { height: '100%' };

  React.useEffect(resetScroll, [container, selection]);

  if (fullScreenLoader && open) { return fullScreenLoader; }

  return (
    <Dialog
      fullScreen
      className={classes.bottomSheet}
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
    >
      <div className={classes.illustrationContainer}>
        {
          goal
            ? <img className={classes.illustration} src={goal.illustration} alt='illustration' />
            : null
        }
        <Typography className={classes.title} variant='h2'>
          {
            goal ? goal.title : 'Loading'
          }
        </Typography>
      </div>
      <DialogContent className={classes.content} ref={container}>
        <div className={classes.inner}>
        {
          goal
            ? <>
                <SwipeableViews disabled={true} index={selection ? 1 : 0} style={style} containerStyle={containerStyle}>
                  <div className={classes.swipeContent}>
                    <Typography className={classes.description}>
                      {
                        goal.description
                      }
                    </Typography>

                    {
                      isEmpty(recommendations)
                        ? null
                        : <>
                          <Typography
                            className={classes.coachingHeading}
                            variant='h3'
                            component='h3'
                          >
                            Recommended Coachings
                        </Typography>
                          {
                            recommendations.map(r => (
                              r.focusAreas
                                ? r.focusAreas.map(fa => (
                                  <FocusAreaCard
                                    key={fa._id}
                                    focusArea={fa}
                                    onClick={() => onSelect(fa)}
                                  />
                                ))
                                : <RecommendationPlaceholder
                                  assessmentId={r.assessmentId}
                                  assessmentTitle={r.assessmentTitle}
                                  goal={goal}
                                />
                            ))
                          }
                        </>
                    }

                    <Typography
                      className={classes.coachingHeading}
                      variant='h3'
                      component='h3'
                    >
                      {
                        isEmpty(recommendations)
                          ? 'Coachings'
                          : 'Other Coachings'
                      }
                    </Typography>

                    {
                      focusAreas.map(fa => (
                        <FocusAreaCard
                          key={fa._id}
                          focusArea={fa}
                          onClick={() => onSelect(fa)}
                        />
                      ))
                    }
                  </div>
                  <div className={classes.swipeContent}>
                    <PageSection className={classes.pageSection}>
                      {
                        selection
                          ? <>
                            <Typography variant='h2' className={classes.coachingHeading}>
                              {
                                selection.title
                              }
                            </Typography>
                            <Typography>
                              {
                                selection.description
                              }
                            </Typography>
                            <Typography variant='h2' className={classes.coachingHeading}>
                              Coaching Plan
                            </Typography>
                            <CoachingPlan
                              className={classes.coachingPlan}
                              sessions={getSessions(selection)}
                            />
                          </>
                          : null
                      }
                    </PageSection>
                    {
                      subscribed
                        ? null
                        : <div className={classes.pleaseSubscribe}>
                          <Typography className={classes.subscribe}>
                            Start your 7-day trial today!{' '}
                            <Link className={classes.plans}
                              to={`/subscription/plans?redirectTo=${location.pathname}`}
                            >
                              Choose a plan
                          </Link>
                          </Typography>
                        </div>
                    }
                    <div className={classes.actionContainer}>
                      <Button
                        onClick={onBack}
                        className={classes.back}
                        variant='contained'
                        size='large'
                      >
                        <KeyboardArrowLeftIcon />
                      </Button>
                      <Button
                        className={classes.start}
                        variant='contained'
                        size='large'
                        disabled={disabled || !subscribed}
                        onClick={() => onStart(selection, goal)}
                      >
                        {
                          disabled
                            ? 'Starting Coaching ...'
                            : 'Start Coaching'
                        }
                      </Button>
                    </div>
                  </div>
                </SwipeableViews>
              </>
            : <div className='inline-loading-indicator'>
                Loading...
              </div>
        }
        </div>
      </DialogContent>
      <IconButton className={classes.close} onClick={handleClose}>
        <CloseIcon className={classes.closeIcon} />
      </IconButton>
    </Dialog>
  );
});
