import React from 'react';
import ReactDOM from 'react-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';

import { ThreeDotsLoader } from 'components/illustrations';
import { Typography } from '@material-ui/core';

const Alert = props => <MuiAlert elevation={6} variant='filled' {...props} />;

const Loader = withStyles(theme => ({
  root: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: theme.zIndex.modal,
  },
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.paper.darkest,
    opacity: 0.9,
  },
  indicatorContainer: {
    isolation: 'isolate',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.paper.darkest,
    borderRadius: 4,
    padding: '24px 48px'
  },
  indicator: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexGrow: 1,
    marginBottom: 12,

    '&:last-child': {
      marginBottom: 0,
    },
  },
  text: {
    color: theme.palette.text.light,
  },
}))(({
  classes,
  timedout = false,
}) => {
  return (
    <div className={classes.root}>
      <div className={classes.background} />
      <div className={classes.indicatorContainer}>
        <div className={classes.indicator}>
          <ThreeDotsLoader />
        </div>
        <div className={classes.indicator}>
          <Typography className={classes.text}>
            Loading
            </Typography>
        </div>
      </div>

      <Snackbar open={timedout} autoHideDuration={6000}>
        <Alert severity='error'>
          This loading process takes an unusual amount of time. Your connection
          maybe offline or the app isn&apos;t responding anymore. Please try
          again later.
        </Alert>
      </Snackbar>
    </div>
  );
});

export const FullscreenLoader = ({
  timedout = false,
}) => {
  return ReactDOM.createPortal(
    <Loader timedout={timedout} />,
    document.body,
  );
};