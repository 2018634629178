import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.main,
    cursor: 'pointer',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
  },
  heading: {
    marginBottom: 0,
    flexGrow: 1,
  },
  icon: {
    margin: 'auto 0',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  labels: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: '0.75rem',
  },
  headingLike: {
    fontFamily: 'Montserrat',
  },
}));

export const NotebookCard = ({
  notebook,
  onClick = () => {},
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} onClick={() => onClick(notebook)}>
      <CardContent className={classes.content}>
        <Box className={classes.title}>
          <Typography className={classes.heading} component='h3' variant='h3'>
            {notebook.title}
          </Typography>
          <ArrowForwardIos className={classes.icon} />
        </Box>

        <Typography component='p' variant='body1'>
          <b className={classes.headingLike}>Pages:</b>&nbsp;{
            notebook.pages.length
          }
        </Typography>

        <Box className={classes.labels}>
          <Typography className={classes.label}>
            <b className={classes.headingLike}>Created at:</b>&nbsp;{
              notebook.createdAt
                ? new Date(notebook.createdAt).toLocaleDateString()
                : 'N/A'
            }
          </Typography>
          <Typography className={classes.label}>
            <b className={classes.headingLike}>Last updated:</b>&nbsp;{
              notebook.updatedAt
                ? new Date(notebook.updatedAt).toLocaleDateString()
                : 'N/A'
            }
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};