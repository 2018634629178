import { CollectionStore } from 'modules/base';

export class CompletedCoachingNudgesStore extends CollectionStore {
  baseUrl = '/coaching-nudges/completed/coachee';

  async fetch(coacheeId) {
    if (!coacheeId) { return []; }

    const response = await this.httpService.get(
      `${this.baseUrl}/${coacheeId}`
    );

    const completedNudges = response.body.map(this.import);

    return completedNudges;
  }

  async complete(nudge, rating, coacheeId, coachingId) {
    const response = await this.httpService.post(
      `${this.baseUrl}/${coacheeId}`,
      {
        text: nudge.text,
        nudge: nudge._id,
        coaching: coachingId,
        rating,
      }
    );

    const completedNudge = this.import(response.body);

    return completedNudge;
  }
};
