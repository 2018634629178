import React from 'react';
import { pathOr } from 'ramda';

const PAGE_PADDING = 26;
const MAX_WIDTH = 450;

export const getClientWidth = () => Math.min(
  pathOr(414, ['body', 'clientWidth'], document) - (2 * PAGE_PADDING),
  MAX_WIDTH - (2 * PAGE_PADDING),
);

export const updateOnResize = setter => () => {
  if (!window || !window.addEventListener) { return setter(0); }

  const onResize = () => {
    setter(getClientWidth());
  };
  window.addEventListener('resize', onResize);

  return () => window.removeEventListener('resize', onResize);
};

export const useScreenWidth = () => {
  const [ width, setWidth ] = React.useState(getClientWidth());

  React.useEffect(updateOnResize(setWidth));

  return width;
};
