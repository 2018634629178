import React from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Navigation from '@material-ui/icons/Navigation';
import IconButton from '@material-ui/core/IconButton';
import CardContent from '@material-ui/core/CardContent';
import clsx from 'clsx';
import SwipeableViews from 'react-swipeable-views';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { assoc } from 'ramda';

const setIndex = assoc('index');

export const CoachingNudgeEntry = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.blue[200],
    color: theme.palette.text.light,
    marginRight: 10,
    marginBottom: 10,
    position: 'relative',
    overflow: 'unset',
    cursor: 'pointer',
    borderBottomRightRadius: 0,
  },
  ratingContainer: {
    position: 'absolute',
    top: 0, left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  ratingLabelContainer: {
    padding: '6px 12px',
    backgroundColor: theme.palette.paper.light,
    borderRadius: 4,
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'text-bottom',
  },
  rating: {
    color: theme.palette.text.dark,
  },
  icon: {
    fill: theme.palette.success.main,
    marginRight: theme.spacing(1),
  },
  hook: {
    fill: theme.palette.blue[200],
    transform: 'rotate(135deg)',
    position: 'absolute',
    right: -12,
    bottom: -12,
  },
}))(({
  nudge,
  rating,
  classes,
  className,
  onClick = () => {},
}) => (
  <Card className={clsx(classes.root, className)} elevation={0} onClick={() => onClick(nudge)}>
    <CardContent>
      { nudge.text }
      {
        rating
          ? <Box className={classes.ratingContainer}>
              <Box className={classes.ratingLabelContainer} component='span'>
                <CheckCircleIcon className={classes.icon} />
                <Typography className={classes.rating} variant='body1' component='span'>
                  {rating}
                </Typography>
              </Box>
            </Box>
          : null
      }
    </CardContent>
    <Navigation className={classes.hook} />
  </Card>
));

const SwipeableControls = withStyles(theme => ({
  root: {
    display: 'flex',
    gap: theme.spacing(3),
    width: '100%',
    justifyContent: 'center',
  },
}))(({
  classes,
  onFoward,
  onBack,
  className,
  currentIndex,
  total,
}) => (
  <Box className={clsx(classes.root, className)}>
    <IconButton onClick={onBack} disabled={currentIndex === 0}>
      <KeyboardArrowLeft />
    </IconButton>
    <Box display='flex' flexDirection='column' justifyContent='center'>
      <Typography component='div'>{currentIndex+1}/{total}</Typography>
    </Box>
    <IconButton onClick={onFoward} disabled={currentIndex === total - 1}>
      <KeyboardArrowRight />
    </IconButton>
  </Box>
));

export const CoachingNudgeList = withStyles(theme => ({
  slide: {
    overflow: 'hidden !important',
    display: 'flex',
    flexDirection: 'column',
  },
  hidden: {
    // display: 'none',
  },
  entry: {
    flexGrow: 1,
  },
}))(({
  classes,
  className,
  entries = [],
  onClick = () => {},
}) => {
  const [ state, setState ] = React.useState({
    index: 0,
  });

  const handleIndexChange = idx => setState(setIndex(idx));

  return (
    <Box className={className}>
      <SwipeableViews
        disabled={true}
        index={state.index}
        onChangeIndex={handleIndexChange}
        slideClassName={classes.slide}
      >
        {
          entries.map((entry, idx) => (
            <CoachingNudgeEntry
              key={entry._id}
              nudge={entry.nudge}
              onClick={onClick}
              className={clsx(classes.entry, {
                [classes.hidden]: idx !== state.index,
              })}
            />
          ))
        }
      </SwipeableViews>
      <SwipeableControls
        onFoward={() => setState({ index: Math.min(state.index + 1, entries.length - 1) })}
        onBack={() => setState({ index: Math.max(state.index - 1, 0) })}
        currentIndex={state.index}
        total={entries.length}
      />
    </Box>
  );
});
