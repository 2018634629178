import { makeObservable, observable, action } from 'mobx';

import { storage } from 'utils/client';

const LOCALSTORAGE_KEY = 'b-ff';

export class FeatureFocusStore {
  identifiers$ = observable.set([]);
  confirmedCache = null;

  constructor() {
    makeObservable(this, {
      'register': action,
      'remove': action,
    });

    this.confirmedCache = new Set(storage.getItem(LOCALSTORAGE_KEY) || []);
  }

  register(identifier) {
    this.identifiers$.add(identifier);
  }

  confirm(identifier) {
    this.confirmedCache.add(identifier);
    storage.setItem(LOCALSTORAGE_KEY, [ ...this.confirmedCache ]);
    this.identifiers$.delete(identifier);
  }

  remove(identifier) {
    this.identifiers$.delete(identifier);
  }

  isCurrent(id) {
    return this.identifiers$.has(id) && !this.confirmedCache.has(id);
  }
}
