import React from 'react';
import { Typography } from '@material-ui/core';
import { ImageSlide } from './image.slide';

export const Employee2Slide = () => (
  <ImageSlide
    src='https://storage.googleapis.com/bestselfy-static/onboarding/manager-onboarding-2.svg'
    alt='coaching skills'
    title='coaching skills'
  >
    <Typography variant='h1' component='h2'>
      Automated coaching for you
    </Typography>

    <Typography>
      In just 10 minutes a day and with support from your manager, become more
      happy, engaged, and successful.
    </Typography>
  </ImageSlide>
);
