export const ROLES = {
  USER: 0,
  ADMIN: 1,
};

export const MAIN_PAGE = '/dashboard';

export const DAILY_FEEDBACK = [
  {
    symbol: '😩',
    label: 'aweful',
  },
  {
    symbol: '😔',
    label: 'bad',
  },
  {
    symbol: '😐',
    label: 'ok',
  },
  {
    symbol: '😊',
    label: 'good',
  },
  {
    symbol: '😄',
    label: 'amazing',
  },
];
