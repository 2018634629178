import { Be5Section } from './be5.section';
import { ManagerDiscoverySection } from './manager-discovery.section';
import { LeadershipSkillsSection } from './leadership-skills.section';

export const ManagerProfile = () => (
  <>
    <Be5Section />
    <ManagerDiscoverySection />
    <LeadershipSkillsSection />
  </>
);
