import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import urlParse from 'url-parse';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { withStyles } from '@material-ui/core/styles';
import { includes } from 'ramda';

import { Markdown } from 'components/markdown';

const getDomainName = (url) => urlParse(url).host;

DOMPurify.addHook(
  'uponSanitizeAttribute',
  function (currentNode, hookEvent, config) {
    if (currentNode.nodeName === 'IFRAME') {
      if (includes(hookEvent?.attrName, config.ALLOWED_ATTR)) {
        hookEvent.forceKeepAttr = true;
      }
      if (!(getDomainName(currentNode.src) === 'www.youtube.com')) {
        hookEvent.keepAttr = false;
        hookEvent.forceKeepAttr = false;
      }
    }
    return currentNode;
  }
);

const Embed = withStyles({
  root: {
    marginBottom: 32,

    '&:last-child': {
      marginBottom: 0,
    }
  },
  video: {
    margin: '12px calc(-1 * var(--page-padding))',
    maxWidth: 'calc(100% + 2 * var(--page-padding))',
    overflow: 'hidden',
  },
  teaser: {
    marginBottom: 8,
  },
})(({
  _id,
  title,
  teaser,
  embedHTML,
  description,
  type,
  classes,
}) => {
  const cleanHTML = React.useMemo(() => (
      DOMPurify.sanitize(embedHTML, {
      ALLOWED_TAGS: ['iframe'],
      ALLOWED_ATTR: ['allowfullscreen', 'allow', 'frameborder', 'height', 'width', 'title', 'src'],
      ALLOWED_URI_REGEXP: /^https:\/\/www\.youtube\.com/i,
    })
  ), [embedHTML]);

  return (
    <div className={classes.root} id={`id-${_id}`}>
      <Typography variant='h3'>{title}</Typography>
      <Typography className={classes.teaser}>{teaser}</Typography>
      <div className={classes[type]}>{parse(cleanHTML)}</div>
      <Typography component='span'>
        <Markdown>{description}</Markdown>
      </Typography>
    </div>
  );
});

const Illustration = withStyles({
  root: {
    marginBottom: 32,

    '&:last-child': {
      marginBottom: 0,
    }
  },
  illustration: {
    margin: '12px calc(-1 * var(--page-padding))',
    maxWidth: 'calc(100% + 2 * var(--page-padding))',
    overflow: 'hidden',
  },
  teaser: {
    marginBottom: 8,
  },
})(({
  _id,
  title,
  teaser,
  url,
  description,
  type,
  classes,
}) => (
  <div className={classes.root} id={`id-${_id}`}>
    <Typography variant='h3'>{title}</Typography>
    <Typography className={classes.teaser}>{teaser}</Typography>
    <img className={classes[type]} src={url} alt={description} />
    <Typography component='span'>
      <Markdown>{description}</Markdown>
    </Typography>
  </div>
));

const Article = withStyles(theme => ({
  root: {
    marginBottom: 24,
    '&:last-child': {
      marginBottom: 0,
    }
  },
  teaser: {
    marginBottom: 8,
  },
  card: {
    cursor: 'pointer',
    backgroundColor: theme.palette.paper.dark,
    color: theme.palette.text.light,
    margin: '0.5rem 0',
  },
  content: {
    '&:last-child': {
      paddingBottom: 8,
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  host: {
    fontSize: '0.875rem',
  },
}))(({
  _id,
  title,
  teaser,
  url,
  description,
  classes,
}) => (
  <div className={classes.root} id={`id-${_id}`}>
    <Typography variant='h3'>{title}</Typography>
    <Typography className={classes.teaser}>{teaser}</Typography>
    <Card className={classes.card}>
      <a href={url} target='_blank' rel='noopener noreferrer'>
        <CardContent className={classes.content}>
          <div className={classes.container}>
            <Typography variant='h3'>Read Article</Typography>
            <ArrowForwardIosIcon />
          </div>
          <Typography className={classes.host}>
            {
              getDomainName(url)
            }
          </Typography>
        </CardContent>
      </a>
    </Card>
    <Typography component='span'>
      <Markdown>{description}</Markdown>
    </Typography>
  </div>
));

export const MediaList = ({
  media = [],
}) => {
  return (
    <>
      {
        media.map(m => {
          switch (m.type) {
            case 'video':
            case 'audio':
              return <Embed key={m._id} {...m} />;
            case 'illustration':
              return <Illustration key={m._id} {...m} />;
            case 'article':
              return <Article key={m._id} {...m} />;
            default:
              return null;
          }
        })
      }
    </>
  );
};

MediaList.propTypes = {
  media: PropTypes.array,
};
