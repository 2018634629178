import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

import { PageContent } from 'components/layout';
import { AuthService } from 'modules/auth';
import { useSegment } from 'utils/analytics';

const SUCCESS_MESSAGE_DEFAULT = 'Your email address was successfully verified. You can now close this page.';
const useStyles = makeStyles(theme => ({
  text: {
    marginTop: theme.spacing(4),
  },
  progress: {
    marginTop: theme.spacing(4),
  },
}));

export const EmailVerificationPage = inject(
  'authService'
)(({
  authService,
  successMessage = SUCCESS_MESSAGE_DEFAULT,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const { track } = useSegment();
  const [ state, setState ] = React.useState({
    status: null,
    message: 'Please wait while your email address is being verified.',
  });
  const search = queryString.parse(location.search) || {};

  React.useEffect(() => {
    (async () => {
      try {
        await authService.verifyEmail(search.oobCode);
        setState(prev => ({
          ...prev,
          status: 'success',
          message: successMessage,
        }));
        track('Email Verified', {
          category: 'Users',
        });
      } catch (err) {
        track('Email Verification Error', {
          category: 'Users',
          code: err.code,
        });
        setState(prev => ({ ...prev, status: err.code, message: err.message }));
      }
    })();
  }, []);

  return (
    <PageContent variant='backgroundPage'>
      <Helmet>
        <title>Email Verification</title>
      </Helmet>
      <Typography className={classes.header} variant='h1' component='h1'>
        Verifying email address.
      </Typography>
      {
        state.status === null
          ? <LinearProgress className={classes.progress} color='primary' />
          : null
      }
      <Typography className={classes.text}>
        {
          state.message
        }
      </Typography>
    </PageContent>
  );
});

EmailVerificationPage.propTypes = {
  authService: PropTypes.instanceOf(AuthService),
  successMessage: PropTypes.string,
};
