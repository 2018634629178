import React from 'react';
import PropTypes from 'prop-types';
import ErrorIcon from '@material-ui/icons/Error';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { pathOr } from 'ramda';

import { MenuStore } from 'modules/menu';
import { connectObserver } from 'utils/state';
import { HeaderLogo, BurgerMenuIcon } from 'components/icons';
import { useSegment } from 'utils/analytics';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 'var(--max-content-width)',
    width: '100vw',
    height: 'var(--header-height)',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.dark,
    color: theme.palette.text.light,
    position: 'fixed',
    top: '0',
    left: '0',
    zIndex: theme.zIndex.appBar,

    '@media (max-width: 480px)': {
      left: 0,
      right: 0,
    },

    '@media (min-width: 481px)': {
      left: 'calc(50% - 0.5 * var(--max-content-width))',
      right: 'calc(50% + 0.5 * var(--max-content-width))',
    }
  },
  item: {
    height: '100%',
    flex: '0 0 auto',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  nav: {
    marginLeft: theme.spacing(4),
    cursor: 'pointer',
  },
  logo: {
    flex: '1 0 auto',
  },
  portal: {
    marginRight: theme.spacing(2),
    minWidth: 20,
  },
  dashboardLink: {
    height: 28,
  },
  icon: {
    fill: theme.palette.warning.main,
    cursor: 'pointer',
  },
  emailSendNotification: {
    color: theme.palette.success.main,
  }
}));

export const AppHeaderComponent = connectObserver(
  'authService',
  'menuStore',
)(({
  authService,
  menuStore
}) => {
  const classes = useStyles();
  const openMenu = () => menuStore.open();
  const { track } = useSegment();
  const [state, setState] = React.useState({
    open: false,
  });

  const emailVerified = pathOr(true, ['currentUser$', 'emailVerified'], authService);
  const open = () => setState({ ...state, open: true });
  const close = () => setState({ ...state, open: false });

  const onSendConfirmationLink = async () => {
    close();
    const { protocol, host } = window.location;
    const redirectUrl = `${protocol}//${host}/email-verification-success`;
    await authService.sendEmailVerificationMail(redirectUrl);
    track('EmailVerificationMailRequested', {
      category: 'Users'
    });
  };

  return (
    <header className={classes.root}>
      <span onClick={openMenu} className={clsx(classes.item, classes.nav)}>
        <BurgerMenuIcon />
      </span>
      <span className={clsx(classes.item, classes.logo)}>
        <Link className={classes.dashboardLink} to='/dashboard'>
          <HeaderLogo />
        </Link>
      </span>
      <span className={clsx(classes.item, classes.portal)}>
        {
          emailVerified
            ? null
            : <ErrorIcon className={classes.icon} onClick={open} />
        }
      </span>
      <Dialog
        open={state.open}
        onClose={close}
      >
        <DialogContent>
          <Typography variant='h3' component='h3'>
            Email Verification Required
          </Typography>
          <Typography gutterBottom>
            Your email address hasn&apos;t been verified, yet. In order to
            receive notifications, we need to verify your email address first.
          </Typography>
          <Typography>
            If you already verified your email address, please reload the page.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button className={classes.emailSendNotification} onClick={onSendConfirmationLink}>
            Send Verification Mail
          </Button>
        </DialogActions>
      </Dialog>
    </header>
  );
});

AppHeaderComponent.propTypes = {
  menuService: PropTypes.instanceOf(MenuStore),
};
