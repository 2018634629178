import { CollectionStore } from 'modules/base';

export class SubscriptionPlansStore extends CollectionStore {
  baseUrl = '/subscriptions/plans';

  async subscribe(plan, query) {
    const response = await this.httpService.post(`${this.baseUrl}/${plan.id}/subscribe`, query);
    return response.body;
  }
}
