import { computed, observable, makeObservable } from 'mobx';

export class MenuStore {
  _isOpen$ = false;

  constructor() {
    makeObservable(this, {
      _isOpen$: observable,
      isOpen$: computed,
    });
  }

  get isOpen$() {
    return this._isOpen$;
  }
  set isOpen$(newValue) {
    this._isOpen$ = newValue;
  }

  open() {
    this.isOpen$ = true;
  }

  close() {
    this.isOpen$ = false;
  }

  toggle() {
    this.isOpen$(!this.open$);
  }
}
