import { observable } from 'mobx';
import { CollectionStore } from 'modules/base';

export class SelfDiscoveryAnswersStore extends CollectionStore {
  baseUrl = '/self-discovery/answers';

  constructor(config) {
    super(config);

    this.authService = config.authService;
  }

  get newItem() {
    return observable({
      question: null,
      answer: '',
      selection: [],
    });
  }

  import(item) {
    delete item.user;
    return super.import(item);
  }

  export(item) {
    item.user = this.authService.currentUser$._id;
    return super.export(item);
  }
}
