import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import log from 'loglevel';

import { SegmentContext } from 'utils/analytics';

export const SegmentProvider = ({ apiKey, ...props }) => {
  if (typeof window.analytics === 'undefined') {
    log.warn('SegmentProvider could not find segment analytics plugin');
  }

  const analytics = () => window.analytics;

  React.useEffect(() => {
    try {
      analytics().load(apiKey);
    } catch (err) {
      Sentry.captureException(err);
    }
  }, []);

  return (
    <SegmentContext.Provider { ...props } value={{ analytics }} />
  );
};

SegmentProvider.propTypes = {
  apiKey: PropTypes.string.isRequired,
};
