import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import log from 'loglevel';
import { useParams, useNavigate, useLocation, Navigate } from 'react-router-dom';
import {
  __, mergeDeepRight, pathOr, compose, assoc, reduce
} from 'ramda';

import { PageContent, PageSection } from 'components/layout';
import { SubHeader } from 'components/sub-header';
import { AssessmentAnswerForm } from 'components/assessment-answer';
import {
  AssessmentsStore,
  AssessmentAnswersStore,
} from 'modules/assessments';
import { GoalsStore } from 'modules/goals';
import { AuthService } from 'modules/auth';
import { useStoreQuery, connectObserver } from 'utils/state';
import { loginRequired } from 'modules/auth';
import { AssessmentConfigurationStore } from 'modules/cms';
import { handleError} from 'utils/logging';
import { withClassification } from 'utils/assessments';
import { useSegment } from 'utils/analytics';
import { pickParams } from 'utils/http';

const decorate = compose(loginRequired, connectObserver(
  'assessmentsStore',
  'authService',
  'assessmentAnswersStore',
  'assessmentConfigurationStore',
  'goalsStore',
));

const mergeAnswers = (questions, template, data) => compose(
  mergeDeepRight(template),
  assoc('answers', __, {}),
  reduce(
    (acc, q) => {
      const answer = { question: q._id, option: data[q._id] };
      if (!(acc[q.type] instanceof Array)) { acc[q.type] = []; }
      acc[q.type].push(answer);
      return acc;
    },
    {}
  ),
)(questions);

export const AnswerAssessmentPage = decorate(({
  assessmentsStore,
  assessmentAnswersStore,
  assessmentConfigurationStore,
  authService,
  goalsStore,
}) => {
  let redirect = null;
  let questions = [];
  const timeStarted = performance ? performance.now() : null;
  const location = useLocation();
  const navigate = useNavigate();
  const userId = authService.currentUser$.uid;
  const { track, useTrackEffect } = useSegment();
  const { id } = useParams();
  const [ qError, qLoading, assessment ] = useStoreQuery(assessmentsStore, 'get', {
    args: id
  });
  const [ aError, aLoading, prevAnswers ] = useStoreQuery(assessmentAnswersStore, 'fetch', {
    args: id
  });
  const [ xhrError, setXhrError ] = React.useState(null);
  const [ submitting, setSubmitting ] = React.useState(false);

  const loading = qLoading || aLoading;
  const error = qError || aError;
  const query = pickParams(['redo', 'redirectTo'], location);

  if (assessment) {
    questions = withClassification(assessment.questions);
  }

  if (!loading && (prevAnswers || []).length && query.redo !== '1') {
    const answerId = prevAnswers[0]._id;
    if (answerId) {
      let url = `/assessments/result/${answerId}`;
      if (query.redirectTo) {
        url += `?redirectTo=${query.redirectTo}`;
      }
      redirect = <Navigate to={url} replace={true} />;
    }
  }

  const onSubmit = handleError(async (data) => {
    const answerTemplate = assessmentAnswersStore.getAnswerTemplate(
      assessment,
      questions,
      userId
    );
    const answer = mergeAnswers(questions, answerTemplate, data);

    setSubmitting(true);
    const savedAnswer = await assessmentAnswersStore.save(answer);
    if (savedAnswer instanceof Error) {
      log.error(savedAnswer);
      setXhrError(savedAnswer);
      setSubmitting(false);
    } else {
      const classification = pathOr(null, ['result', 0, 'classification'], savedAnswer);
      track('Assessment Completed', {
        category: 'Assessments',
        result: savedAnswer._id,
        assessment: assessment.title,
        assessmentId: assessment._id,
        classification: classification.name,
        classificationId: classification._id,
        duration: timeStarted && performance.now() - timeStarted,
      });
      assessmentConfigurationStore.unfeature(assessment._id, 'assessment', 'dashboard');
      goalsStore.resetRecommendationCache();
      const search = query.redirectTo ? `?redirectTo=${query.redirectTo}` : '';
      navigate(`/assessments/result/${savedAnswer._id}${search}`);
    }
  }, navigate);

  useTrackEffect('Assessment Started', () => (assessment && {
    category: 'Assessments',
    assessment: assessment.title,
    assessmentId: assessment._id,
  }), [assessment]);

  return (
    <PageContent>
    {
      redirect
    }
    {
      loading && <div className='loading-indicator'>Loading...</div>
    }
    {
      (error || xhrError) && <div>Error...</div>
    }
    {
      !loading && !error && !xhrError && assessment && <>
        <Typography variant='h1' component='h1'>
        {
          assessment.title
        }
        </Typography>
        <SubHeader>
            Read each statement and select the answer that best describes you.
            There are no right or wrong answers so go with your gut and
            don&apos;t overthink it!
        </SubHeader>
        <PageSection variant='card'>
          <AssessmentAnswerForm
            questions={questions}
            onSubmit={onSubmit}
            disabled={submitting}
            submitting={submitting}
          />
        </PageSection>
      </>
    }
    </PageContent>
  );
});

AnswerAssessmentPage.propTypes = {
  assessmentsStore: PropTypes.instanceOf(AssessmentsStore),
  authService: PropTypes.instanceOf(AuthService),
  assessmentAnswersStore: PropTypes.instanceOf(AssessmentAnswersStore),
  assessmentConfigurationStore: PropTypes.instanceOf(AssessmentConfigurationStore),
  goalsStore: PropTypes.instanceOf(GoalsStore),
};
