import React from 'react';
import PropTypes from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useNavigate, useParams } from 'react-router-dom';

import { Markdown } from 'components/markdown';
import { PageSection } from 'components/layout';
import { TopicsStore } from 'modules/topics';
import { connectObserver, useStoreQuery } from 'utils/state';
import { stopPropagation } from 'utils/forms';

import './classifications-page.styles.css';

export const ClassificationsPage = connectObserver(
  'topicsStore',
)(({ topicsStore }) => {
  const navigate = useNavigate();
  const { topicId } = useParams();
  const [ error, loading, topic ] = useStoreQuery(topicsStore, 'get', {
    args: topicId
  });
  const [ selected, selectClassification ] = React.useState(null);

  const handleClick = classification => stopPropagation(() => (
    selectClassification(classification))
  );
  const goToClassification = (c) => {
    const url = `/classifications/${c._id}/self-reflection`;
    navigate(url);
  };

  return (
    <div className='ClassificationsPage PageContent'>
    {
      loading && <div>Loading...</div>
    }
    {
      error && <div>Error</div>
    }
    {
      !loading && !error && topic && <>
        <h1>
          <span className='HeaderPrefix'>Assessment</span>
          { topic.name }
        </h1>
        <p>
          Which personality type fits best?
        </p>
        <PageSection className='BottomStickyContainer'>
          {
            topic.classifications.map(c => (
              <ExpansionPanel key={c._id}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-label='Expand'
                  aria-controls='additional-actions1-content'
                  id='additional-actions1-header'
                >
                  <FormControlLabel
                    aria-label={c.name}
                    onClick={handleClick(c)}
                    control={<Radio checked={selected === c} />}
                    label={c.name}
                  />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Markdown source={c.description} />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))
          }
          <div className='BottomStickyControls'>
            <Button className='full-width' value='Continue' disabled={selected === null}
              onClick={() => goToClassification(selected)} />
          </div>
        </PageSection>
      </>
    }
    </div>
  );
});

ClassificationsPage.propTypes = {
  topicsStore: PropTypes.instanceOf(TopicsStore),
};
