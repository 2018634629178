export const SLIDES = [{
  title: 'be5 personality assessment ',
  alt: 'personality-assessment',
  text: 'Receive coaching recommendations based on your personality.',
  background: 'https://storage.googleapis.com/bestselfy-static/be5-subscriptions.svg',
  backgroundColor: '#30c3bc',
}, {
  title: '7-day trial',
  alt: 'free-trial',
  text: 'Test-drive all coaching sessions, be5, daily mood tracker, and tailored on-demand media.',
  background: 'https://storage.googleapis.com/bestselfy-static/free-trial-subscriptions.svg',
  backgroundColor: '#feca0b',
}, {
  title: 'Unlimited coaching sessions',
  alt: 'unlimited-access',
  text: 'Full access to all coaching sessions to match your changing needs.',
  background: 'https://storage.googleapis.com/bestselfy-static/unlimited-caochings-subscriptions.svg',
  backgroundColor: '#fb1827',
}, {
  title: 'Daily mood tracker',
  alt: 'daily-mood-tracker',
  text: 'Understand the impact mood has on your work.',
  background: 'https://storage.googleapis.com/bestselfy-static/mood-tracker-subscriptions.svg',
  backgroundColor: '#864ac0',
}];
