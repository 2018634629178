import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import CheckCircle from '@material-ui/icons/CheckCircleRounded';
import { withStyles } from '@material-ui/core/styles';

export const GoalCard = withStyles(theme => ({
  root: {
    padding: 8,
    flexShrink: 0,
    position: 'relative',
    borderRadius: 4,
    overflow: 'hidden',
    margin: 0,
    cursor: 'pointer',
    boxSizing: 'border-box',
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: 800,
    fontSize: 14,
    color: theme.palette.text.light,
    isolation: 'isolate',
  },
  background: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  placeholderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '100%',
    height: '100%',
  },
  placeholderIcon: {
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: 48,
    color: theme.palette.text.light,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  checkOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '90%',
    height: '90%',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  checkIcon: {
    position: 'absolute',
    right: '50%',
    bottom: '50%',
    marginRight: -48,
    marginBottom: -48,
    width: 96,
    height: 96,
    color: 'rgba(255, 255, 255, 0.8)',
  },
}))(({
  classes,
  className = '',
  placeholder = false,
  goal = null,
  backgroundColor = '#000000',
  selected = null,
  isFinished = false,
  size = 168,
  onClick = () => { },
}) => (
  <Paper className={clsx(className, classes.root)} style={{ backgroundColor, width: size, height: size }} onClick={onClick}>
    {
      placeholder
        ? <div className={classes.placeholderContainer}>
          <AddIcon className={classes.placeholderIcon} />
        </div>
        : <>
          <img className={classes.background} src={goal.illustration} alt='illustration' />
          <Typography className={classes.title}>{goal.title}</Typography>
          {
            isFinished
              ? <CheckCircle className={classes.checkIcon} />
              : null
          }
        </>
    }
    {
      (goal && typeof selected === 'string' && selected !== goal._id)
        ? <div className={classes.overlay} />
        : null
    }
  </Paper>
));