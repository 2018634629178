import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from "react-helmet";
import { compose } from 'ramda';
import { useLocation, useNavigate } from 'react-router-dom';

import { PageContent, PageSection } from 'components/layout';
import { SubscriptionStore } from 'modules/subscriptions';
import { BottomStickyControls } from 'components/bottom-sticky';
import { loginRequired } from 'modules/auth';
import { useSegment } from 'utils/analytics';
import { connectObserver } from 'utils/state';

const decorate = compose(loginRequired, connectObserver(
  'subscriptionStore',
));

const useStyles = makeStyles(theme => ({
  imgContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 48,
    marginLeft: 'calc(-1 * var(--page-padding))',
    marginRight: 'calc(-1 * var(--page-padding))',
    marginBottom: 48,
  },
  img: {
    margin: 'auto',
    width: '100%',
    height: 'auto',
  },
  title: {
    textAlign: 'center',
  },
  text: {
    textAlign: 'center',
  },
  subtitle: {
    maxWidth: 340,
    textAlign: 'center',
    margin: 'auto',
    marginBottom: 32,
  },
  quote: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    margin: 'auto',
    marginTop: 32,
  },
  quoteCard: {
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: theme.palette.paper.light,
    color: theme.palette.background.main,
    borderRadius: '35px 35px 0 35px',
  },
  quoteCardContent: {
    padding: '32px 24px',
    '&:last-child': {
      paddingBottom: 24,
    },
  },
  quoteText: {
    textAlign: 'center',
    fontSize: '1.25rem',
    marginBottom: 8,
    fontFamily: 'Montserrat',
  },
  quoteAuthor: {
    textAlign: 'right',
    fontFamily: 'Montserrat',
  },
  quotationMark: {
    position: 'absolute',
    fontSize: 144,
    color: theme.palette.accent.main,
    fontFamily: 'Montserrat',
    right: 25,
    top: -85,
    fontWeight: 700,
  },
}));

export const SubscriptionSuccessPage = decorate(({
  subscriptionStore,
}) => {
  const location = useLocation();
  const navigate = useNavigate ();
  const classes = useStyles();
  const { track } = useSegment();
  const [state, setState] = React.useState({ loading: true });
  const { subscription, id, redirectTo } = queryString.parse(location.search) || {};

  const to = url => () => navigate(url);

  React.useEffect(() => {
    (async () => {
      if (subscription && id) {
        await subscriptionStore.authorize(subscription, id);
        track('Subscription Payment Success', {
          category: 'Subscriptions',
        });
      }
      setState(prev => ({ ...prev, loading: false }));
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>
    <PageContent>
      <Helmet>
        <title>Subscription Success</title>
      </Helmet>

      <PageSection bottomSticky>
        <Typography variant='h2' component='h2' className={classes.title}>
          Thank you!
        </Typography>

        <Typography className={classes.text}>
          You successfully subscribed!
        </Typography>

        <div className={classes.imgContainer}>
          <img
            className={classes.img}
            src='https://storage.googleapis.com/bestselfy-static/rocket-stars.svg'
            alt='rocket'
          />
        </div>

        <Typography variant='h2' component='h2' className={classes.subtitle}>
          Ready for launch?
        </Typography>

        <div className={classes.quote}>
          <Card className={classes.quoteCard}>
            <CardContent className={classes.quoteCardContent}>
              <Typography className={classes.quoteText}>
                &quot;<i>The best investment you can make is in yourself.</i>&quot;
              </Typography>
              <Typography className={classes.quoteAuthor}>
                &mdash; Warren Buffett
              </Typography>
              <Typography className={classes.quotationMark}>
                &#8221;
              </Typography>
            </CardContent>
          </Card>
        </div>

        <BottomStickyControls variant='dark'>
          <Button variant='contained' disabled={state.loading} fullWidth
            onClick={to(redirectTo || '/topics')} size='large'
          >
            Continue
          </Button>
        </BottomStickyControls>
      </PageSection>
    </PageContent>
  </>;
});

SubscriptionSuccessPage.propTypes = {
  subscriptionStore: PropTypes.instanceOf(SubscriptionStore),
};