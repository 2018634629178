import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

import { PageSection } from 'components/layout';

const useStyles = makeStyles(theme => ({
  bottomSheet: {
    maxWidth: 'var(--max-content-width)',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: theme.palette.background.main,
  },
  bottomSheetTitle: {
    paddingTop: 24,
    paddingRight: 50,
    backgroundColor: theme.palette.background.main,
    color: theme.palette.text.light,
  },
  content: {
    backgroundColor: theme.palette.background.main,
    padding: '0 var(--page-padding)',
    display: 'flex',
    maxWidth: 'var(--max-content-width)',
    flexDirection: 'column',
  },
  close: {
    position: 'absolute',
    top: 12,
    right: 12,
    color: theme.palette.text.light,
  },
  pageSection: {
    display: 'block',
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export const BottomSheet = ({
  title = '',
  open = false,
  bottomSticky = false,
  content = null,
  actions = null,
  handleClose = () => {}
}) => {
  const classes = useStyles();

  return (
    <Dialog
      fullScreen
      className={classes.bottomSheet}
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
    >
      <DialogTitle disableTypography={!(title instanceof String)} className={classes.bottomSheetTitle}>
        {
          title
        }
        <IconButton className={classes.close} onClick={handleClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <PageSection className={classes.pageSection} variant='card' bottomSticky={bottomSticky}>
          {
            content
          }
        </PageSection>
      </DialogContent>
      {
        actions && <DialogActions>
          {
            actions
          }
        </DialogActions>
      }
    </Dialog>
  );
};

BottomSheet.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  open: PropTypes.bool,
  content: PropTypes.node,
  actions: PropTypes.node,
  handleClose: PropTypes.func,
  bottomSticky: PropTypes.bool,
};
