import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  radioGroup: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    width: '100%',
  },
  question: {
    marginBottom: 24,
  },
  labelRoot: {
    margin: 0,
    flexBasis: 40,
    flexGrow: 1,
  },
  label: {
    fontSize: '0.7rem',
    textAlign: 'center',
  },
}));

export const ScaleQuestionField = ({
  question,
  name,
  onChange,
  answer,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant='h4' component='h4' className={classes.question}>
      {
        question.text
      }
      </Typography>
      <RadioGroup className={classes.radioGroup}>
      {
        question.options.map(option => (
          <FormControlLabel
            className={classes.labelRoot}
            key={option._id}
            value={option._id}
            control={
              <Radio
                type='radio'
                name={`${name}.option`}
                onClick={onChange}
                checked={option._id === answer}
              />
            }
            label={
              <Typography className={classes.label}>
                { option.label }
              </Typography>
            }
            labelPlacement='bottom'
          />
        ))
      }
      </RadioGroup>
    </div>
  );
};

ScaleQuestionField.propTypes = {
  question: PropTypes.shape({
    text: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      _id:  PropTypes.string.isRequired,
      label: PropTypes.string,
      order: PropTypes.number.isRequired,
    })),
  }),
  answer: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
