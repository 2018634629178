import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, Slide, Typography, Paper, IconButton, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { OptionCard } from './option-card.component';
import { BottomStickyControls } from 'components/bottom-sticky';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 'var(--max-content-width)',
    margin: '0 auto',
  },
  content: {
    padding: 'var(--page-padding)',
    backgroundColor: theme.palette.paper.light,
    height: '100%',
    gap: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 76,
  },
  question: {
    marginBottom: '1rem',
    paddingRight: 24,
  },
  closeButton: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export const AnswerSelectionDialog = ({
  open = false,
  question = '',
  initialSelection = [],
  options = [],
  min = 1,
  max,
  onClose = () => {},
  onChange = () => {},
}) => {
  const classes = useStyles();
  max = max || options.length;
  const [state, setState] = React.useState({
    selection: [],
  });

  const selectionValid = state.selection.length >= min && state.selection.length <= max;

  const handleSelect = (option) => () => {
    const included = state.selection.includes(option);

    if (!included && state.selection.length >= max) {
      return;
    }

    if (included) {
      setState(prev => ({
        ...prev,
        selection: prev.selection.filter(s => s !== option),
      }));
    } else {
      setState(prev => ({
        ...prev,
        selection: [...prev.selection, option],
      }));
    }
  };

  const handleSubmit = () => {
    onChange(state.selection);
    onClose();
  };

  React.useEffect(() => setState(prev => ({
    ...prev,
    selection: initialSelection,
  })), [open]);

  return (
    <Dialog
      open={open}
      className={classes.root}
      onClose={onClose}
      TransitionComponent={Transition}
      closeAfterTransition={true}
      fullScreen
    >
      <Paper elevation={0} className={classes.content}>
        <Typography className={classes.question} variant='h4'>{question}</Typography>
        {
          options.map(option => (
            <OptionCard
              key={option}
              option={option}
              onClick={handleSelect(option)}
              disabled={state.selection.length >= max}
              selected={state.selection.includes(option)}
            />
          ))
        }
      </Paper>
      <IconButton className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <BottomStickyControls>
        <Button
          disabled={!selectionValid}
          fullWidth variant='contained'
          color='primary'
          size='large'
          onClick={handleSubmit}
        >
          {
            selectionValid
              ? 'Save'
              : `Please select ${min === max ? max : `${min}-${max}`} options`
          }
        </Button>
      </BottomStickyControls>
    </Dialog>
  );
};

AnswerSelectionDialog.propTypes = {
  question: PropTypes.string,
  initialSelection: PropTypes.array,
  options: PropTypes.arrayOf(PropTypes.string),
  open: PropTypes.bool,
  className: PropTypes.string,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
};
