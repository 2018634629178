export const cyan = {
  50: '#a2ded9',
  100: '#8bd5d0',
  200: '#74cdc6',
  300: '#5cc4bd',
  400: '#45bcb3',
  500: '#2eb4aa',
  600: '#17aba0',
  700: '#138a85',
  800: '#106869',
  900: '#0c474e',
};
