import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import MuiAlert from '@material-ui/lab/Alert';
import { Helmet } from 'react-helmet';
import { compose, assoc } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate, useParams } from 'react-router-dom';

import { PageContent, PageSection } from 'components/layout';
import { HeaderPrefix } from 'components/header-prefix';
import { SubHeader } from 'components/sub-header';
import { NewMemberBottomSheet } from './components/new-member-bottom-sheet.component';
import { PendingInvitationCard } from './components/pending-invitation-card.component';
import { TeamMemberCard } from './components/team-member-card.component';
import { loginRequired } from 'modules/auth';
import { guard } from 'utils/decorators';
import { connectObserver, useStoreQuery, useLoaders } from 'utils/state';
import { useSegment } from 'utils/analytics';
import { BottomStickyControls } from 'components/bottom-sticky/bottom-sticky-controls.component';

const openMenu = assoc('menuOpen');
const showCopySnackbar = assoc('showCopySnackbar');
const showResendSnackbar = assoc('showResendSnackbar');
const setSaving = assoc('saving');
const decorate = compose(loginRequired, connectObserver('teamsStore'));

const Alert = props => <MuiAlert elevation={6} variant='filled' {...props} />;

const useStyles = makeStyles(theme => ({
  root: {},
  addButton: {
    marginBottom: theme.spacing(2),
  },
  card: {
    marginBottom: theme.spacing(2),
  },
}));

export const TeamPage = decorate(({
  teamsStore,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const { track } = useSegment();
  const [error, loading, team] = useStoreQuery(teamsStore, 'get', {
    args: id,
  });
  const [state, setState] = React.useState({
    menuOpen: false,
    showCopySnackbar: false,
    showResendSnackbar: false,
    saving: false,
  });

  const { fullscreenLoader } = useLoaders([loading]);

  const publicLink = `${window.location.origin}/teams/join/${team?._id}`;
  const handleOpenMenu = () => setState(openMenu(true));
  const handleCloseMenu = () => setState(openMenu(false));
  const handleShowCopySnackbar = () => setState(showCopySnackbar(true));
  const handleHideCopySnackbar = () => setState(showCopySnackbar(false));
  const handleShowResendSnackbar = () => setState(showResendSnackbar(true));
  const handleHideResendSnackbar = () => setState(showResendSnackbar(false));
  
  const inviteMember = async (data) => {
    setState(setSaving(true));
    guard(async () => {
      await teamsStore.inviteNewMember(team, data);
      setState(setSaving(false));
      track('Team Member invited', {
        category: 'Teams',
        teamId: team._id,
        ...data
      });
      handleCloseMenu();
    });
  };

  const deletePendingInvitation = async (invitation) => {
    setState(setSaving(true));
    guard(async () => {
      await teamsStore.deletePendingInvitation(team, invitation.email);
      setState(setSaving(false));
      track('Pending Invitation Deleted', { category: 'Teams', teamId: team._id });
      handleCloseMenu();
    });
  };

  const removeMember = async member => {
    setState(setSaving(true));
    guard(async () => {
      await teamsStore.removeMember(team, member);
      setState(setSaving(false));
      track('Team Member Removed', { category: 'Teams', teamId: team._id, memberId: member._id });
      handleCloseMenu();
    });
  };

  const copyPublicInvitationLink = () => {
    navigator.clipboard.writeText(publicLink);
    handleShowCopySnackbar();
    handleCloseMenu();
  };

  const copyInvitationLink = (invitation) => {
    const encodedEmail = encodeURIComponent(invitation.email);
    const invitationLink = `${window.location.origin}/teams/join/${team._id}?email=${encodedEmail}&invitation=${invitation._id}`;
    navigator.clipboard.writeText(invitationLink);
    handleShowCopySnackbar();
  };

  const resendInvitation = async (invitation) => {
    await inviteMember({ email: invitation.email });
    handleShowResendSnackbar();
  };

  const showMember = member => {
    const url = `/teams/${team._id}/members/${member._id}`;
    navigate(url);
  };
  
  return (
    <PageContent className={classes.root}>
      {fullscreenLoader}
      <Helmet>
        <title>Teams</title>
      </Helmet>
      <Typography variant='h1' component='h1'>
        <HeaderPrefix>Teams</HeaderPrefix>
        {team?.name}
      </Typography>
      <SubHeader>
        Support your team with individual coaching.
      </SubHeader>
      <PageSection variant='card' bottomSticky>
        {
          !loading && !error && team && <>
            <div className={classes.pageSection}>
              <Button
                fullWidth
                className={classes.addButton}
                variant='outlined' size='large' color='primary'
                startIcon={<AddCircleOutlineIcon />}
                onClick={handleOpenMenu}
              >
                Add Team Member
              </Button>
              {
                team.invitations.map(invitation => (
                  <PendingInvitationCard
                    key={invitation._id}
                    className={classes.card}
                    invitation={invitation}
                    onResendInvitation={resendInvitation}
                    onCopyInvitationLink={copyInvitationLink}
                    onDeleteInvitation={deletePendingInvitation}
                    disabled={state.saving}
                  />
                ))
              }
              {
                team.members.map(member => (
                  <TeamMemberCard
                    key={member._id}
                    className={classes.card}
                    member={member}
                    onShowMember={showMember}
                    onRemoveMember={removeMember}
                  />
                ))
              }
            </div>
          </>
        }
        <NewMemberBottomSheet
          open={state.menuOpen}
          invitationLink={publicLink}
          onClose={handleCloseMenu}
          onMemberInvite={inviteMember}
          onCopyInvitationLink={copyPublicInvitationLink}
          disabled={state.saving}
        />

        <Snackbar
          open={state.showCopySnackbar}
          autoHideDuration={3000}
          onClose={handleHideCopySnackbar}
        >
          <Alert className={classes.snackbar} onClose={handleHideCopySnackbar} severity='info'>
            Invitation Link Copied
          </Alert>
        </Snackbar>

        <Snackbar
          open={state.showResendSnackbar}
          autoHideDuration={3000}
          onClose={handleHideResendSnackbar}
        >
          <Alert className={classes.snackbar} onClose={handleHideResendSnackbar} severity='info'>
            Invitation Sent
          </Alert>
        </Snackbar>
      </PageSection>
      <BottomStickyControls>
        <Button
          fullWidth
          variant='contained'
          size='large'
          color='primary'
          onClick={() => navigate(`/teams`)}
        >
          Show All Teams
        </Button>
      </BottomStickyControls>
    </PageContent>
  );
});