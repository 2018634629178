import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { BeSquaresBackground } from 'components/backgrounds';
import { BeLogo } from 'components/icons';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100vw',
    maxWidth: 'var(--max-content-width)',
    margin: 'auto',
    padding: 'var(--page-padding)',
    boxSizing: 'border-box',
    marginTop: 'var(--header-height)',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
    backgroundColor: theme.palette.background.main,
  },
  'no-background': {
    color: theme.palette.text.dark,
    backgroundColor: theme.palette.paper.light,
  },
  backgroundPage: {
    color: theme.palette.text.dark,
    margin: '0 auto',
    paddingTop: 128,
    overflow: 'auto',
    backgroundColor: 'unset',
  },
  empty: {
    width: '100vw',
    maxWidth: 'var(--max-content-width)',
    margin: 'auto',
    padding: 'unset',
    boxSizing: 'border-box',
    marginTop: 'unset',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
    backgroundColor: 'unset',
  },
  logo: {
    marginBottom: 16,
  },
}));

export const PageContent = ({
  className,
  children,
  variant,
  showBackgroundLogo = true,
}) => {
  const classes = useStyles();

  return <>
    {
      variant === 'backgroundPage'
        ? <BeSquaresBackground />
        :null
    }
    <div className={clsx(classes.root, className, classes[variant])}>
      {
        variant === 'backgroundPage' && showBackgroundLogo
          ? <BeLogo className={classes.logo} />
          :null
      }
      {
        children
      }
    </div>
  </>;
};

PageContent.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  variant: PropTypes.string,
  showBackgroundLogo: PropTypes.bool,
};
