import React from 'react';
import PropTypes from 'proptypes';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

const CURRENCY_SYMBOLS = {
  USD: '$',
};

const ButtonLabel = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  planName: {
    fontFamily: 'Montserrat',
    fontWeight: 800,
  },
  planPrice: {
    fontFamily: 'Montserrat',
  },
})(({
  classes,
  plan,
  active = false,
}) => {
  const cCode = CURRENCY_SYMBOLS[plan.currency_code];
  const price = plan.price / plan.period / 100;

return <span className={clsx(classes.root, {
  [classes.active]: active
})}>
    <Typography className={classes.planName}>
      {
      active ? 'Subscribed' : plan.name
      }
    </Typography>
    <Typography className={classes.planPrice}>
      {
        `${cCode}${price} / ${plan.period_unit}`
      }
    </Typography>
  </span>;
});

export const SubscriptionPlanCard = withStyles(theme => ({
  root: {},
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 16,

    '&:last-child': {
      marginBottom: 0,
    },
  },
  labeledButton: {
    border: `2px solid ${theme.palette.accent.main}`,
  },
  subscribed: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.text.light,
    '&:disabled': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.text.light,
    }
  },
  label: {
    fontSize: '0.875rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: theme.palette.accent.main,
    borderRadius: '4px 4px 0 0',
    color: theme.palette.text.light,
    padding: '2px 8px 0 8px',
  },
  accent: {
    borderColor: theme.palette.accent.main,
    backgroundColor: theme.palette.accent.main,
  },
  success: {
    borderColor: theme.palette.success.main,
    backgroundColor: theme.palette.success.main,
  },
  expiration: {
    marginTop: 4,
    fontSize: '0.75rem',
    color: theme.palette.success.main,
    width: '100%',
    textAlign: 'center',
  },
}))(({
  plan,
  className = '',
  classes,
  label,
  labelColor,
  subscribed = false,
  couponExpiration,
  disabled = false,
  onClick = () => {},
}) => (
  <div className={classes.wrapper}>
    {
      label
        ? <div className={clsx(classes.label, classes[labelColor])}>
            <Typography variant='h4' component='span'>
            {
              label
            }
            </Typography>
          </div>
        : null
    }
    <Button fullWidth variant='contained' className={clsx(classes.cta, classes[labelColor], className, {
      [classes.subscribed]: subscribed,
      [classes.labeledButton]: Boolean(label),
    })} onClick={() => onClick(plan)} disabled={disabled} size='large'>
      <ButtonLabel plan={plan} active={subscribed} />
    </Button>
    {
      couponExpiration
        ? <Typography className={classes.expiration}>
            This offer is valid until: {
              couponExpiration
            }
          </Typography>
        : null
    }
  </div>
));

SubscriptionPlanCard.propTypes = {
  plan: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    currency_code: PropTypes.string.isRequired,
    period: PropTypes.number.isRequired,
    period_unit: PropTypes.string.isRequired,
    description: PropTypes.string,
    trial_period: PropTypes.number,
    trial_period_unit: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  subscribed: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.node,
};
