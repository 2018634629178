import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { BottomSheetDialog } from 'components/dialogs';
import { Notebook } from 'components/notes';
import { NotebooksStore } from 'modules/notebooks';
import { connectObserver, useLoaders } from 'utils/state';
import { getPageData } from 'modules/notebooks';

const useStyles = makeStyles({
  content: {
    position: 'relative',
  },
  title: {
    marginBottom: 32,
    paddingRight: 12,
  },
  closeButton: {
    position: 'absolute',
    top: 4,
    right: 4,
    margin: 0,
    padding: 0,
    minWidth: 'unset',
  },
});

export const NotebookDialog = connectObserver(
  'notebooksStore',
)(({
  notebooksStore,
  className,
  coaching,
  open,
  onClose = () => {},
  ...props
}) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    notebook: null,
    loading: true,
    error: null,
  });
  const { fullScreenLoader } = useLoaders([state.notebook]);

  React.useEffect(() => {
    if (!coaching || !open) { return; }
    (async () => {
      setState(prev => ({ ...prev, loading: true }));
      try {
        const notebook = await notebooksStore.forCoaching(coaching._id);
        setState(prev => ({ ...prev, loading: false, notebook }));
      } catch (err) {
        setState(prev => ({ ...prev, loading: false, error: err }));
      }
    })();
  }, [coaching, open]);

  const pageData = React.useMemo(
    () => {
      if (!coaching || !state.notebook) { return []; }
      return getPageData(state.notebook.pages, coaching.sessions);
    },
    [state.notebook, coaching]
  );

  if (fullScreenLoader && open) { return fullScreenLoader; }

  return (
    <BottomSheetDialog
      open={open}
      onClose={onClose}
      className={clsx(classes.root, className)}
      { ...props }
    >
      { state.loading ? <div>Loading...</div> : null }
      { state.error ? <div>Error...</div> : null }
      {
        !state.loading && !state.error && <>
          <DialogContent className={classes.content}>
            <Button className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </Button>
            <Typography variant='h2' component='h2' className={classes.title}>
              {
                coaching.title
              }
            </Typography>
            <Notebook pageData={pageData} />
          </DialogContent>
        </>
      }
    </BottomSheetDialog>
  );
});

NotebookDialog.propTypes = {
  notebooksStore: PropTypes.instanceOf(NotebooksStore),
  coaching: PropTypes.object,
};
