import React from 'react';
import PropTypes from 'prop-types';

export const ArrowIcon = ({
  width = '23',
  height = '10',
  viewBox = '0 0 23 10',
  stroke = '#6B46C6',
  className = '',
  ...props
}) => (
  <svg {...props} className={className} xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox}>
    <g fill='none' fillRule='evenodd' stroke={stroke} strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'>
        <path d='M5 0L0 5 5 10M1 5h22' transform='matrix(-1 0 0 1 23 0)'/>
    </g>
  </svg>

);

ArrowIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string,
};
