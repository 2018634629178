import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiThumbUpIcon from '@material-ui/icons/ThumbUp';
import MuiThumbDownIcon from '@material-ui/icons/ThumbDown';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

const Dialog = withStyles({
  root: {
    maxWidth: 'var(--max-content-width)',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})(MuiDialog);

const DialogContent = withStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    backgroundColor: theme.palette.background.main,
    color: theme.palette.text.light,

    '& > *:first-child': {
      marginTop: 'auto',
    },
  }
}))(MuiDialogContent);

const IconButton = withStyles({
  label: {
    fontSize: '2rem',
  },
})(Button);

const ThumbUpIcon = withStyles({
  root: {
    fontSize: '2.5rem',
  }
})(MuiThumbUpIcon);

const ThumbDownIcon = withStyles({
  root: {
    fontSize: '2.5rem',
  }
})(MuiThumbDownIcon);

export const SessionCompletionDialog = withStyles(theme => ({
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 76,

    '@media(max-width: 320px)': {
      marginBottom: 26,
    },
  },
  checkMarkCircle: {
    width: 161,
    height: 161,
    borderRadius: 161,
    backgroundColor: theme.palette.success.main,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
    marginBottom: 56,

    '@media(max-width: 375px)': {
      width: 125,
      height: 125,
      marginBottom: 28,
    },

    '@media(max-width: 320px)': {
      marginBottom: 14,
    },
  },
  checkMark: {
    fill: theme.palette.text.light,
    fontSize: 130,
    margin: 'auto',

    '@media(max-width: 375px)': {
      fontSize: 90,
    },
  },
  textBox: {
    marginTop: 'auto',
    marginBottom: 'auto',
    textAlign: 'center',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: '2rem',
    fontWeight: 700,

    '@media(max-width: 375px)': {
      fontSize: '1.5rem',
    },
  },
  text: {
    fontFamily: 'Montserrat',
    fontSize: '2rem',
    fontWeight: 400,

    '@media(max-width: 375px)': {
      fontSize: '1.5rem',
    },
  },
  ratingActionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  ratingActions: {
    margin: '48px auto',

    '@media(max-width: 320px)': {
      marginTop: 24,
      marginBottom: 24,
    },

    '@media(max-height: 740px)': {
      marginTop: 24,
      marginBottom: 24,
    },
  },
  iconButton: {
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  icon: {
    fill: theme.palette.text.disabled,
  },
  iconDislikeActive: {
    fill: theme.palette.warn.main
  },
  iconLikeActive: {
    fill: theme.palette.success.main
  },
}))(({
  classes,
  open = false,
  actionsDisabled = false,
  onClose = () => {},
  onContinue = () => {},
}) => {
  const [state, setState] = React.useState({
    rating: null,
  });

  const onLike = () => setState(prev => ({ ...prev, rating: 1 }));
  const onDislike = () => setState(prev => ({ ...prev, rating: 0 }));
  const handleContinue = () => onContinue(state.rating);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
    >
      <DialogContent>
        <div className={classes.imageContainer}>
          <div className={classes.checkMarkCircle}>
            <CheckIcon className={classes.checkMark} />
          </div>
          <div className={classes.textBox}>
            <Typography className={classes.title} component='h2'>
              Session Completed!
            </Typography>
            <Typography className={classes.text}>
              Congratulations, you achieved your daily goal!
            </Typography>
          </div>
        </div>

        <div className={classes.actions}>
          <div className={classes.ratingActionsContainer}>
            <div className={classes.ratingActions}>
              <Typography variant='h3' component='h3'>
                Did you like this session?
              </Typography>
              <div className={classes.buttonGroup}>
                <IconButton onClick={onDislike}>
                  <ThumbDownIcon className={clsx(classes.icon, {
                    [classes.iconDislikeActive]: state.rating === 0,
                  })} />
                </IconButton>
                <IconButton className={classes.iconButton} onClick={onLike}>
                  <ThumbUpIcon className={clsx(classes.icon, {
                    [classes.iconLikeActive]: state.rating === 1,
                  })} />
                </IconButton>
              </div>
            </div>
          </div>
          <div className={classes.continueAction}>
            <Button size='large' variant='contained' onClick={handleContinue} disabled={actionsDisabled} fullWidth>
              Continue
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
});

SessionCompletionDialog.propTypes = {
  onContinue: PropTypes.func,
  actionsDisabled: PropTypes.bool,
};
