import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import { AuthService } from 'modules/auth';
import { useSegment } from 'utils/analytics';

export const PageTracker = inject('authService')(({
  authService
}) => {
  const location = useLocation();
  const { usePageEffect, useIdentifyEffect } = useSegment();

  usePageEffect(location.pathname);
  useIdentifyEffect(authService.currentUser$);

  return null;
});

PageTracker.propTypes = {
  authService: PropTypes.instanceOf(AuthService),
};
