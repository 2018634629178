import { head, compose, find, filter, prop, propEq, flatten, map, includes } from 'ramda';

export const featuredIn = location => config => find(
  f => f.location === location && f.active,
  config.featured,
);

export const configForLocation = location => find(
  compose(
    c => prop('active', c || {}),
    find(propEq('location', location)),
    prop('featured')
  ),
);

export const filterFeaturedIn = location => filter(
  propEq('location', location)
);

export const filterIdentifier = identifier => filter(
  propEq('identifier', identifier)
);

export const filterActive = filter(prop('active'));

export const filterType = type => filter(propEq('type', type));

const getFeatured = map(prop('featured'));

export const getFeature = (type, identifier, location) => compose(
  head,
  filterActive,
  filterFeaturedIn(location),
  flatten,
  getFeatured,
  filterIdentifier(identifier),
  filterType(type),
);

export const getConfig = (type, identifier, location) => compose(
  configForLocation(location),
  filterIdentifier(identifier),
  filterType(type),
);

export const getAssessmentForConfig = feature => find(
  compose(includes(feature), prop('featured'))
);