import * as yup from 'yup';
import { SingletonStore } from 'modules/base';
import { makeObservable, computed } from 'mobx';

export class AccountStore extends SingletonStore {
  baseUrl = '/account';

  constructor(config) {
    super(config);

    makeObservable(this, {
      name$: computed,
      email$: computed,
      role$: computed,
    });
  }

  get newItem() {
    return {
      firstName: null,
      lastName: null,
      country: null,
      company: null,
      role: null,
      yearOfBirth: null,
      gender: null,
      nationality: null,
    };
  }

  get schema() {
    return yup.object().shape({
      firstName: yup.string().transform(v => v || null).nullable(),
      lastName: yup.string().transform(v => v || null).nullable(),
      country: yup.string().transform(v => v || null).nullable(),
      company: yup.string().transform(v => v || null).nullable(),
      role: yup.string().transform(v => v || null).nullable(),
      yearOfBirth: yup.number().transform(v => v || null).nullable(),
      gender: yup.string().transform(v => v || null).nullable(),
      nationality: yup.string().transform(v => v || null).nullable(),
    });
  }

  import(data) {
    if (data === null) {
      return super.import(this.newItem);
    } else {
      delete data.user;
      return super.import(data);
    }
  }

  get name$() {
    let name = null;
    if (this.item$) {
      name = (
        `${this.item$.firstName || ''} ${this.item$.lastName || ''}`
      ).trim();
    }

    return name;
  }

  get email$() {
    let email = 'name@company.com';
    if (this.item$) {
      email = this.item$.email;
    }

    return email;
  }

  get role$() {
    let role = [];

    if (this.item$) {
      this.item$.role && role.push(this.item$.role);
      this.item$.company && role.push(this.item$.company);
    }

    return (role.join(' at ') || '').trim();
  }
}