import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import { inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';

import { PageContent } from 'components/layout';
import { AuthService } from 'modules/auth';

const useStyles = makeStyles(theme => ({
  text: {
    marginBottom: theme.spacing(4),
  },
}));

export const LogoutPage = inject('authService')(({
  authService
}) => {
  const classes = useStyles();

  let timeout = null;
  React.useEffect(() => {
    timeout = setTimeout(async () => {
      await authService.logout();

      window.location.href = '/login';
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <PageContent variant='backgroundPage'>
      <Helmet>
        <title>Log Out</title>
      </Helmet>
      <Typography className={classes.header} variant='h1' component='h1'>
        Signing out of bestselfy.
      </Typography>
      <Typography className={classes.text}>
        See you soon.
      </Typography>
    </PageContent>
  );
});

LogoutPage.propTypes = {
  authService: PropTypes.instanceOf(AuthService)
};
