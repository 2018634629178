import { CollectionStore } from 'modules/base';

export class ClassificationsStore extends CollectionStore {
  baseUrl = '/classifications';

  get newItem() {
    return {
      name: '',
      description: '',
      order: null,
      selfReflectionDescription: '',
      reflections: [],
    };
  }
}
