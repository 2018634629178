import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './multiple-choice-question-field.styles.css';

export const MultipleChoiceQuestionField = React.forwardRef(
  function MultipleChoiceQuestionField(props, ref) {
    const className = classnames('MultipleChoiceQuestionField--text', {
      error: props.error ? props.error.type === 'required' : false,
    });
    return (
      <div className='MultipleChoiceQuestionField'>
        <div className={className}>
        {
          props.question.text
        }
        </div>
        <div className='MultipleChoiceQuestionField--options'>
        {
          props.question.options.map(option => (
            <div key={option._id} className='MultipleChoiceQuestionAnswerOption'>
              <input
                value={option._id}
                type='radio'
                name={`${props.name}.option`}
                onChange={props.onChange}
                ref={ref}
                className='MultipleChoiceQuestionField--radio'
              />
              <label>{ option.label }</label>
            </div>
          ))
        }
        </div>
      </div>
    );
  }
);

MultipleChoiceQuestionField.propTypes = {
  question: PropTypes.shape({
    text: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      _id:  PropTypes.string.isRequired,
      label: PropTypes.string,
      order: PropTypes.number.isRequired,
    })),
  }),
  name: PropTypes.string.isRequired,
  error: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
};
