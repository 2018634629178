import React from 'react';
import packageJson from '../../package.json';
import { mergeDeepRight } from 'ramda';

export const SegmentContext = React.createContext({
  analytics: () => ({
    page() {},
    identify() {},
    track() {},
  }),
});

export const useSegment = () => {
  const { analytics } = React.useContext(SegmentContext);

  if (analytics === null) {
    throw new Error('useSegment > "analytics" is not part of the context');
  }

  const page = (page) => analytics().page(page, {
    clientVersion: packageJson.version,
  });

  const identify = (user) => analytics().identify(user.uid, {
    email: user.email,
    clientVersion: packageJson.version,
  });

  const track = (eventName, payload = {}) => {
    analytics().track(
      eventName,
      mergeDeepRight(payload, { clientVersion: packageJson.version }),
    );
  };

  const usePageEffect = (url, deps = null) => React.useEffect(() => {
    page(url);
  }, deps === null ? [url] : deps);

  const useTrackEffect = (eventName, payloadFunc = () => ({}), deps) => React.useEffect(() => {
    const payload = payloadFunc();

    if (payload) {
      track(eventName, payload);
    }
  }, deps);

  const useIdentifyEffect = (user, deps = null) => React.useEffect(() =>{
    if (!user) { return; }

    identify(user);
  }, deps ? deps : [user]);

  return { page, identify, track, usePageEffect, useTrackEffect, useIdentifyEffect };
};

export const toPercentage = (number) => Math.min(Math.ceil(number * 100), 100);
