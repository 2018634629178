import React from 'react';
import PropTypes from 'proptypes';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { renderFieldError, getError } from 'utils/forms';

export const NameInputSlide = withStyles(theme => ({
  form: {
    marginTop: 8,
  },
  slide: {
    flex: '1 0 250px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    justifyContent: 'flex-end',
    boxSizing: 'border-box',
  },
  textField: {
    width: '100%',
    marginBottom: theme.spacing(3),
    color: theme.palette.text.main,

    '&:last-child': {
      marginBottom: theme.spacing(0),
    },
  },
  textContainer: {
    padding: 'var(--page-padding)',
    paddingTop: 50,
    paddingBottom: '5vh',
    isolation: 'isolate',
    backgroundImage: 'linear-gradient(to top, rgba(255, 255, 255, 1) 85%, rgba(255, 255, 255, 0) 100%)',

    '@media(max-width: 320px)': {
      paddingBottom: 12,
    },
  },
}))(({
  classes,
  errors = {},
  onSubmit = () => {},
  register = () => {},
}) => {
  const errorFor = getError(errors);

  return (
    <div className={classes.slide}>
      <div className={classes.textContainer}>
        <Typography variant='h1' component='h1'>
          Welcome to bestselfy
        </Typography>

        <Typography paragraph>
          Let&apos;s get started, what&apos;s your name?
        </Typography>

        <form
          className={classes.form}
          onSubmit={onSubmit}
        >
          {renderFieldError(errors, '__all__')}
          <TextField className={classes.textField} label='First name'
            type='text' name='firstName' variant='outlined'
            error={Boolean(errorFor('firstName'))} helperText={errorFor('firstName')}
            {...register('firstName')}
          />

          <TextField className={classes.textField} label='Last name'
            type='text' name='lastName' variant='outlined'
            error={Boolean(errorFor('lastName'))} helperText={errorFor('lastName')}
            {...register('lastName')}
          />
        </form>
      </div>
    </div>
  );
});

NameInputSlide.propTypes = {
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
  register: PropTypes.func,
};
