import React from 'react';
import PropTypes from 'prop-types';
import { slide as Menu } from 'react-burger-menu';
import { compose } from 'ramda';

import { AccountStore } from 'modules/account';
import { MenuStore } from 'modules/menu';
import { AuthService } from 'modules/auth';
import { connectObserver, useStoreQuery } from 'utils/state';
import { loginRequired } from 'modules/auth';

import './burger-menu.styles.css';

const decorate = compose(loginRequired, connectObserver(
  'menuStore',
  'authService',
  'accountStore',
));

export const BurgerMenu = decorate(({
  menuStore,
  authService,
  accountStore,
  items = [],
}) => {
  useStoreQuery(accountStore, 'get');
  const onChange = (state) => menuStore.isOpen$ = state.isOpen;
  const onNavigate = () => menuStore.close();

  return (
    <Menu isOpen={menuStore.isOpen$} onStateChange={onChange} disableAutoFocus width={290}>
      <span>
        <span className='bm-item-wrapper'>
          <span className='bm-item-header'>
            <h2>
              {
                accountStore.name$ ||
                (authService.currentUser$ && authService.currentUser$.email)
              }
            </h2>
            {
              accountStore.name$ && <span className='bm-email-subheading'>
              {
                (authService.currentUser$ && authService.currentUser$.email)
              }
              </span>
            }
          </span>
        </span>
      </span>

      {
        items.map(({ icon, item }, idx) => (
          <span key={idx}>
            <span className='bm-item-wrapper' onClick={onNavigate}>
              { icon && <span className='bm-item-inner'>{ icon }</span> }
              <span className='bm-item-inner'>{ item }</span>
            </span>
          </span>
        ))
      }
    </Menu>
  );
});

BurgerMenu.propTypes = {
  menuStore: PropTypes.instanceOf(MenuStore),
  authService: PropTypes.instanceOf(AuthService),
  accountStore: PropTypes.instanceOf(AccountStore),
  items: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.node,
    item: PropTypes.node.isRequired,
  })),
};
