import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    padding: 12,
    width: '100%',
    maxWidth: 'var(--max-content-width)',
    bottom: 0,
    backgroundColor: theme.palette.paper.light,
    boxSizing: 'border-box',
    boxShadow: '0 -5px 15px var(--lightest-grey)',
    display: 'flex',

    '@media (max-width: 480px)': {
      left: 0,
      right: 0,
    },

    '@media (min-width: 481px)': {
      left: 'calc(50% - 0.5 * var(--max-content-width))',
      right: 'calc(50% + 0.5 * var(--max-content-width))',
    }
  },
  dark: {
    backgroundColor: theme.palette.paper.darker,
  },
}));

export const BottomStickyControls = ({
  className = '',
  variant,
  children,
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, classes[variant], className)}>
      {
        children
      }
    </div>
  );
};

BottomStickyControls.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.any,
};
