import { CoachingsStore } from './coachings.store';

export class PastCoachingStore extends CoachingsStore {
  baseUrl = '/coachings/archive';
  error = new Error('PastCoachingStore is read-only');

  add(coaching) {
    this.items$.unshift(coaching);
  }

  async create() { throw this.error; }
  async save() { throw this.error; }
  async delete() { throw this.error; }
}
