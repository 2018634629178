import queryString from 'query-string';
import urlParse from 'url-parse';
import { curry, pick, mergeRight } from 'ramda';

export const pickParams = curry((params, location) => {
  return pick(params, queryString.parse(location.search));
});

export const addQuery = curry((query, url) => {
  const parsed = urlParse(url, true);
  parsed.set('query', mergeRight(parsed.query, query));
  // Only send pathname and query.
  return parsed.toString().replace(parsed.origin, '');
});
