import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import log from 'loglevel';
import { makeStyles } from '@material-ui/core/styles';

import { connectObserver } from 'utils/state';
import { useSegment } from 'utils/analytics';
import { AuthService } from 'modules/auth';
import { UpdatePasswordForm, UpdateEmailForm } from 'components/auth';

const useStyles = makeStyles(theme => ({
  submitButton: {
    width: '100%',
  },
  textField: {
    width: '100%',
    marginBottom: theme.spacing(3),
    color: theme.palette.text.dark,

    '&:last-child': {
      marginBottom: theme.spacing(0),
    },
  },
  success: {
    color: theme.palette.success.main,
    marginTop: theme.spacing(1.5),
  },
  divider: {
    margin: theme.spacing(0.5, 0, 1.5, 0),
  },
  form: {
    marginBottom: theme.spacing(4),

    '&:last-child': {
      marginBottom: theme.spacing(0),
    }
  }
}));

export const SecuritySettings = connectObserver(
  'authService'
)(({
  authService,
}) => {
  const classes = useStyles();
  const { track } = useSegment();
  const [ state, setState ] = React.useState({
    saving: false,
    passwordMessage: null,
    emailMessage: null,
    updatePasswordErrors: [],
    updateEmailErrors: [],
  });

  const currentUser = authService.currentUser$;

  const onSubmitPassword = reset => async (data) => {
    if (data.newPasswordConfirmation !== data.newPassword) {
      setState(prev => ({ ...prev, updatePasswordErrors: [{
        name: 'newPasswordConfirmation',
        reason: 'notMatch',
        message: 'Password and password confirmation must match',
      }] }));
      return;
    }

    setState(prev => ({ ...prev, saving: true, message: null }));

    try {
      await authService.login(currentUser.email, data.currentPassword);
      await currentUser.updatePassword(data.newPassword);
      track('Password Updated', { category: 'Users' });
      reset({});
      setState(prev => ({
        ...prev,
        saving: false,
        passwordMessage: 'Password updated successfully'
      }));
    } catch (err) {
      log.error('SecuritySettings#onSubmitPassword', err);
      let message = 'Something when wrong';
      const name = 'currentPassword';
      const reason = '';

      if (err.code === 'auth/wrong-password') {
        message = 'Wrong password';
      } else if (err.code === 'auth/too-many-requests') {
        message = 'Too many unsuccessful attempts. Please try again later';
      }

      const formError = { name, reason, message };
      setState(prev => ({
        ...prev,
        saving: false,
        updatePasswordErrors: [formError]
      }));
    }
  };

  const onSubmitEmail = reset => async ({ newEmail, password }) => {
    setState(prev => ({ ...prev, saving: true, message: null }));

    try {
      await authService.updateEmail(newEmail, password);
      track('Email Update Requested', { category: 'Users' });
      reset({});
      setState(prev => ({
        ...prev,
        saving: false,
        emailMessage: 'A verification email has been send to your new address'
      }));
    } catch (err) {
      log.error('SecuritySettings#onSubmitEmail', err);
      let message = 'Something when wrong';
      let name = 'newEmail';
      const reason = '';

      if (err.code === 'auth/email-already-in-use') {
        message = err.message;
      }

      const formError = { name, reason, message };
      setState(prev => ({
        ...prev,
        saving: false,
        updateEmailErrors: [formError]
      }));
    }
  };

  return (
    <>
      {
        currentUser && <>
          <Typography variant='h3'>Update Password</Typography>
          {
            state.passwordMessage && <Typography className={classes.success}>
              { state.passwordMessage }
            </Typography>
          }
          <Divider className={classes.divider} />
          <UpdatePasswordForm className={classes.form}
            onSubmit={onSubmitPassword}
            formErrors={state.updatePasswordErrors}
            saving={state.saving}
          />


          <Typography variant='h3'>Update Email Address</Typography>
          {
            state.emailMessage && <Typography className={classes.success}>
              { state.emailMessage }
            </Typography>
          }
          <Divider className={classes.divider} />
          <UpdateEmailForm className={classes.form}
            onSubmit={onSubmitEmail}
            formErrors={state.updateEmailErrors}
            saving={state.saving}
          />
        </>
      }
    </>
  );
});

SecuritySettings.propTypes = {
  authService: PropTypes.instanceOf(AuthService),
};
