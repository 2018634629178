import log from 'loglevel';
import * as Sentry from '@sentry/react';
import { tap } from 'ramda';

export const tapLog = (prefix = '') => tap((...args) => (
  log.debug('>>> tapLog', prefix, ...args)
));

export const tapDebugger = tap((...args) => {
  const _args = args;
  log.debug('>>> tapLog', ..._args);
  // eslint-disable-next-line no-debugger
  debugger;
});

export const handleError = (func, navigate = null) => async (...args) => {
  try {
    return await func(...args);
  } catch (error) {
    if (navigate !== null) {
      navigate('/error', { error });
    } else {
      Sentry.captureException(error);
      log.error(error);
    }
  }
};
