import { createTheme } from '@material-ui/core/styles';
import { createOverrides } from './overrides';
import { createTypography } from './typography';
import { palette } from './palette';

export const theme = createTheme({
  spacing: 8,
  palette,
  typography: createTypography(palette),
  overrides: createOverrides(palette),
});
