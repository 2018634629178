import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { Markdown } from 'components/markdown';
import { BottomSheet } from 'components/bottom-sheet';

const useStyles = makeStyles(theme => ({
  description: {
    marginBottom: theme.spacing(2),
  },
  detailedDescription: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
}));

export const SessionContentBottomSheet = ({
  session,
  open,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <BottomSheet
      title={
        <Typography variant='h1'>
          {session.title}
        </Typography>
      }
      open={open}
      content={
        <>
          {
            session.description
              ? <div className={classes.description}>
                <Typography variant='h3'>
                  Session Content
                </Typography>
                <Markdown className={classes.description}>
                  {session.description}
                </Markdown>
              </div>
              : null
          }

          {
            session.detailedDescription
              ? <div className={classes.detailedDescription}>
                <Typography variant='h3'>
                  More Information
                </Typography>
                <Markdown>
                  {session.detailedDescription}
                </Markdown>
              </div>
              : null
          }
        </>
      }
      handleClose={onClose}
    />
  );
};
