import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import log from 'loglevel';
import { makeStyles } from '@material-ui/core/styles';

import { connectObserver, useStoreQuery } from 'utils/state';
import { useSegment } from 'utils/analytics';
import { SettingsStore } from 'modules/settings';
import { timezones } from 'utils/timezones';

const useStyles = makeStyles(() => ({
  select: {
    width: '100%'
  },
}));

export const NotificationSettings = connectObserver(
  'settingsStore'
)(({
  settingsStore,
}) => {
  const classes = useStyles();
  const { track } = useSegment();
  const [ error, loading ] = useStoreQuery(settingsStore, 'get');
  const save = async settings => {
    try {
      await settingsStore.save(settings);
    } catch (err) {
      log.error(err);
    }
  };
  const handleChange = field => async event => {
    settings[field] = event.target.value;
    save(settings);
  };
  const onChangeNotifications = (area, type, prop) => async event => {
    settings.notifications[area][type][prop] = event.target.checked;
    save(settings);
    track('Notification Settings Updated', {
      category: 'Notifications',
      area,
      type,
      enabled: event.target.checked,
    });
  };

  const settings = settingsStore.item$;

  return (
    <>
      {
        error && <div className='inline-error-message'>Error...</div>
      }
      {
        loading && <div className='loading-indicator'>Loading...</div>
      }
      {
        !error && !loading && settings && <>
          <List className={classes.root}
            subheader={<ListSubheader>Channels</ListSubheader>}
          >
            <ListItem>
              <ListItemText primary='Email' />
              <ListItemSecondaryAction>
                <Switch
                  checked={settings.notifications.channels.email.enabled}
                  onChange={onChangeNotifications('channels', 'email', 'enabled')}
                  name='settings_notifications_channels_email'
                  edge='end'
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>

          <List className={classes.root}
            subheader={<ListSubheader>Notifications</ListSubheader>}
          >
            <ListItem>
              <ListItemText primary='Mood Tracker' />
              <ListItemSecondaryAction>
                <Switch checked={settings.notifications.types.moodTracker.enabled}
                  onChange={onChangeNotifications('types', 'moodTracker', 'enabled')}
                  name='settings_notifications_types_mood_tracker'
                  edge='end'
                />
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem>
              <ListItemText primary='Coaching Reminder' />
              <ListItemSecondaryAction>
                <Switch checked={settings.notifications.types.coachingReminder.enabled}
                  onChange={onChangeNotifications('types', 'coachingReminder', 'enabled')}
                  name='settings_notifications_types_challenge_reminder'
                  edge='end'
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>

          <List className={classes.root}
            subheader={<ListSubheader>Timezone</ListSubheader>}
          >
            <ListItem>
              <ListItemText>
                <Select
                  native
                  className={classes.select}
                  value={settings.timezone}
                  onChange={handleChange('timezone')}
                  label='Timezone'
                  inputProps={{ name: 'timezone' }}
                >
                  {
                    timezones.map(t => <option key={t} value={t}>{ t }</option>)
                  }
                </Select>
              </ListItemText>
            </ListItem>
          </List>
        </>
      }
    </>
  );
});

NotificationSettings.propTypes = {
  settingsStore: PropTypes.instanceOf(SettingsStore),
};
