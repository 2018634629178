import React from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollTop = () => {
  const location = useLocation();

  React.useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return null;
};

ScrollTop.propTypes = {

};
