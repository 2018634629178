import React from 'react';
import Slide from '@material-ui/core/Slide';

import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export const BottomSheetDialog = withStyles({
  root: {
    maxWidth: 'var(--max-content-width)',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})((props) => <Dialog {...props} TransitionComponent={Transition} fullScreen />);