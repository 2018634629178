import React from 'react';
import PropTypes from 'prop-types';

import { MediaList } from 'components/media';

export const MediaTab = ({
  media = [],
}) => {
  return (
    <div>
      <MediaList media={media} />
    </div>
  );
};

MediaTab.propTypes = {
  media: PropTypes.array,
};
