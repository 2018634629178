import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';

export const HostedPage = withStyles(theme => ({
  modalDismiss: {
    position: 'absolute',
    top: 8,
    right: 8,
    color: theme.palette.text.light,
    zIndex: theme.zIndex.tooltip,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modalContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: theme.zIndex.modal,
  },
  modalBackground: {
    backgroundColor: '#000000',
    opacity: 0.75,
    width: '100%',
    height: '100%',
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 'none',
  },
}))(({
  classes,
  page,
  cancelCheckout = () => {},
}) => (
  <div className={classes.modalContainer}>
    <div className={classes.modalBackground} onClick={cancelCheckout} />
    <iframe
      title='Checkout Subscription'
      src={page.url}
      className={classes.iframe}
    /> 
    <Button
      className={classes.modalDismiss}
      onClick={cancelCheckout}
      endIcon={<CloseIcon />}
    >
      Cancel
    </Button>
  </div>
));

HostedPage.propTypes = {
  classes: PropTypes.object,
  page: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  cancelCheckout: PropTypes.func,
};
