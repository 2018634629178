import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import SubjectIcon from '@material-ui/icons/Subject';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { path } from 'ramda';

import { BottomSheet } from 'components/bottom-sheet';
import { Markdown } from 'components/markdown';
import { varyByOs, OS } from 'utils/client';
import { useSegment } from 'utils/analytics';

const ResultSlider = withStyles(theme => ({
  root: {
    height: 32,
    color: theme.palette.text.main,
  },
  thumb: {
    height: 40,
    width: 18,
    borderRadius: 'unset',
    backgroundColor: theme.palette.accent.main,
    border: `1px solid ${theme.palette.paper.main}`,
    marginTop: -4,
  },
  rail: {
    height: 32,
    backgroundColor: theme.palette.paper.main,
    opacity: 1,
  },
}))(Slider);

export const Trait = withStyles(theme => ({
  root: {
    cursor: 'pointer',
  },
  sliderContainer: {
    position: 'relative',
    marginLeft: 'calc(-1 * var(--page-padding))',
    marginRight: 'calc(-1 * var(--page-padding))',
  },
  sliderLabel: {
    fontFamily: 'Montserrat',
    position: 'absolute',
    fontSize: '0.7rem',
    fontWeight: 600,
    color: theme.palette.text.darker,
    top: varyByOs({
      [OS.WINDOWS]: 20,
      [OS.MAC]: 20,
      [OS.IOS]: 27,
      [OS.ANDROID]: 27,
    }, 20),
  },
  low: {
    left: 'var(--page-padding)',
  },
  high: {
    right: 'var(--page-padding)',
  },
  title: {
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    paddingRight: 48,
    overflowWrap: 'anywhere',
  },
  sheetParagraph: {
    marginBottom: 24,
  },
  moreIcon: {
    color: theme.palette.accent.main,
    backgroundColor: theme.palette.paper.light,
    position: 'absolute',
    right: 0,
    top: -6,
    padding: 8,

    '&:hover': {
      backgroundColor: theme.palette.paper.darker,
    },
  },
  readMore: {
    textDecoration: 'underline',
    color: theme.palette.accent.main,
  },
}))(({
  result,
  className = '',
  classes,
}) => {
  const { track } = useSegment();
  const [ state, setState ] = React.useState({
    open: false,
  });

  const facet = path(['classification', 'facet'], result);
  const hasFacet = Boolean(facet);
  const open = () => {
    track('Open Trait', {
      category: 'Assessments',
      trait: result.classification.name,
    });
    setState({ open: true });
  };
  const close = () => setState({ open: false });

  const bottomSheetHeader = (
    <Typography variant='h1' component='h1'>
      {
        hasFacet
          ? facet.name
          : null
      }
    </Typography>
  );

  return (
    <>
      <div className={clsx(classes.root, className)}>
        <Typography className={classes.title} variant='h2' component='h2'>
          {
            result.classification.name
          }
          {
            facet.description || facet.strengths || facet.weaknesses
              ? <IconButton onClick={open} className={classes.moreIcon}>
                  <SubjectIcon />
                </IconButton>
              : null
          }
        </Typography>
        <Typography>
          {
            result.classification.description
          }
        </Typography>
        <div className={classes.sliderContainer}>
          <ResultSlider
            min={0} max={1} step={0.01}
            valueLabelDisplay='off'
            track={false}
            value={result.normalizedScore}
            onClick={open}
          />
          <Typography className={clsx(classes.sliderLabel, classes.low)}>
            {
              result.classification.labelLow
            }
          </Typography>
          <Typography className={clsx(classes.sliderLabel, classes.high)}>
            {
              result.classification.labelHigh
            }
          </Typography>
        </div>
      </div>
      {
        hasFacet
          ? <BottomSheet
            title={bottomSheetHeader}
            open={state.open}
            content={
              <>
                {
                  facet.description
                    ? <>
                        <Typography variant='h2' component='h2'>
                          Description
                        </Typography>
                        <Typography component='div' className={classes.sheetParagraph}>
                          <Markdown>{facet.description}</Markdown>
                        </Typography>
                      </>
                    : null
                }

                {
                  facet.strengths
                    ? <>
                        <Typography variant='h2' component='h2'>
                          Strengths
                        </Typography>
                        <Typography component='div' className={classes.sheetParagraph}>
                          <Markdown>{facet.strengths}</Markdown>
                        </Typography>
                      </>
                    : null

                }

                {
                  facet.weaknesses
                    ? <>
                        <Typography variant='h2' component='h2'>
                          Blindspots
                        </Typography>
                        <Typography component='div' className={classes.sheetParagraph}>
                          <Markdown>{facet.weaknesses}</Markdown>
                        </Typography>
                      </>
                    : null
                }
              </>
            }
            handleClose={close}
          />
          : null
      }
    </>
  );
});
Trait.propTypes = {
  result: PropTypes.object.isRequired,
  className: PropTypes.string,
};
