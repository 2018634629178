import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { pathOr } from 'ramda';

import { TodoListsStore } from 'modules/todos';
import { useSegment } from 'utils/analytics';
import { connectObserver } from 'utils/state';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  emailSendNotification: {
    color: theme.palette.success.main,
  }
}));

export const VerifyEmailSection = connectObserver(
  'authService',
)(({
  authService,
}) => {
  const classes = useStyles();
  const { track } = useSegment();
  const [ state, setState ] = React.useState({
    emailSend: false,
  });

  const emailVerified = pathOr(true, ['currentUser$', 'emailVerified'], authService);

  const onSendConfirmationLink = async () => {
    setState(prev => ({ ...prev, emailSend: false }));
    const { protocol, host } = window.location;
    const redirectUrl = `${protocol}//${host}/email-verification-success`;
    await authService.sendEmailVerificationMail(redirectUrl);
    track('EmailVerificationMailRequested', {
      category: 'Users'
    });
    setTimeout(() => setState(prev => ({ ...prev, emailSend: true })), 350);
  };

  return (
    emailVerified
      ? null
      : <Card className={classes.root}>
        <CardHeader
          title={
            <Typography variant='h2'>Email Verification Required</Typography>
          }
        />
        <CardContent>
          <Typography>
            Please verify your email address in order to receive notifications
            (check your spam folder if you can&apos;t find it).
          </Typography>
        </CardContent>
        <CardActions>
          {
            state.emailSend
              ? <Button size='small' className={classes.emailSendNotification}>
                Email Sent
              </Button>
              : <Button size='small' color='primary' onClick={onSendConfirmationLink}>
                Send Confirmation Link
              </Button>
          }
        </CardActions>
      </Card>
  );
});

VerifyEmailSection.propTypes = {
  todoListsStore: PropTypes.instanceOf(TodoListsStore),
  timerInfo: PropTypes.shape({
    destination: PropTypes.number,
    timeUntilReset: PropTypes.number,
  }),
};
