import React from 'react';
import PropTypes from 'prop-types';

export const BeLogo = ({
  width = '73',
  height = '42',
  viewBox = '0 0 73 42',
  className = '',
}) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox}>
    <defs>
      <linearGradient id='a' x1='-13.916%' y1='21.607%' y2='66.551%'>
        <stop offset='0%' stopColor='#04CAE6'/>
        <stop offset='100%' stopColor='#0547CC'/>
      </linearGradient>
    </defs>
    <path fill='url(#a)' fillRule='evenodd' d='M3.095 0c.128.087.286.073.426.125C4.539.5 5.103 1.25 5.264 2.29c.129.825.037 1.661.027 2.492-.04 3.575-.087 7.15-.132 10.725 0 .045.005.09.008.135.092.01.126-.054.172-.092 2.279-1.907 4.892-3.098 7.85-3.43 4.974-.56 9.257.933 12.775 4.482 2.049 2.067 3.371 4.554 3.914 7.401.912 4.78-.197 9.077-3.374 12.794-2.207 2.583-5.02 4.238-8.364 4.885-4.798.928-9.118-.172-12.863-3.309-2.425-2.03-4.006-4.613-4.805-7.665C.11 29.322-.008 27.91 0 26.48.04 18.65.073 10.82.108 2.988.112 1.908.486 1.008 1.4.37c.266-.186.581-.248.873-.37h.82zm67.121 36.01c1.39.108 2.536 1.165 2.74 2.524.006.037.03.071.044.107v.719c-.095.327-.16.66-.315.97-.593 1.186-1.849 1.797-3.024 1.648-1.45-.184-2.465-1.178-2.669-2.538-.286-1.908 1.288-3.582 3.224-3.43zM50.843 12.033c2.474.173 4.77.893 6.86 2.217 2.973 1.881 5 4.502 6.109 7.82.475 1.422.71 2.888.73 4.385.019 1.415-1.074 2.542-2.494 2.546-4.558.01-9.115.01-13.672 0-1.264-.004-2.331-.972-2.461-2.18-.14-1.3.664-2.45 1.927-2.752.236-.056.477-.066.72-.066h10.75c-.478-1.565-1.265-2.917-2.405-4.053-2.146-2.14-4.753-3.12-7.785-2.954-4.526.248-8.384 3.708-9.12 8.153-.851 5.14 2.498 10.037 7.624 11.155 1.452.316 2.913.271 4.379.123 1.685-.171 3.146-.876 4.501-1.84l1.042-.753c.175-.125.35-.247.53-.366.875-.58 1.801-.629 2.714-.109.888.507 1.323 1.31 1.261 2.32-.065 1.06-.635 1.802-1.628 2.21a1.337 1.337 0 0 0-.308.187c-2.226 1.715-4.725 2.806-7.515 3.229-2.649.401-5.256.272-7.795-.63-3.395-1.208-6.034-3.347-7.905-6.408a14.01 14.01 0 0 1-2.01-5.95c-.338-3.323.352-6.431 2.133-9.272 1.81-2.89 4.342-4.932 7.551-6.125a14.824 14.824 0 0 1 6.267-.887zm-35.758 4.96C9.649 17 4.99 21.342 5.04 27.106c.046 5.462 4.493 9.876 10.053 9.89 5.547.015 10.07-4.491 10.066-10.006-.005-5.52-4.521-10.004-10.074-9.997z'/>
  </svg>
);

BeLogo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  className: PropTypes.string,
};
