import { CollectionStore } from 'modules/base';

export class TodoListsStore extends CollectionStore {
  baseUrl = '/todos/lists';

  constructor(config) {
    super(config);

    this.authService = config.authService;
  }

  export(item) {
    const todoList = super.export(item);
    todoList.user = this.authService.currentUser$._id;
    delete todoList.deleted;
    return todoList;
  }

  import(item) {
    delete item.user;
    item.deleted = false;
    return super.import(item);
  }

  get newItem() {
    return {
      title: 'New To-do List',
      description: '',
      levels: [],
      media: [],
      topic: null,
      goal: null,
      deleted: false,
    };
  }
}
