import React from 'react';
import { object, string, array } from 'yup';
import { prop, match, head, compose, curry, pathOr } from 'ramda';

export const OptionSchema = array().of(object().shape({
  option: string().required(compose(head, match(/(\d+)/g), prop('path'))),
}));

export const AnswerSchema = object().shape({
  scale: OptionSchema,
  multipleChoice: OptionSchema,
});

export const renderFieldError = (errors, fieldName) => {
  let error = null;

  if (errors && errors[fieldName]) {
    error = errors[fieldName] instanceof Array ?
      errors[fieldName].map((e, idx) => (
        <p key={idx} className='form-error'>{ e.message }</p>)
      ) :
        <p className='form-error'>{ errors[fieldName].message }</p>;
  }

  return error;
};

export const stopPropagation = (func) => (event) => {
  if (event && event.stopPropagation instanceof Function) {
    event.stopPropagation();
  }
  return func(event);
};

export const preventDefault = (func) => (event) => {
  event.preventDefault();
  return func(event);
};

export const stopAndPrevent = compose(stopPropagation, preventDefault);

export const getError = curry((errors, field) => pathOr(null, [field, 'message'], errors));

export const wrapRegister = f => (...args) => {
  const props = f(...args);
  props.inputRef = props.ref;
  delete props.ref;
  return props;
};
