import { CollectionStore } from 'modules/base';
import { importAssessment, exportAssessment } from 'utils/assessments';

export class AssessmentsStore extends CollectionStore {
  baseUrl = '/assessments';
  _items$ = [];

  get newItem() {
    return {
      title: '',
      topic: null,
      questions: {
        scale: [],
        multipleChoice: [],
      },
      description: '',
      publishedAt: null,
    };
  }

  import(item) {
    return super.import(importAssessment(item));
  }

  export(item) {
    return exportAssessment(super.export(item));
  }

  async publish(assessment) {
    assessment.publishedAt = (new Date()).toISOString();
    return this.save(assessment);
  }

  async unpublish(assessment) {
    assessment.unpublishedAt = (new Date()).toISOString();
    return this.save(assessment);
  }
}
