import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

const YellowCircle = withStyles({
  root: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: 200,
    height: 200,
    transform: 'rotate(0deg) translate(-175px, -145px)',
    borderRadius: 100,
    backgroundImage: 'linear-gradient(15deg, #efbf75 7%, #f8ba39 39%)',
  },
  centered: {
    width: '26vw',
    maxWidth: 126,
    height: '26vw',
    maxHeight: 126,
    left: 'min(40px, 8vw)',
    top: 160,
    transform: 'unset',
    zIndex: 1,
  },
})(({
  classes,
  variant
}) => <div className={clsx(classes.root, classes[variant])} /> );

const BlueSquare = withStyles({
  root: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: 510,
    height: 510,
    transform: 'rotate(-152deg) translate(170px, 455px)',
    borderRadius: 100,
    backgroundImage: 'linear-gradient(to right, #003194 9%, #2d73f5 81%)',
  },
  centered: {
    width: '42vw',
    maxWidth: 199.23,
    height: '42vw',
    maxHeight: 197.79,
    left: 'min(205px, 43vw)',
    top: 120,
    borderRadius: 13,
    transform: 'rotate(-118.18deg)',
    zIndex: 3,
  },
})(({
  classes,
  variant
}) => <div className={clsx(classes.root, classes[variant])} />);

const TealSquare = withStyles({
  root: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: 416,
    height: 413,
    borderRadius: 100,
    transform: 'rotate(240deg) translate(90px, 375px)',
    backgroundImage: 'linear-gradient(222deg, #0ce7f5 26%, #0547cc 72%)',
  },
  centered: {
    width: '42vw',
    maxWidth: 202.69,
    height: '42vw',
    maxHeight: 202.69,
    left: 'min(90px ,19vw)',
    top: 45,
    borderRadius: 47,
    transform: 'rotate(-30deg)',
    zIndex: 2,
  },
})(({
  classes,
  variant
}) => <div className={clsx(classes.root, classes[variant])} />);

export const BeSquaresBackground = withStyles({
  root: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden',
    zIndex: -100,
  },
  centered: {
    left: 'unset',
    right: 'unset',
    maxWidth: 'var(--max-page-width)',
    margin: 'auto',
    width: '100%',
  },
})(({
  classes,
  variant,
  className,
}) => (
  <div className={clsx(classes.root, classes[variant], className)}>
    <YellowCircle variant={variant} />
    <BlueSquare variant={variant} />
    <TealSquare variant={variant} />
  </div>
));
