import React from 'react';
import PropTypes from 'prop-types';
import  { withStyles } from '@material-ui/core/styles';

export const ImageSlide = withStyles(theme => ({
  slide: {
    flex: '1 0 250px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    justifyContent: 'flex-end',
    boxSizing: 'border-box',
  },
  textContainer: {
    padding: 'var(--page-padding)',
    paddingTop: 50,
    paddingBottom: '5vh',
    isolation: 'isolate',
    backgroundImage: 'linear-gradient(to top, rgba(255, 255, 255, 1) 85%, rgba(255, 255, 255, 0) 100%)',

    '@media(max-width: 320px)': {
      paddingBottom: 12,
    },
  },
  imageContainer: {
    position: 'absolute',
    top: '15vh',
    width: '100%',
    display: 'flex',

    '@media(max-width: 320px)': {
      top: '2vh',
    },
  },
  image: {
    marginLeft: 'auto',
    marginRight: 'auto',
    height: 'auto',
    maxWidth: '100%',
  },
}))(({
  classes,
  src,
  alt,
  title,
  children,
}) => (
  <div className={classes.slide}>
    <div className={classes.imageContainer}>
      <img
        className={classes.image}
        src={src}
        alt={alt}
        title={title}
      />
    </div>

    <div className={classes.textContainer}>
      {children}
    </div>
  </div>
));

ImageSlide.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
