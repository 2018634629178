import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import MuiMobileStepper from '@material-ui/core/MobileStepper';
import * as Sentry from '@sentry/react';
import * as yup from 'yup';
import { Helmet } from 'react-helmet';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { compose } from 'ramda';
import { useForm, useFormState } from 'react-hook-form';

import { BeSquaresBackground } from 'components/backgrounds';
import { PageContent, PageSection } from 'components/layout';
import { AccountStore } from 'modules/account';
import { AssessmentConfigurationStore } from 'modules/cms';
import { connectObserver, useStoreQuery } from 'utils/state';
import { loginRequired } from 'modules/auth';
import { wrapRegister } from 'utils/forms';
import { getConfig } from 'utils/cms';

import { NameInputSlide } from './components/name-input.slide';
import { RoleInputSlide } from './components/role-input.slide';
import { Employee1Slide } from './components/employee-1.slide';
import { Employee2Slide } from './components/employee-2.slide';
import { Employee3Slide } from './components/employee-3.slide';
import { Manager1Slide } from './components/manager-1.slide';
import { Manager2Slide } from './components/manager-2.slide';
import { Manager3Slide } from './components/manager-3.slide';

const featuredInWelcome = getConfig('assessment', 'personality-assessment', 'welcome-page');

const MobileStepper = withStyles(theme => ({
  root: {
    background: 'unset',
  },
  dotActive: {
    backgroundColor: theme.palette.paper.dark,
  },
}))(MuiMobileStepper);

const decorate = compose(loginRequired, connectObserver(
  'accountStore',
  'assessmentConfigurationStore',
));

const useStyles = makeStyles(theme => ({
  page: {
    height: '100vh',
    position: 'relative',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    justifyContent: 'flex-end',

    '&:last-child': {
      marginBottom: 0,
      paddingBottom: 0,
    },
  },
  form: {
    marginTop: 8,
  },
  slide: {
    flex: '1 0 250px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    justifyContent: 'flex-end',
    boxSizing: 'border-box',
  },
  textField: {
    width: '100%',
    marginBottom: theme.spacing(3),
    color: theme.palette.text.main,

    '&:last-child': {
      marginBottom: theme.spacing(0),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  textContainer: {
    padding: 'var(--page-padding)',
    paddingTop: 50,
    paddingBottom: '5vh',
    isolation: 'isolate',
    backgroundImage: 'linear-gradient(to top, rgba(255, 255, 255, 1) 85%, rgba(255, 255, 255, 0) 100%)',

    '@media(max-width: 320px)': {
      paddingBottom: 12,
    },
  },
  actionContainer: {
    padding: 'var(--page-padding)',
    paddingTop: 0,
  },
  stepperContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  imageContainer: {
    position: 'absolute',
    top: '15vh',
    width: '100%',
    display: 'flex',

    '@media(max-width: 320px)': {
      top: '2vh',
    },
  },
  image: {
    marginLeft: 'auto',
    marginRight: 'auto',
    height: 'auto',
    maxWidth: '100%',
  },
}));

const NameSchema = yup.object().shape({
  firstName: yup.string().required('This field is required'),
  lastName: yup.string().required('This field is required'),
  isManager: yup.string().required(),
});

export const WelcomePage = decorate(({
  accountStore,
  assessmentConfigurationStore,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [ state, setState ] = React.useState({
    saving: false,
    index: 0,
    config: null,
  });
  const [ aError, aLoading, account ] = useStoreQuery(accountStore, 'get');
  const [ cError, cLoading, configs ] = useStoreQuery(assessmentConfigurationStore, 'fetch');
  const { register, handleSubmit, setValue, control } = useForm({
    resolver: yupResolver(NameSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      isManager: 'false',
    },
  });
  const loading = aLoading || cLoading;
  const error = aError || cError;
  const { errors } = useFormState({ control });
  const reg = wrapRegister(register);

  React.useEffect(() => {
    setValue('firstName', account?.firstName);
    setValue('lastName', account?.lastName);
    setValue('isManager', account?.isManager ? 'true' : 'false');
  }, [account]);

  React.useEffect(() => {
    if (configs?.length) {
      setState(prev => ({
        ...prev,
        config: featuredInWelcome(configs),
      }));
    }
  }, [configs]);

  const submitName = ({ firstName, lastName }) => {
    account.firstName = firstName;
    account.lastName = lastName;
    nextSlide();
  };

  const submitAccount = async (data) => {
    const { firstName, lastName, isManager } = data;
    if (!account.onboarding) {
      account.onboarding = {};
    }
    account.onboarding.showWelcomeScreen = false;
    account.firstName = firstName;
    account.lastName = lastName;
    account.isManager = isManager === 'true';
    account.subscribeAutomatically = true;
    setState(prev => ({ ...prev, saving: true }));
    try {
      if (account._id) {
        await accountStore.save(account);
      } else {
        await accountStore.create(account);
      }
    } catch(err) {
      Sentry.captureException(err);
    }

    setState(prev => ({ ...prev, saving: false, index: 2 }));
  };

  const handleIndexChange = index => setState({ ...state, index });
  const nextSlide = () => setState(prev => ({ ...prev, index: state.index + 1 }));
  const done = () => (state.config?.assessment && account.isManager)
    ? navigate(`/assessments/${state.config.assessment}?redirectTo=/profile`)
    : navigate('/topics');
  const next = async () => {
    switch (state.index) {
      case 0:
        await handleSubmit(submitName)();
        break;
      case 1:
        await handleSubmit(submitAccount)();
        break;
      case 4:
        done();
        break;
      default:
        nextSlide();
    }
  };

  const swipableProps = {
    index: state.index,
    disabled: !account?.firstName || !account?.lastName,
    onChangeIndex: handleIndexChange,
    style: {
      height: '100%',
    },
    containerStyle: {
      height: '100%',
      flexGrow: 1,
    },
  };

  return (
    <PageContent className={classes.page} variant='empty' showBackgroundLogo={false}>
      <BeSquaresBackground />

      <Helmet>
        <title>Welcome to bestselfy</title>
      </Helmet>
      {
        loading && <div>Loading...</div>
      }
      {
        error && <div>Error</div>
      }
      {
        !loading && !error && account && <>
          <PageSection className={classes.section}>
            <SwipeableViews { ...swipableProps }>
              <NameInputSlide
                errors={errors}
                onSubmit={handleSubmit(submitName)}
                register={reg}
              />

              <RoleInputSlide
                errors={errors}
                onSubmit={handleSubmit(submitAccount)}
                disabled={state.saving}
                control={control}
              />

              {
                account?.isManager
                  ? <Manager1Slide />
                  : <Employee1Slide />
              }
              {
                account?.isManager
                  ? <Manager2Slide />
                  : <Employee2Slide />
              }
              {
                account?.isManager
                  ? <Manager3Slide />
                  : <Employee3Slide />
              }
            </SwipeableViews>

            <div className={classes.actionContainer}>
              <div className={classes.stepperContainer}>
                <MobileStepper
                  variant='dots'
                  steps={5}
                  position='static'
                  activeStep={state.index}
                  className={classes.stepper}
                />
              </div>
              <Button
                className={classes.submitButton}
                variant='contained'
                disabled={state.saving}
                onClick={next}
                size='large'
                fullWidth
              >
                { state.disabled ? 'Saving...' : 'Continue'}
              </Button>
            </div>
          </PageSection>
        </>
      }
    </PageContent>
  );
});

WelcomePage.propTypes = {
  accountStore: PropTypes.instanceOf(AccountStore),
  assessmentConfigurationStore: PropTypes.instanceOf(AssessmentConfigurationStore),
};
