import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const Emoji = ({ symbol, className, ...props}) => {
  const classes = useStyles();

  return (
    <span
      className={clsx(className, classes.root)}
      role='img'
      aria-label=''
      { ...props }
    >
      { symbol }
    </span>
  );
};

Emoji.propTypes = {
  symbol: PropTypes.string,
  className: PropTypes.string,
};
