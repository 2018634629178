import { CollectionStore } from 'modules/base';

export class CoachingsStore extends CollectionStore {
  baseUrl = '/coachings';

  constructor(config) {
    super(config);

    this.authService = config.authService;
  }

  async count() {
    const url = `${this.baseUrl}/count`;
    const response = await this.httpService.get(url);
    return response.body;
  }

  export(item) {
    const coaching = super.export(item);
    coaching.user = this.authService.currentUser$._id;
    delete coaching.deleted;
    return coaching;
  }

  import(item) {
    delete item.user;
    item.deleted = false;
    return super.import(item);
  }

  toggleMute(coaching) {
    coaching.muted = !coaching.muted;
    return this.save(coaching);
  }

  async getNudges(coaching) {
    const url = `${this.baseUrl}/${coaching._id}/nudges`;
    const response = await this.httpService.get(url);
    return response.body;
  }

  get newItem() {
    return {
      title: 'New Coaching',
      description: '',
      sessions: [],
      media: [],
      topic: null,
      goal: null,
      focusArea: null,
      deleted: false,
    };
  }
}
