export const purple = {
  50: '#c4b5e9',
  100: '#b5a3e3',
  200: '#a690dd',
  300: '#977dd7',
  400: '#896bd2',
  500: '#7a58cc',
  600: '#6b46c6',
  700: '#5739a3',
  800: '#422b80',
  900: '#2e1e5d',
};
