export const yellow = {
  50: '#fde4b0',
  100: '#fcdd9c',
  200: '#fbd688',
  300: '#fbcf74',
  400: '#fac860',
  500: '#f9c14d',
  600: '#f8ba39',
  700: '#e0a835',
  800: '#c89632',
  900: '#b0832e',
};
