import moment from 'moment';

import { CollectionStore } from 'modules/base';
import { getClassification } from 'utils/topics';

export class TopicsStore extends CollectionStore {
  baseUrl = '/topics';

  get newItem() {
    return {
      name: '',
      classifications: [],
      description: '',
      recommendation: null,
    };
  }

  async topicForClassification(classId, opts = {}) {
    const topics = await this.fetch(opts);

    for (let i=0; i<topics.length; i++) {
      const topic = topics[i];
      const classification = getClassification(topic, classId);
      if (classification) {
        return topic;
      }
    }

    return null;
  }

  async publish(topic) {
    topic.publishedAt = moment();
    topic.unpublishedAt = null;

    return this.save(topic);
  }

  async unpublish(topic) {
    topic.publishedAt = null;
    topic.unpublishedAt = moment();

    return this.save(topic);
  }
}
