import React from 'react';
import PropTypes from 'prop-types';

import { Notebook } from 'components/notes';
import { getPageData } from 'modules/notebooks';

export const NotebookTab = ({
  notebook = { pages: [] },
  sessions = [],
}) => {
  const pageData = React.useMemo(
    () => getPageData(notebook.pages, sessions),
  [notebook, sessions]);

  return (
    <Notebook pageData={pageData} />
  );
};

NotebookTab.propTypes = {
  pages: PropTypes.array,
  notebook: PropTypes.object,
  sessions: PropTypes.array,
};
