import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { pathOr } from 'ramda';

import { BottomSheetDialog } from 'components/dialogs';
import { MediaList } from 'components/media';

const useStyles = makeStyles({
  content: {
    position: 'relative',
    padding: 'var(--page-padding)',
  },
  title: {
    marginBottom: 32,
    paddingRight: 12,
  },
  closeButton: {
    position: 'absolute',
    top: 4,
    right: 4,
    margin: 0,
    padding: 0,
    minWidth: 'unset',
  },
});

export const MediaDialog = ({
  className,
  coaching,
  onClose = () => { },
  ...props
}) => {
  const classes = useStyles();

  return (
    <BottomSheetDialog
      onClose={onClose}
      className={clsx(classes.root, className)}
      {...props}
    >
      <DialogContent className={classes.content}>
        <Button className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </Button>
        <Typography variant='h2' component='h2' className={classes.title}>
          {
            pathOr('', ['title'], coaching)
          }
        </Typography>
        <MediaList media={pathOr([], ['media'], coaching)} />
      </DialogContent>
    </BottomSheetDialog>
  );
};

MediaDialog.propTypes = {
  className: PropTypes.string,
  coaching: PropTypes.object,
  onClose: PropTypes.func,
};
