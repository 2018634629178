import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { slice } from 'ramda';
import { observer } from 'mobx-react';


const truncate = (text, length = 25) => {
  const ellipsis = '...';
  let words = text.split(' ');
  if (words.length > length) {
    words = slice(0, length + 1, words);
    words.push(ellipsis);
  }
  return words.join(' ');
};

const getSessionState = session => {
  if (session.dateCompleted) { return 'completed'; }
  if (session.dateSkipped) { return 'skipped'; }
  return 'pending';
};

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    marginBottom: 32,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  content: {
    '&:last-child': {
      paddingBottom: 16,
    }
  },
  title: {
    marginBottom: 12,
  },
  textOutline: {
    marginBottom: 12,
  },
  footerText: {
    fontFamily: 'Montserrat',
    fontSize: '0.9em',
    color: theme.palette.text.disabled,
  },
  state: {
    textTransform: 'capitalize',
    fontWeight: 800,
  },
  date: {},
  completed: {
    color: theme.palette.success.main,
  },
  skipped: {
    color: theme.palette.primary.main,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }
}));

export const NoteCard = observer(({
  page = {},
  session = {},
  onClick = () => { },
}) => {
  const classes = useStyles();
  const state = getSessionState(session);
  return (
    <Card className={classes.root} elevation={6} onClick={onClick}>
      <CardContent className={classes.content}>
        <Typography variant='h4' component='h4' className={classes.title}>
          {
            page.title
          }
        </Typography>
        <Typography className={classes.textOutline}>
          {
            page.content
              ? truncate(page.content, 25)
              : <i>No notes...</i>
          }
        </Typography>
        {
          session
            ? <div className={classes.footer}>
              <Typography className={clsx(classes.state, classes.footerText, classes[state])}>
                {
                  state
                }
              </Typography>
              {
                session.dateCompleted || session.dateSkipped
                  ? <Typography className={clsx(classes.date, classes.footerText)}>
                    {
                      moment(session.dateCompleted || session.dateSkipped).calendar()
                    }
                  </Typography>
                  : null
              }
            </div>
            : null
        }
      </CardContent>
    </Card>
  );
});

NoteCard.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
  }).isRequired,
  session: PropTypes.shape({
    dateSkipped: PropTypes.string,
    dateComplete: PropTypes.string,
  }),
};
