import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

import { CoachingMap } from 'components/coaching-plan';
import { Typography } from '@material-ui/core';

export const CoachingCard = withStyles(theme => ({
  root: {
    position: 'relative',
  },
  header: {
  },
  menu: {
    position: 'absolute',
    top: 8,
    right: 0,
    minWidth: 'unset',
    padding: 8,
  },
  title: {
    paddingRight: 48,
  },
  coachingPlan: {
    marginBottom: 24,
  },
  doneButton: {
    backgroundColor: theme.palette.success.main,
    backgroundImage: 'unset',
  },
}))(({
  classes,
  className,
  coaching,
  onOpenMenu = () => {},
  onContinue = () => {},
}) => {
  const isFinished = !!coaching.dateFinished;
  const { isLocked, isJourney } = coaching;
  return (
    <Card className={clsx(classes.root, className)} elevation={0}>
      <CardContent>
        <div className={classes.header}>
          <Typography className={classes.title} variant='h3' component='h3'>
          {
            coaching.title
          }
          </Typography>
          <Button className={classes.menu} onClick={onOpenMenu}>
            <MoreVertIcon />
          </Button>
        </div>
        <CoachingMap
          className={classes.coachingPlan}
          sessions={coaching.sessions}
          interactive={false}
          windowSize={1}
        />
        <Button
          className={clsx({
            [classes.doneButton]: isFinished,
          })}
          size='large'
          variant='contained'
          onClick={() => onContinue(coaching)}
          disabled={isJourney && isLocked}
          fullWidth
        >
          {
            isFinished
              ? 'Done'
              : isLocked && isJourney
                ? 'Locked'
                : 'Continue Coaching'
          }
        </Button>
      </CardContent>
    </Card>
  );
});