import React from 'react';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import clsx from 'clsx';
import { withStyles } from "@material-ui/core/styles";
import { assoc } from 'ramda';

const confirmLeaving = assoc('confirmLeaving');

export const TeamCardMemberMenu = withStyles(theme => ({
  dialog: {
    width: 340,
  },
  dialogContent: {
    height: 98,
    padding: 0,

    '&:first-child': {
      paddingTop: 0,
    }
  },
  dialogTitle: {
    // width: 328,
    padding: 16,
    textAlign: 'center',
  },
  h3: {
    marginBottom: 0,
  },
  actionList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItem: {
    textAlign: 'center',
    cursor: 'pointer',
  },
  warn: {
    color: theme.palette.warn.main,
  },
  dialogContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '0 16px',
  },
  textContainer: {
    flexGrow: 1,
  },
  actionContainer: {
    height: 50,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  confirmSave: {
    color: theme.palette.success.main,
  },
  confirmLeaving: {
    color: theme.palette.warn.main,
  },
}))(({
  classes,
  team = null,
  open = false,
  disabled = false,
  onClose = () => {},
  onLeave,
}) => {
  const [state, setState] = React.useState({
    confirmLeaving: false,
  });

  const handleConfirmLeaving = () => setState(confirmLeaving(true));
  const handleCancelDeletion = () => setState(confirmLeaving(false));
  const resetState = () => setState(() => ({
    confirmLeaving: false,
  }));

  const shouldToggle = state.confirmLeaving
    || state.editName
    || state.editDescription;

  React.useEffect(() => resetState(), [team]);

  if (!team) { return null; }

  return (
    <Dialog classes={{ paper: classes.dialog }} onClose={onClose} open={open}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography variant='h3' component='h3' className={classes.h3}>
          {team.name}
        </Typography>
      </DialogTitle>
      
      <SwipeableViews disabled={true} index={shouldToggle ? 1 : 0}>
        <DialogContent className={classes.dialogContent}>
          <List className={classes.actionList}>
            <Divider />
            <ListItem>
              <ListItemText
                className={clsx(classes.listItem, classes.warn)}
                primary='Leave Team'
                onClick={handleConfirmLeaving}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                className={classes.listItem}
                primary='Cancel'
                onClick={onClose}
              />
            </ListItem>
          </List>
        </DialogContent>
        <DialogContent className={classes.dialogContent}>
        {
          state.confirmLeaving
            ? <div className={classes.dialogContentContainer}>
                <Typography className={classes.textContainer}>
                  Are you sure you want to leave this team?
                </Typography>
                <div className={classes.actionContainer}>
                  <Button onClick={handleCancelDeletion}>
                    Back
                  </Button>
                  <Button
                    onClick={() => onLeave(team)}
                    className={classes.confirmLeaving}
                    disabled={disabled}
                  >
                    Leave
                  </Button>
                </div>
              </div>
            : null
        }
        </DialogContent>
      </SwipeableViews>
    </Dialog>
  );
});