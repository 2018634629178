import React from 'react';
import Helmet from 'react-helmet';
import { Button, Typography, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';

import { PageContent, PageSection } from 'components/layout';
import { loginRequired } from 'modules/auth';

const NavButton = withStyles(theme => ({
  root: {
    backgroundImage: 'unset',
    backgroundColor: theme.palette.paper.light,
    color: theme.palette.text.main,
  },
}))(({ classes, children, onClick }) => (
  <Button className={classes.root} size='large' variant='contained' onClick={onClick}>
    {children}
  </Button>
));

const useStyles = makeStyles(theme => ({
  pageBody: {
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  imageContainer: {
    minHeight: 360,
    marginBottom: theme.spacing(2),
  },
  image: {
    width: '100%',
    height: 'auto',
    marginBottom: theme.spacing(1),
  },
  h2: {
    textAlign: 'center',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

export const PastActivitiesPage = loginRequired(() => {
  const classes = useStyles();
  const navigate = useNavigate();
  const to = section => () => {
    navigate(`/coaching-journey/${section}`);
  };

  return (
    <PageContent variant='blue'>
      <Helmet>
        <title>Past Activity</title>
      </Helmet>

      <PageSection className={classes.pageBody}>
        <div className={classes.imageContainer}>
          <img
            className={classes.image}
            src={`${process.env.PUBLIC_URL}/images/past-activities.svg`}
            alt='Coaching Journey'
          />
          <Typography className={classes.h2} variant='h2' component='h1'>Past Activity</Typography>
        </div>

        <div className={classes.buttons}>
          <NavButton onClick={to('notes')}>Notes</NavButton>
          <NavButton onClick={to('completed-coachings')}>Completed Coachings</NavButton>
          <NavButton onClick={to('mood-tracker')}>Mood Tracker</NavButton>
        </div>
      </PageSection>
    </PageContent>
  );
});
