import * as log from 'loglevel';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as yup from 'yup';
import clsx from 'clsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { Helmet } from 'react-helmet';
import { useForm, useFormState } from 'react-hook-form';
import { pathOr, curry } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import { PageContent } from 'components/layout';
import { useSegment } from 'utils/analytics';
import { firebase } from 'modules/firebase';
import { wrapRegister } from 'utils/forms';

const getError = curry((errors, field) => pathOr(null, [field, 'message'], errors));

const useStyles = makeStyles(theme => ({
  text: {
    marginBottom: theme.spacing(4),
  },
  textField: {
    width: '100%',
    marginBottom: theme.spacing(3),
    color: theme.palette.text.main,

    '&:last-child': {
      marginBottom: theme.spacing(0),
    },
  },
  loginLink: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    lineHeight: '1.43',
    letterSpacing: '0.2px',
    textAlign: 'center',
    color: '#6f7faf',
    marginTop: theme.spacing(2),
  },
  successMessage: {
    color: theme.palette.success.main,
  },
}));

const LoginSchema = yup.object().shape({
  email: yup.string().email().required(),
});

const PasswordRecoveryPage = () => {
  const classes = useStyles();
  const { track } = useSegment();
  const { register, handleSubmit, control, setError, clearErrors } = useForm({
    resolver: yupResolver(LoginSchema),
  });
  const { errors } = useFormState({ control });
  const [ state, setState ] = React.useState({
    submitting: false,
    successMessage: null,
  });
  const errorFor = getError(errors);
  const reg = wrapRegister(register);
  const successMessage = 'Email sent, check your inbox and spam folders';

  async function onLogin({ email }) {
    clearErrors();
    setState(prev => ({ ...prev, submitting: true }));
    try {
      await firebase.auth().sendPasswordResetEmail(email);
      onEmailSuccess();
    } catch (error) {
      onEmailError(error);
    }
  }

  async function onEmailSuccess() {
    setState(prev => ({ ...prev, submitting: false, successMessage }));
    track('Account Recovery Mail Sent', {
      category: 'Users',
    });
  }

  function onEmailError(err) {
    log.debug('PasswordRecoveryPageController#onLoginFailure', err);
    let message = 'Unknown error';
    const name = 'email';

    if (err.code === 'auth/invalid-email') {
      message = 'This email address is invalid';
      setState(prev => ({ ...prev, submitting: false }));
      setError(name, { type: '', message });
    } else {
      // No one needs to know, whether an email address is associated with an
      // existing acount or not.
      setState(prev => ({ ...prev, submitting: false, successMessage }));
    }
  }

  return (
    <PageContent variant='backgroundPage'>
      <Helmet>
        <title>Password Reset</title>
      </Helmet>
      <Typography className={classes.header} variant='h1' component='h1'>
        Reset your password.
      </Typography>
      <Typography className={classes.text}>
        Enter your mail address associated with your account to receive an email
        that with a link to reset your password reset.
      </Typography>
      <form onSubmit={handleSubmit(onLogin)}>
        <TextField
          className={classes.textField}
          label='Email'
          type='text'
          name='email'
          variant='outlined'
          error={Boolean(errorFor('email'))}
          helperText={errorFor('email') || state.successMessage}
          FormHelperTextProps={{
            className: clsx({ [classes.successMessage]: state.successMessage })
          }}
          { ...reg('email') }
        />
        <Button fullWidth size='large' variant='contained' type='submit'
          disabled={state.submitting}
        >
          {state.submitting ? 'Submitting...': 'Submit'}
        </Button>
        <Typography className={classes.loginLink}>
          Do you remember your password?&nbsp;
          <Link className='OutboundLink' to='/login'>Login</Link>
        </Typography>
      </form>
    </PageContent>
  );
};

export {
  PasswordRecoveryPage,
};
