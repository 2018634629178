import { SingletonStore } from 'modules/base';

export class SubscriptionStore extends SingletonStore {
  baseUrl = '/subscriptions';

  async authorize(subId, hpId) {
    const response = await this.httpService.post(
      `${this.baseUrl}/${subId}/authorize`,
      { hpId }
    );

    if (response.body) {
      const item = this.import(response.body);
      this.item$ = item;
    }
    return this.item$;
  }

  async openPortal() {
    const res = await this.httpService.get(`${this.baseUrl}/portal`);
    return res.body;
  }
}
