import React from 'react';
import PropTypes from 'prop-types';

export const BurgerMenuIcon = ({
  width = '20',
  height = '17',
  viewBox = '0 0 20 17',
}) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox}>
      <path fill='#FFF' fillOpacity='.85' fillRule='evenodd' d='M9.8 14a1.2 1.2 0 1 1 0 2.4H1.2a1.2 1.2 0 1 1 0-2.4h8.6zm4-7a1.2 1.2 0 1 1 0 2.4H1.2a1.2 1.2 0 1 1 0-2.4h12.6zm5-7a1.2 1.2 0 1 1 0 2.4H1.2a1.2 1.2 0 1 1 0-2.4h17.6z'/>
  </svg>
);

BurgerMenuIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};
