import React from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';

import { NoteDialog } from './note-dialog.component';
import { NoteCard } from './note-card.component';

export const Notebook = ({
  className,
  pageData = [],
}) => {
  const [state, setState] = React.useState({
    open: false,
    pageSelected: pathOr(null, [0, 'page'], pageData),
  });

  const showNote = pageSelected => () => setState({ ...state, open: true, pageSelected });
  const closeDialog = () => setState({ ...state, open: false });
  const noteCards = React.useMemo(() => pageData.map(({ page, session }) => (
    <NoteCard
      key={page._id}
      page={page}
      session={session}
      onClick={showNote(page)}
    />
  )), [pageData]);

  return (
    <div className={className}>
      {
        noteCards
      }
      <NoteDialog
        open={state.open}
        onClose={closeDialog}
        title={state.pageSelected.title}
        text={state.pageSelected.content}
      />
    </div>
  );
};

Notebook.propTypes = {
  className: PropTypes.string,
  pageData: PropTypes.arrayOf(PropTypes.shape({
    page: PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string,
    }),
  })),
};
