import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

export const SubHeader = withStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
  },
}))(({
  children,
  className = '',
  classes,
}) => (
  <Typography className={clsx(classes.root, className)}>
    { children }
  </Typography>
));

SubHeader.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
};
