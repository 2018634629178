import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { groupBy, values, compose, concat, slice, head, last, find } from 'ramda';
import { withStyles } from '@material-ui/core/styles';

import { Emoji } from 'components/emoji';
import { compareDate } from 'utils/date';
import { varyByOs, OS } from 'utils/client';

const DAY_COLUMN_HEADERS = [ 'M', 'T', 'W', 'T', 'F', 'S', 'S' ];
const EMPTY_WEEK = [ null, null, null, null, null, null, null ];
const getEmptyDays = (length) => slice(0, length, EMPTY_WEEK);
const prefixWeek = (week) => concat(getEmptyDays(7 - week.length), week);
const postfixWeek = (week) => concat(week, getEmptyDays(7 - week.length));

const fillWeeks = (weeks) => {
  if (weeks.length === 1) {
    const firstWeek = head(weeks);
    return firstWeek[0].format('D') === '1' ?
      [prefixWeek(firstWeek)]:
      [postfixWeek(firstWeek)];
  } else {
    const firstWeek = head(weeks);
    const lastWeek = last(weeks);
    const remainingWeeks = slice(1, weeks.length - 1, weeks);
    const filledFirstWeek = prefixWeek(firstWeek);
    const filledLastWeek = postfixWeek(lastWeek);

    return concat(concat([filledFirstWeek], remainingWeeks), [filledLastWeek]);
  }
};

const Day = withStyles({
  emoji: {
    position: 'absolute',
    fontSize: varyByOs({
      [OS.WINDOWS]: 18,
      [OS.MAC]: 26,
      [OS.IOS]: 26,
    }, 18),
    top: '45px',
    left: '50%',
    right: '50%',
    marginLeft: '-12.5px',
  },
  day: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: '0 1 50px',
    textAlign: 'center',
    height: '84px',
    fontSize: '18px',
    fontWeight: '500',
    color: '#989898',
    boxSizing: 'border-box',
    paddingTop: '15px',
  },
  isToday: {
    color: 'var(--app-background)',
  },
  todayMarker: {
    height: 7,
    position: 'absolute',
    backgroundColor: 'var(--app-background)',
    left: 0,
    right: 0,
    bottom: 0,
  },
})(({
  day,
  dayData,
  today,
  classes,
}) => {
  let icon = null;
  const addTodayMarker = day && compareDate(today, day);
  const data = day && dayData && find(
    f => compareDate(day, moment.utc(f.date)),
    dayData
  );

  if (data) {
    icon = <Emoji
      className={classes.emoji}
      symbol={data.feedback.emoticon}
      label={data.feedback.value}
           />;
  }

  return (
    <div className={clsx(classes.day, {
      [classes.isToday]: addTodayMarker,
    })}>
      {
        day && day.format('D')
      }
      {
        icon
      }
      {
        addTodayMarker && <div className={classes.todayMarker} />
      }
    </div>
  );
});

Day.propTypes = {
  day: PropTypes.any,
  dayData: PropTypes.arrayOf(PropTypes.shape({
  })),
  onClick: PropTypes.func,
  today: PropTypes.any,
  classes: PropTypes.object,
};

const Week = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
})(({
  week,
  dayData,
  today,
  classes,
}) => {
  return (
    <div className={classes.root}>
      {
        week.map((day, idx) => (
          <Day key={idx} day={day} dayData={dayData} today={today} />
        ))
      }
    </div>
  );
});

Week.propTypes = {
  week: PropTypes.any.isRequired,
  dayData: PropTypes.arrayOf(PropTypes.shape({
  })),
  today: PropTypes.any,
  classes: PropTypes.object,
};

const HeaderRow = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },

  header: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: '0 1 50px',
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: '500',
    boxSizing: 'border-box',
    paddingTop: '15px',
    height: '50px',
    color: '#304659',
  },
})(({
  classes,
}) => (
  <div className={classes.root}>
    {
      DAY_COLUMN_HEADERS.map((d, idx) => (
        <div key={idx} className={classes.header}>
          {
            d
          }
        </div>
      ))
    }
  </div>
));

export const TrackerCalendar = withStyles({
  root: {
    flex: '1 1 auto',
  },
})(({
  date,
  dayData,
  rangeBasis = 'month',
  className = '',
  today,
  classes,
}) => {
  const range = date.range(
    date.startOf(rangeBasis).format('YYYY-MM-DD'),
    date.endOf(rangeBasis).format('YYYY-MM-DD')
  );
  const days = Array.from(range.by('day'));
  const weeks = compose(
    fillWeeks,
    values,
    groupBy((d) => d.isoWeek())
  )(days);

  return (
    <div className={clsx(classes.root, className)}>
      <HeaderRow />
      {
        weeks.map((week, idx) => (
          <Week key={idx}
            week={week}
            dayData={dayData}
            today={today || moment.utc()}
          />
        ))
      }
    </div>
  );
});

TrackerCalendar.propTypes = {
  date: PropTypes.any,
  dayData: PropTypes.arrayOf(PropTypes.shape({
  })),
  rangeBasis: PropTypes.oneOf(['week', 'isoweek', 'month']),
  className: PropTypes.string,
  today: PropTypes.any,
  classes: PropTypes.object,
};
