import { groupBy, sortBy, pathOr, reverse, compose } from 'ramda';

export const sortByCreationDate = teams => {
  return compose(reverse, sortBy(pathOr(Date.now(), ['createdAt'])))(teams);
};

export const groupByMembership = account => groupBy(team => {
  if (team.lead._id === account._id) { return 'lead'; };
  if (team.members.find(member => member.account._id === account._id)) { return 'member'; };
  return 'none';
});

export const sortAndGroupByMembership = account => compose(
  groupByMembership(account),
  sortByCreationDate
);
