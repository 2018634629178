import React from 'react';

import { StoreContext } from 'modules/contexts';
import { sync } from 'utils/state';

export const usePastCoachings = () => {
  const { pastCoachingsStore } = React.useContext(StoreContext);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const fetch = sync(() => pastCoachingsStore.fetch(), setLoading, setError);
  const get = sync((id) => pastCoachingsStore.get(id), setLoading, setError);
  const add = (data) => pastCoachingsStore.add(data);

  return {
    loading,
    error,
    store: pastCoachingsStore,
    fetch,
    get,
    add,
  };
};
