import clsx from 'clsx';
import { SimpleCard } from 'components/cards';
import { withStyles } from '@material-ui/styles';

export const OptionCard = withStyles(theme => ({
  root: {
    cursor: 'pointer',
  },
  selected: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.text.light,
  },
  disabled: {
    cursor: 'not-allowed',
    backgroundColor: theme.palette.black['100'],
    color: theme.palette.text.light,
  },
}))(({
  classes,
  option,
  disabled = false,
  selected = false,
  onClick = () => { },
}) => (
  <SimpleCard elevation={disabled && !selected ? 0 : 2} className={clsx(classes.root, {
    [classes.selected]: selected,
    [classes.disabled]: disabled && !selected,
  })} onClick={onClick}>{option}</SimpleCard>
));
