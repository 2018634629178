import PropTypes from 'prop-types';
import { inject } from 'mobx-react';

import { AuthService } from 'modules/auth';

export const PermissionRequired = inject('authService')((props) => (
  props.authService.currentUser$.role === props.permission ? props.children || null : null
));

PermissionRequired.propTypes = {
  permission: PropTypes.number.isRequired,
  authService: PropTypes.instanceOf(AuthService),
};
