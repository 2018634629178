import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import { assoc } from 'ramda';

const setExpanded = assoc('expanded');

const ReadLink = withStyles(theme => ({
  link: {
    marginLeft: theme.spacing(1),
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))(({ classes, children, ...props }) => (
  <Typography
    component='a'
    variant='body1'
    className={clsx(classes.link, props.className)}
    {...props}
  >
    {children}
  </Typography>
));

export const ReadMore = ({
  maxLength = 100,
  children,
  ...props
}) => {
  const [state, setState] = React.useState({
    expanded: false,
  });

  const text = React.useMemo(() => {
    if (state.expanded || children.length <= maxLength) {
      return children;
    }

    return `${children.slice(0, maxLength)}...`;
  }, [state.expanded, children]);

  const expand = e => {
    e.preventDefault();
    setState(setExpanded(true));
  };
  const collapse = e => {
    e.preventDefault();
    setState(setExpanded(false));
  };

  return (
    <Typography {...props}>
      {text}
      {
        children.length > maxLength
          ?  state.expanded
            ? <ReadLink onClick={collapse}>read less</ReadLink>
            : <ReadLink onClick={expand}>read more</ReadLink>
          : null
      }
    </Typography>
  );
};

ReadMore.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
};
