import React from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LockIcon from '@material-ui/icons/Lock';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import { find, includes, indexOf, prepend, append, pathOr, range } from 'ramda';

const getId = pathOr(null, ['_id']);

const Bubble = withStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    margin: 0,
    padding: 0,
    width: 41,
  },
  interactive: {
    width: 55,
  },
  bubble: {
    fontSize: 18,
    width: 41,
    height: 41,
    borderRadius: 100,
    border: `2px solid ${theme.palette.accent.main}`,
    color: theme.palette.accent.main,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    boxSizing: 'border-box',
  },
  bubbleSelected: {
    fontSize: 24,
    width: 55,
    height: 55,
  },
  bubbleInteractive: {
    fontSize: 16,
    width: 31,
    height: 31,
  },
  inactive: {
    border: 'none',
    backgroundColor: theme.palette.disabled.lighter,
  },
  skipped: {
    border: 'none',
    backgroundColor: theme.palette.disabled.lighter,
    color: theme.palette.text.light,
  },
  current: {
    backgroundColor: theme.palette.accent.main,
    color: theme.palette.text.light,
  },
  completed: {
    border: 'none',
    backgroundColor: theme.palette.success.main,
    color: theme.palette.text.light,
  },
  label: {
    margin: 'auto',
    fontFamily: 'Montserrat',
    fontWeight: 800,
    display: 'flex',
  },
  outerRing: {
    borderRadius: 100,
    border: `2px solid ${theme.palette.disabled.main}`,
    width: 21,
    height: 21,
    display: 'flex',
    justifyContent: 'space-around',
    margin: 'auto',
    boxSizing: 'border-box',

  },
  innerRing: {
    borderRadius: 100,
    border: `4px solid ${theme.palette.disabled.main}`,
    width: 11,
    height: 11,
    margin: 'auto',
    boxSizing: 'border-box',
  },
}))(({
  classes,
  state,
  label = '',
  selected = false,
  interactive = false,
}) => (
  <div className={clsx(classes.root, { [classes.interactive]: interactive })}>
    <div className={clsx(classes.bubble, classes[state], {
      [classes.bubbleSelected]: selected,
      [classes.bubbleInteractive]: interactive && !selected,
    })}>
      {
        state === 'inactive'
          ? <div className={classes.outerRing}>
            <div className={classes.innerRing} />
          </div>
          : null
      }
      {
        state === 'completed'
          ? <span className={classes.label}><DoneIcon /></span>
          : null
      }
      {
        state === 'current'
          ? <span className={classes.label}>{label}</span>
          : null
      }
      {
        state === 'skipped'
          ? <span className={classes.label}><CloseIcon /></span>
          : null
      }
    </div>
  </div>
));

const Connector = withStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 0,
  },
  stretch: {
    flexGrow: 1,
  },
  whitespace: {
    flexGrow: 1,
  },
  bar: {
    width: 2,
    flex: '0 0 auto',
    display: 'inline-block',
    backgroundColor: theme.palette.accent.main,
    minHeight: 12,
    visibility: 'hidden',
  },
  wide: {
    width: 4,
  },
  current: {
    visibility: 'unset',
  },
  inactive: {
    backgroundColor: theme.palette.disabled.lighter,
    visibility: 'unset',
  },
  completed: {
    backgroundColor: theme.palette.success.main,
    visibility: 'unset',
  },
  skipped: {
    backgroundColor: theme.palette.disabled.lighter,
    visibility: 'unset',
  },
}))(({
  classes,
  state = false,
  wide = false,
  stretch = false,
}) => (
  <div className={clsx(classes.root, {
    [classes.stretch]: stretch,
  })}>
    <div className={classes.whitespace}></div>
    <div className={clsx(classes.bar, classes[state], {
      [classes.wide]: wide,
    })}></div>
    <div className={classes.whitespace}></div>
  </div>
));

const Entry = withStyles(theme => ({
  row: {
    flexDirection: 'row',
    display: 'none',
  },
  selected: {
    backgroundImage: theme.palette.action.contained.primary,
    color: theme.palette.text.light,
    marginRight: 'calc(-1 * var(--page-padding))',
    marginLeft: 'calc(-1 * var(--page-padding))',
    paddingRight: 'var(--page-padding)',
    paddingLeft: 'var(--page-padding)',
    boxShadow: '0px 0px 10px 4px rgba(0, 0, 0, 0.25)',
  },
  interactive: {
    cursor: 'pointer',
  },
  visible: {
    display: 'flex',
  },
  roadmap: {
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '8px 8px 8px 16px',
  },
  title: {
    flex: '1 1 auto',
    marginTop: 12,
  },
  titleSelected: {
    marginTop: 20,
    '& > *': {
      fontWeight: 700,
    }
  },
  titleInteractive: {
    marginTop: 9,
  },
  teaser: {
    flex: '1 1 auto',
    marginTop: 12,
  },
  action: {
    marginTop: 16,
    flex: '1 1 auto',
    paddingBottom: 16,
  },
  actionButton: {
    backgroundImage: 'none',
    backgroundColor: theme.palette.accent.main,
    color: theme.palette.text.light,
    fontSize: '0.875em',
    padding: '4px 16px',
    height: 'unset',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.palette.accent.main,
      color: theme.palette.text.light,
    },
  },
}))(({
  classes,
  session,
  topEdge = false,
  bottomEdge = false,
  state = 'inactive',
  label = '',
  selected = false,
  onClick = () => {},
  onStart = () => {},
  isVisible = true,
  interactive = false,
}) => {
  const locked = state === 'inactive';

  return (
    <div className={clsx(classes.row, {
      [classes.selected]: selected,
      [classes.visible]: isVisible,
      [classes.interactive]: interactive,
    })} onClick={onClick}>
      <div className={classes.roadmap}>
        <Connector state={topEdge} wide={selected} />
        <Bubble label={label} state={state} selected={selected} interactive={interactive} />
        <Connector state={bottomEdge} wide={selected} stretch={true} />
      </div>
      <div className={classes.content}>
        <div className={clsx(classes.title, {
          [classes.titleSelected]: selected,
          [classes.titleInteractive]: interactive && !selected,
        })}>
          <Typography>
            {
              session.title
            }
          </Typography>
        </div>
        {
          session.teaser && selected
            ? <div className={classes.teaser}>
                <Typography>
                  {
                    session.teaser
                  }
                </Typography>    
              </div>
            : null
        }
        {
          selected
            ? <div className={classes.action}>
                <Button
                  className={classes.actionButton}
                  variant='contained'
                  onClick={onStart}
                  disabled={locked}
                  startIcon={locked ? <LockIcon /> : undefined}
                >
                  {
                    locked
                      ? 'Locked'
                      : 'Go to Session'
                  }
                </Button>
              </div>
            : null
        }
      </div>
    </div>
  );
});

export const CoachingMap = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
})(({
  classes,
  sessions = [],
  className = '',
  interactive = true,
  onStart = () => {},
  windowSize = 0,
}) => {
  let visibleIdx;
  let previousState = null;
  const firstIncomplete = find(s => !(s.dateCompleted || s.dateSkipped), sessions);

  if (windowSize) {
    const currentIdx = indexOf(firstIncomplete, sessions);
    const windowRange = 2 * windowSize + 1;
    const totalLength = sessions.length;

    if (!firstIncomplete) {
      visibleIdx = range(totalLength - windowRange, totalLength);
    } else if (currentIdx - windowSize < 0) {
      visibleIdx = range(0, windowRange);
    } else if (currentIdx + windowSize >= totalLength) {
      visibleIdx = range(totalLength - windowRange, totalLength);
    } else {
      visibleIdx = [currentIdx];
      for (let i = 1; i<=windowSize; i++) {
        visibleIdx = prepend(currentIdx - i, visibleIdx);
        visibleIdx = append(currentIdx + i, visibleIdx);
      }
    }
  }

  const [state, setState] = React.useState({
    sessionSelected: firstIncomplete,
  });

  const setSelected = session => () => setState({
    ...state,
    sessionSelected: session,
  });

  React.useEffect(() => {
    setSelected(firstIncomplete)();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getId(firstIncomplete)]);

  const getBubbleState = s => {
    if (s._id === getId(firstIncomplete)) {
      return 'current';
    } else if (s.dateCompleted) {
      return 'completed';
    } else if (s.dateSkipped) {
      return 'skipped';
    } else {
      return 'inactive';
    }
  };

  return (
    <div className={clsx(classes.root, className)}>
      {
        sessions.map((s, idx) => {
          const order = idx + 1;
          const isFirst = !previousState;
          const isLast = order >= sessions.length;
          const entryState = getBubbleState(s);
          const isSelected = interactive && getId(state.sessionSelected) === s._id;
          const isVisible = visibleIdx ? includes(idx, visibleIdx) : true;

          const component = (
            <Entry
              key={s._id}
              session={s}
              label={order}
              state={entryState}
              topEdge={!isFirst && previousState}
              bottomEdge={!isLast && entryState}
              onClick={setSelected(s)}
              selected={isSelected}
              onStart={() => onStart(s)}
              isVisible={isVisible}
              interactive={interactive}
            />
          );

          previousState = entryState;

          return component;
        })
      }
    </div>
  );
});