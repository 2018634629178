import React from 'react';
import { withStyles } from '@material-ui/core/styles';

export const BlockIllustration = withStyles(theme => ({
  root: {
    maxHeight: 200,
    height: 'auto',
    width: 'auto',
    objectFit: 'scale-down',
    margin: theme.spacing(3),
  },
}))(({ classes, ...props }) => {
  return (
    <img
      alt='illustration'
      className={classes.root}
      { ...props }
    />
  );
});
