import React from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { find } from 'ramda';

const Bubble = withStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    margin: 0,
    padding: 0,
  },
  bubble: {
    fontSize: 24,
    width: 45,
    height: 45,
    flex: '0 0 45px',
    borderRadius: 100,
    border: `2px solid ${theme.palette.accent.main}`,
    color: theme.palette.accent.main,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    boxSizing: 'border-box',
  },
  inactive: {
    border: 'none',
    backgroundColor: theme.palette.disabled.lighter,
  },
  current: {
    backgroundColor: theme.palette.accent.main,
    color: theme.palette.text.light,
  },
  label: {
    margin: 'auto',
    fontFamily: 'Montserrat',
    fontWeight: 800,
  },
  outerRing: {
    borderRadius: 100,
    border: `2px solid ${theme.palette.disabled.main}`,
    width: 21,
    height: 21,
    display: 'flex',
    justifyContent: 'space-around',
    margin: 'auto',
    boxSizing: 'border-box',
    
  },
  innerRing: {
    borderRadius: 100,
    border: `4px solid ${theme.palette.disabled.main}`,
    width: 11,
    height: 11,
    margin: 'auto',
    boxSizing: 'border-box',
  },
}))(({
  classes,
  state,
  label = '',
}) => (
  <div className={classes.root}>
    <div className={clsx(classes.bubble, classes[state])}>
    {
      state === 'inactive'
        ? <div className={classes.outerRing}>
            <div className={classes.innerRing} />
          </div>
        : <span className={classes.label}>{label}</span>
    }
    </div>
  </div>
));

const Connector = withStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
  whitespace: {
    flexGrow: 1,
  },
  bar: {
    width: 2,
    height: '100%',
    flex: '0 0 auto',
    display: 'inline-block',
    backgroundColor: theme.palette.accent.main,
    minHeight: 12,
  },
  hidden: {
    visibility: 'hidden',
  },
  dotted: {
    backgroundColor: theme.palette.disabled.lighter,
  },
}))(({
  classes,
  hidden = false,
  dotted = false,
}) => (
  <div className={classes.root}>
    <div className={classes.whitespace}></div>
    <div className={clsx(classes.bar, {
      [classes.hidden]: hidden,
      [classes.dotted]: dotted,
    })}></div>
    <div className={classes.whitespace}></div>
  </div>
));

export const CoachingPlan = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  roadmap: {
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '8px 8px 8px 16px',
  },
})(({
  classes,
  sessions = [],
  className = '',
  topEdge = false,
  bottomEdge = false,
  animated = false,
}) => {
  let firstIncomplete = null;
  const getBubbleState = s => {
    if (!animated) {
      return 'neutral';
    } else if (s === firstIncomplete) {
      return 'current';
    } else if (s.dateCompleted) {
      return 'completed';
    } else {
      return 'inactive';
    }
  };

  if (animated) {
    firstIncomplete = find(s => !s.dateCompleted, sessions);
  }

  return (
    <div className={clsx(classes.root, className)}>
        {
          sessions.map((s, idx) => (
            <div key={s._id} className={classes.row}>
              <div className={classes.roadmap}>
                <Connector hidden={!idx && !topEdge} dotted={!s.dateCompleted && animated} />
                <Bubble label={idx + 1} state={getBubbleState(s)} />
                <Connector hidden={(idx + 1 >= sessions.length) && !bottomEdge} dotted={!s.dateCompleted && animated} />
              </div>
              <div className={classes.label}>
                <Typography>{ s.title || s.task.title }</Typography>
              </div>
            </div>
          ))
        }
    </div>
  );
});